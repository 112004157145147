import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { TbReplace } from "react-icons/tb";
import {
  CustomAddDialog,
  CustomReplaceDialog,
  CustomTextFieldN,
  CustomUpdateDialog,
} from "../../components";
import VlcSWModules from "./VlcSWModules";
import labCar from "../../redux/actions/labCar";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import customConsole from "../../config/customConsole";

function VlcEEComponents({
  heading,
  eEComponentsList,
  handleEEComponentAdd,
  handleEEComponentUpdate,
  handleEEComponentReplace,
  selectedProjectDetails,
  selectedZone,
}) {
  /* */
  const dispatch = useDispatch();

  /*  */
  const { swModulesList } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );

  /* -------------- Dialogs -------------- */
  const [openEEComponentAddDialog, setOpenEEComponentAddDialog] =
    useState(false);
  const [openEEComponentUpdateDialog, setOpenEEComponentUpdateDialog] =
    useState(false);
  const [openEEComponentReplaceDialog, setOpenEEComponentReplaceDialog] =
    useState(false);

  /* -------------- Selected ee component -------------- */
  const [isEEComponentSelected, setIsEEComponentSelected] = useState(false);
  const [selectedEEComponentDetails, setSelectedEEComponentDetails] =
    useState(null);

  /* -------------- Selected ee component update/replace details  -------------- */
  const [
    selectedEEComponentUpdateDetails,
    setSelectedEEComponentUpdateDetails,
  ] = useState(null);
  const [
    selectedEEComponentReplaceDetails,
    setSelectedEEComponentReplaceDetails,
  ] = useState(null);

  /* -------------- EE - SW Modules -------------- */
  const [eESWModulesList, setEESWModulesList] = useState([]);

  /* -------------- useEffects -------------- */
  useEffect(() => {
    /* Make to default state when the project is changed */
    setIsEEComponentSelected(false);
    setSelectedEEComponentDetails(null);
    setSelectedEEComponentUpdateDetails(null);
    setSelectedEEComponentReplaceDetails(null);
    setEESWModulesList([]);
  }, [selectedProjectDetails]);

  useEffect(() => {
    /* Make to default state when the selected zone is changed */
    setIsEEComponentSelected(false);
    setSelectedEEComponentDetails(null);
  }, [selectedZone]);

  useEffect(() => {
    /* Make to default state when the ee component is changed */
    setEESWModulesList([]);
  }, [selectedEEComponentDetails]);

  const getSWModulesList = () => {
    if (
      selectedProjectDetails &&
      selectedProjectDetails?.project_id &&
      selectedEEComponentDetails &&
      selectedEEComponentDetails?.ee_id
    ) {
      dispatch(
        HandleApiActions({
          ...labCar.get_sw_modules,
          params: {
            project_id: selectedProjectDetails?.project_id,
            ee_id: selectedEEComponentDetails?.ee_id,
          },
          show_toast: false,
        })
      );
    }
  };

  useEffect(() => {
    getSWModulesList();
  }, [selectedEEComponentDetails]);

  useEffect(() => {
    if (swModulesList) {
      setEESWModulesList(swModulesList);
    }
  }, [swModulesList]);

  useEffect(() => {
    console.log(
      "selectedEEComponentUpdateDetails:",
      selectedEEComponentUpdateDetails
    );
    console.log(
      "selectedEEComponentReplaceDetails:",
      selectedEEComponentReplaceDetails
    );
  }, [selectedEEComponentUpdateDetails, selectedEEComponentReplaceDetails]);

  /* -------------- Handle EE Component functions -------------- */
  const handleSelectedEEComponent = (eEComponent) => {
    if (eEComponent) {
      setIsEEComponentSelected(true);
      setSelectedEEComponentDetails(eEComponent);
    }
  };

  const handleSelectedEEComponentUpdateDetails = (eEComponent) => {
    setOpenEEComponentUpdateDialog(true);

    if (eEComponent) {
      setSelectedEEComponentUpdateDetails(eEComponent);
    }
  };

  const handleSelectedEEComponentReplaceDetails = (eEComponent) => {
    setOpenEEComponentReplaceDialog(true);

    if (eEComponent) {
      setSelectedEEComponentReplaceDetails(eEComponent);
    }
  };

  /* -------------- Handle SW Module functions -------------- */
  const handleEESWModuleAdd = ({
    version,
    name,
    summary,
    description,
    vf_ids,
  }) => {
    if (
      selectedProjectDetails &&
      selectedProjectDetails?.project_id &&
      selectedEEComponentDetails &&
      selectedEEComponentDetails?.ee_id
    ) {
      let currentTime = new Date().toISOString();

      customConsole({
        project_id: Number(selectedProjectDetails?.project_id),
        ee_id: Number(selectedEEComponentDetails?.ee_id),
        version,
        name,
        ...(summary && { summary }),
        description,
        vf_ids,
        current_time: currentTime,
      });

      dispatch(
        HandleApiActions({
          ...labCar.add_sw_module,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            ee_id: Number(selectedEEComponentDetails?.ee_id),
            version,
            name,
            ...(summary && { summary }),
            description,
            vf_ids,
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        getSWModulesList();
      });

      // setTimeout(getSWModulesList, 700);
    }
  };

  const handleEESWModuleUpdate = ({ swm_id, version }) => {
    if (
      selectedProjectDetails &&
      selectedProjectDetails?.project_id &&
      selectedEEComponentDetails &&
      selectedEEComponentDetails?.ee_id
    ) {
      let currentTime = new Date().toISOString();

      customConsole({
        project_id: Number(selectedProjectDetails?.project_id),
        ee_id: Number(selectedEEComponentDetails?.ee_id),
        swm_id,
        version,
        current_time: currentTime,
      });

      dispatch(
        HandleApiActions({
          ...labCar.update_sw_module,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            ee_id: Number(selectedEEComponentDetails?.ee_id),
            swm_id,
            version,
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        getSWModulesList();
      });

      // setTimeout(getSWModulesList, 700);
    }
  };

  const handleEESWModuleReplace = ({
    swm_id,
    version,
    name,
    summary,
    description,
    vf_ids,
  }) => {
    if (
      selectedProjectDetails &&
      selectedProjectDetails?.project_id &&
      selectedEEComponentDetails &&
      selectedEEComponentDetails?.ee_id
    ) {
      let currentTime = new Date().toISOString();

      customConsole({
        project_id: Number(selectedProjectDetails?.project_id),
        ee_id: Number(selectedEEComponentDetails?.ee_id),
        swm_id,
        version,
        name,
        ...(summary && { summary }),
        description,
        vf_ids,
        current_time: currentTime,
      });

      dispatch(
        HandleApiActions({
          ...labCar.replace_sw_module,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            ee_id: Number(selectedEEComponentDetails?.ee_id),
            swm_id,
            version,
            name,
            ...(summary && { summary }),
            description,
            vf_ids,
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        getSWModulesList();
      });

      // setTimeout(getSWModulesList, 700);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: { xs: "column", md: "row" },
        justifyContent: "space-evenly",
      }}
    >
      <Box>
        <Card
          sx={{
            mt: 4,
            mx: 2,
            width: "370px",
            boxShadow: 12,
          }}
        >
          <CardHeader
            sx={{
              py: 1,
              backgroundColor: "lightgrey",
              borderBottom: "1px solid lightgrey",
            }}
            title={
              <>
              <Typography
                textAlign={"start"}
                fontWeight={600}
                variant="subtitle1"
              >
                {heading}
              </Typography>
                <Typography
                  textAlign={"start"}
                  // variant="body2"
                  fontSize="13px"
                  color="textSecondary"
                >
                  {"EE Components"}
                </Typography>
              </>
            }
            action={
              <Box
                sx={{
                  pt: 1.5,
                }}
              >
              <Button
                startIcon={<AddIcon />}
                color="primary"
                size="small"
                variant="contained"
                onClick={() => setOpenEEComponentAddDialog(true)}
              >
                Add
              </Button>
              </Box>
            }
          />
          <CardContent
            sx={{
              height: "371px",
              overflowY: "auto",
            }}
          >
            {eEComponentsList && eEComponentsList?.length > 0 ? (
              eEComponentsList?.map((item, index) => (
                <Box
                  key={index}
                  onClick={() => handleSelectedEEComponent(item)}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    mb: 1.5,
                    pl: 1.4,
                    pr: 1,
                    backgroundColor: "background.paper",
                    borderRadius: 1,
                    // boxShadow: 2,
                    boxShadow: 4,
                    border:
                      selectedEEComponentDetails &&
                        selectedEEComponentDetails?.ee_id == item?.ee_id
                        ? "2px solid blue"
                        : "1px solid lightgrey",
                    cursor: "pointer",
                    "&:hover": {
                      // backgroundColor: "lightgrey",
                    },
                  }}
                >
                  <Box sx={{ flexGrow: 1, textAlign: "start", my: 1 }}>
                    <Typography variant="body2">
                      <strong>Description:</strong> {item?.description}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Assembly Number:</strong> {item?.assembly_number}
                    </Typography>
                    {/* <Typography variant="body2">
                  <strong>Supplier Name:</strong> {item?.supplier_name}
                </Typography> */}
                    <Typography variant="body2">
                      <strong>Version:</strong> {item?.version}
                    </Typography>
                  </Box>
                  <Box
                  // sx={{
                  //   display: "flex",
                  //   flexDirection: "column",
                  //   alignItems: "center",
                  //   justifyContent: "center",
                  //   gap: 1,
                  // }}
                  >
                    <Tooltip title="Update">
                    <IconButton
                      size="small"
                      color="info"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectedEEComponentUpdateDetails(item);
                      }}
                    >
                      <EditIcon />
                    </IconButton>
                    </Tooltip>
                    <Tooltip title="Replace">
                    <IconButton
                      size="small"
                      color="info"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleSelectedEEComponentReplaceDetails(item);
                      }}
                    >
                      <TbReplace style={{ fontSize: "22px" }} />
                    </IconButton>
                    </Tooltip>
                  </Box>
                </Box>
              ))
            ) : (
              <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
                No data available
              </Typography>
            )}
          </CardContent>
          <CardActions
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              borderTop: "1px solid lightgrey",
            }}
          >
            {/* <Button
              startIcon={<CloseIcon />}
              color="error"
              size="small"
              variant="contained"
            >
              Cancel
            </Button> */}
          </CardActions>
        </Card>

        <VlcEEComponentAddDialog
          openEEComponentAddDialog={openEEComponentAddDialog}
          setOpenEEComponentAddDialog={setOpenEEComponentAddDialog}
          handleEEComponentAdd={handleEEComponentAdd}
        />

        <VlcEEComponentUpdateDialog
          openEEComponentUpdateDialog={openEEComponentUpdateDialog}
          setOpenEEComponentUpdateDialog={setOpenEEComponentUpdateDialog}
          handleEEComponentUpdate={handleEEComponentUpdate}
          selectedEEComponentUpdateDetails={selectedEEComponentUpdateDetails}
          setSelectedEEComponentUpdateDetails={
            setSelectedEEComponentUpdateDetails
          }
        />

        <VlcEEComponentReplaceDialog
          openEEComponentReplaceDialog={openEEComponentReplaceDialog}
          setOpenEEComponentReplaceDialog={setOpenEEComponentReplaceDialog}
          handleEEComponentReplace={handleEEComponentReplace}
          selectedEEComponentReplaceDetails={selectedEEComponentReplaceDetails}
          setSelectedEEComponentReplaceDetails={
            setSelectedEEComponentReplaceDetails
          }
        />
      </Box>

      <Box>
        {isEEComponentSelected && (
          <VlcSWModules
            heading={selectedEEComponentDetails.description}
            sWModulesList={eESWModulesList}
            handleSWModuleAdd={handleEESWModuleAdd}
            handleSWModuleUpdate={handleEESWModuleUpdate}
            handleSWModuleReplace={handleEESWModuleReplace}
            setIsEEComponentSelected={setIsEEComponentSelected}
            setSelectedEEComponentDetails={setSelectedEEComponentDetails}
            selectedProjectDetails={selectedProjectDetails}
          />
        )}
      </Box>
    </Box>
  );
}

export default VlcEEComponents;

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 23-07-2024
 * @description
 * @param {}
 * @return {}
 */
function VlcEEComponentAddDialog({
  openEEComponentAddDialog,
  setOpenEEComponentAddDialog,
  handleEEComponentAdd,
}) {
  /* Add EE Components Fields */
  const [assemblyNo, setAssemblyNo] = useState("");
  const [assemblyNoError, setAssemblyNoError] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [supplierNameError, setSupplierNameError] = useState("");
  const [version, setVersion] = useState("");
  const [versionError, setVersionError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [summary, setSummary] = useState("");
  const [summaryError, setSummaryError] = useState("");

  /* -------------- UseEffects -------------- */

  /* -------------- Add EE Component -------------- */

  const clearAddEEComponentFields = () => {
    setAssemblyNo("");
    setAssemblyNoError("");
    setSupplierName("");
    setSupplierNameError("");
    setVersion("");
    setVersionError("");
    setDescription("");
    setDescriptionError("");
    setSummary("");
  };

  const isAddEEComponentValid = () => {
    return (
      assemblyNo.trim() !== "" &&
      !assemblyNoError &&
      supplierName.trim() !== "" &&
      !supplierNameError &&
      version.trim() !== "" &&
      !versionError &&
      description.trim() !== "" &&
      !descriptionError &&
      (summary.trim().length === 0 || !summaryError)
    );
  };

  const handleCloseEEComponentAdd = () => {
    setOpenEEComponentAddDialog(false);

    clearAddEEComponentFields();
  };

  const handleAddEEComponent = () => {
    handleEEComponentAdd({
      assembly_number: assemblyNo,
      supplier_name: supplierName,
      version: version,
      summary: summary,
      description: description,
    });

    handleCloseEEComponentAdd();
  };

  return (
    <CustomAddDialog
      open={openEEComponentAddDialog}
      onClose={handleCloseEEComponentAdd}
      dialogTitle={"Add EE Component"}
      dialogContent={
        <Stack spacing={2} mb={2}>
          <CustomTextFieldN
            size={"small"}
            label={"Assembly No*"}
            placeholder={"Assembly No*"}
            type={"text"}
            fldType={"textR"}
            value={assemblyNo}
            setValue={setAssemblyNo}
            valueError={assemblyNoError}
            setValueError={setAssemblyNoError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Supplier Name*"}
            placeholder={"Supplier Name*"}
            type={"text"}
            fldType={"text"}
            value={supplierName}
            setValue={setSupplierName}
            valueError={supplierNameError}
            setValueError={setSupplierNameError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Version*"}
            placeholder={"Version*"}
            type={"text"}
            fldType={"textR"}
            value={version}
            setValue={setVersion}
            valueError={versionError}
            setValueError={setVersionError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Description*"}
            placeholder={"Description*"}
            type={"text"}
            fldType={"text"}
            value={description}
            setValue={setDescription}
            valueError={descriptionError}
            setValueError={setDescriptionError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Summary"}
            placeholder={"Summary"}
            type={"text"}
            fldType={"textO"}
            value={summary}
            setValue={setSummary}
            valueError={summaryError}
            setValueError={setSummaryError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
        </Stack>
      }
      onConfirm={() => {
        handleAddEEComponent({});
        clearAddEEComponentFields();
      }}
      onDisabled={!isAddEEComponentValid()}
    />
  );
}

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 23-07-2024
 * @description
 * @param {}
 * @return {}
 */
function VlcEEComponentUpdateDialog({
  openEEComponentUpdateDialog,
  setOpenEEComponentUpdateDialog,
  handleEEComponentUpdate,
  selectedEEComponentUpdateDetails,
  setSelectedEEComponentUpdateDetails,
}) {
  /* Update EE Components Fields */
  const [version, setVersion] = useState("");
  const [versionError, setVersionError] = useState("");

  useEffect(() => {
    if (
      openEEComponentUpdateDialog == true &&
      selectedEEComponentUpdateDetails
    ) {
      setVersion(selectedEEComponentUpdateDetails?.version || "");
    }
  }, [openEEComponentUpdateDialog, selectedEEComponentUpdateDetails]);

  /* -------------- Update EE Component -------------- */

  const clearUpdateEEComponentFields = () => {
    setVersion("");
    setVersionError("");
  };

  const isUpdateEEComponentValid = () => {
    return (
      !versionError &&
      version.trim() !== selectedEEComponentUpdateDetails?.version
    );
  };

  const handleCloseEEComponentUpdate = () => {
    setOpenEEComponentUpdateDialog(false);

    clearUpdateEEComponentFields();

    setSelectedEEComponentUpdateDetails(null);
  };

  const handleUpdateEEComponent = ({}) => {
    if (
      selectedEEComponentUpdateDetails &&
      selectedEEComponentUpdateDetails?.ee_id
    ) {
      handleEEComponentUpdate({
        ee_id: Number(selectedEEComponentUpdateDetails?.ee_id),
        version: version,
      });

      handleCloseEEComponentUpdate();
    }
  };

  return (
    <CustomUpdateDialog
      open={openEEComponentUpdateDialog}
      onClose={handleCloseEEComponentUpdate}
      dialogTitle={"Update EE Component"}
      dialogContent={
        <Stack spacing={2} mb={2}>
          <CustomTextFieldN
            size={"small"}
            label={"Version*"}
            placeholder={"Version*"}
            type={"text"}
            fldType={"textR"}
            value={version}
            setValue={setVersion}
            valueError={versionError}
            setValueError={setVersionError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
        </Stack>
      }
      onConfirm={() => {
        handleUpdateEEComponent({});
        clearUpdateEEComponentFields();
      }}
      onDisabled={!isUpdateEEComponentValid()}
    />
  );
}

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 23-07-2024
 * @description
 * @param {}
 * @return {}
 */
function VlcEEComponentReplaceDialog({
  openEEComponentReplaceDialog,
  setOpenEEComponentReplaceDialog,
  handleEEComponentReplace,
  selectedEEComponentReplaceDetails,
  setSelectedEEComponentReplaceDetails,
}) {
  /* Replace EE Components Fields */
  const [assemblyNo, setAssemblyNo] = useState("");
  const [assemblyNoError, setAssemblyNoError] = useState("");
  const [supplierName, setSupplierName] = useState("");
  const [supplierNameError, setSupplierNameError] = useState("");
  const [version, setVersion] = useState("");
  const [versionError, setVersionError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [summary, setSummary] = useState("");
  const [summaryError, setSummaryError] = useState("");

  useEffect(() => {
    if (
      openEEComponentReplaceDialog == true &&
      selectedEEComponentReplaceDetails
    ) {
      setAssemblyNo(selectedEEComponentReplaceDetails?.assembly_number || "");
      setSupplierName(selectedEEComponentReplaceDetails?.supplier_name || "");
      setVersion(selectedEEComponentReplaceDetails?.version || "");
      setDescription(selectedEEComponentReplaceDetails?.description || "");
      setSummary(selectedEEComponentReplaceDetails?.summary || "");
    }
  }, [openEEComponentReplaceDialog, selectedEEComponentReplaceDetails]);

  /* -------------- Replace EE Component -------------- */

  const clearReplaceEEComponentFields = () => {
    setAssemblyNo("");
    setAssemblyNoError("");
    setSupplierName("");
    setSupplierNameError("");
    setVersion("");
    setVersionError("");
    setDescription("");
    setDescriptionError("");
    setSummary("");
  };

  const isReplaceEEComponentValid = () => {
    return (
      !assemblyNoError &&
      !supplierNameError &&
      !versionError &&
      !descriptionError &&
      (summary.trim().length === 0 || !summaryError) &&
      (assemblyNo.trim() !==
        selectedEEComponentReplaceDetails?.assembly_number ||
        supplierName.trim() !==
        selectedEEComponentReplaceDetails?.supplier_name ||
        version.trim() !== selectedEEComponentReplaceDetails?.version ||
        description.trim() !== selectedEEComponentReplaceDetails?.description ||
        summary.trim() !== selectedEEComponentReplaceDetails?.summary)
    );
  };

  const handleCloseEEComponentReplace = () => {
    setOpenEEComponentReplaceDialog(false);

    clearReplaceEEComponentFields();

    setSelectedEEComponentReplaceDetails(null);
  };

  const handleReplaceEEComponent = ({}) => {
    if (
      selectedEEComponentReplaceDetails &&
      selectedEEComponentReplaceDetails?.ee_id
    ) {
      handleEEComponentReplace({
        ee_id: Number(selectedEEComponentReplaceDetails?.ee_id),
        assembly_number: assemblyNo,
        supplier_name: supplierName,
        version: version,
        summary: summary,
        description: description,
      });

      handleCloseEEComponentReplace();
    }
  };

  return (
    <CustomReplaceDialog
      open={openEEComponentReplaceDialog}
      onClose={handleCloseEEComponentReplace}
      dialogTitle={"Replace EE Component"}
      dialogContent={
        <Stack spacing={2} mb={2}>
          <CustomTextFieldN
            size={"small"}
            label={"Assembly No*"}
            placeholder={"Assembly No*"}
            type={"text"}
            fldType={"textR"}
            value={assemblyNo}
            setValue={setAssemblyNo}
            valueError={assemblyNoError}
            setValueError={setAssemblyNoError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Supplier Name*"}
            placeholder={"Supplier Name*"}
            type={"text"}
            fldType={"text"}
            value={supplierName}
            setValue={setSupplierName}
            valueError={supplierNameError}
            setValueError={setSupplierNameError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Version*"}
            placeholder={"Version*"}
            type={"text"}
            fldType={"textR"}
            value={version}
            setValue={setVersion}
            valueError={versionError}
            setValueError={setVersionError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Description*"}
            placeholder={"Description*"}
            type={"text"}
            fldType={"text"}
            value={description}
            setValue={setDescription}
            valueError={descriptionError}
            setValueError={setDescriptionError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Summary"}
            placeholder={"Summary"}
            type={"text"}
            fldType={"textO"}
            value={summary}
            setValue={setSummary}
            valueError={summaryError}
            setValueError={setSummaryError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
        </Stack>
      }
      onConfirm={() => {
        handleReplaceEEComponent({});
        clearReplaceEEComponentFields();
      }}
      onDisabled={!isReplaceEEComponentValid()}
    />
  );
}
