/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 20-07-2024
 * @description
 */
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 20-07-2024
 * @description
 * @param {}
 * @return {}
 */
const CustomAddDialog = ({
  open,
  onClose,
  dialogTitle,
  dialogContent,
  onConfirm,
  onDisabled,
}) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      // fullWidth
      // maxWidth={"xs"}
    >
      <DialogTitle sx={{ boxShadow: 3 }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography fontWeight={600} variant="h6">
            {dialogTitle}
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={onClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent
        dividers
        sx={
          {
            //   px: 4,
          }
        }
      >
        {dialogContent}
      </DialogContent>
      <DialogActions sx={{ boxShadow: 3 }}>
        {/* <Button
          color="error"
          variant="contained"
          onClick={onClose}
          startIcon={<CloseIcon />}
          sx={
            {
              // borderRadius: "0px"
            }
          }
        >
          Cancel
        </Button> */}
        <Button
          variant="contained"
          onClick={onConfirm}
          startIcon={<AddIcon />}
          disabled={onDisabled}
          sx={
            {
              // borderRadius: "0px"
            }
          }
        >
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomAddDialog;
