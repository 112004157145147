/**
 * @author Tahir Shaik - tahir@bookwater.com - +91-9177327808
 * @date 11-06-2024
 * @description This file is to have the Login,signup & forgot password functionality
 */
import React, { useState } from "react";
import { Login, ForgotPassword, ResetPassword } from "../../components";
import { Box } from "@mui/material";
import appMeta from "../../assets/app-meta/appMeta";
import { useDispatch, useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import styles from "./authStyle";
import {
  ForgotPasswordAction,
  LoginAction,
  ResetPasswordAction,
} from "../../redux/actions/authAction";
import { toast } from "react-toastify";
import useThrottle from "../../hooks/useThrottle";
// import loginBg from "public\auth_bg_car.png"
function Authentication() {
  const [authView, setAuthView] = useState(100);
  const auth = useSelector((state) => state.authReducer);
  const dispatch = useDispatch();

  const handleAuthViewChange = (newAuthView) => {
    setAuthView(newAuthView);
  };

  const handleLogin = ({ email, password, rememberMe }) => {
    dispatch(LoginAction({ email, password, storage: rememberMe })).then(
      (res) => {
        if (!res?.error) {
          toast.success(res?.msg);
        } else {
          toast.error(res?.msg);
        }
      }
    );
  };
  const throttledHandleLogin = useThrottle(handleLogin, 2500);

  const handleForgotPassword = ({ email }) => {
    dispatch(ForgotPasswordAction({ email, handleAuthViewChange })).then(
      (res) => {
        if (!res?.error) {
          toast.success(res?.msg);
        } else {
          toast.error(res?.msg);
        }
      }
    );
  };
  const throttledHandleForgotPassword = useThrottle(handleForgotPassword, 2500);

  const handleResetPassword = ({ otp, password }) => {
    dispatch(ResetPasswordAction({ otp, password, handleAuthViewChange })).then(
      (res) => {
        if (!res?.error) {
          toast.success(res?.msg);
        } else {
          toast.error(res?.msg);
        }
      }
    );
  };
  const throttledHandleResetPassword = useThrottle(handleResetPassword, 2500);

  if (auth.isAuthenticated) {
    return <Navigate to="/dashboard/home" replace={true} />;
  }

  return (
    <Box
      sx={[
        styles.container,
        {
          position: "relative",
          height: "100vh",
          width: "100vW",
          alignItems: "center",
          display: "flex",
          justifyContent: "center",
        },
      ]}
    >
      <Box
        sx={{
          display: "flex",
          alignItems: { xs: "center", sm: "none" },
          justifyContent: { xs: "center", sm: "none" },
          position: "fixed",
          right: { xs: "0%", sm: "10%", lg: "10%" },
          // border: "1px solid red",
          maxWidth: "100vW",
        }}
      >
        <img height={600} src="auth_bg_car.png" alt="" srcset="" />
      </Box>
      <Box
        sx={{
          ...styles.mainContainer,
          // position: { md: "fixed" },
        }}
      >
        {(() => {
          switch (authView) {
            case appMeta.authView.LOGIN:
              return (
                <Login
                  handleLogin={handleLogin}
                  throttledHandleLogin={throttledHandleLogin}
                  handleAuthViewChange={handleAuthViewChange}
                />
              );
            case appMeta.authView.FORGOT_PASSWORD:
              return (
                <ForgotPassword
                  handleForgotPassword={handleForgotPassword}
                  throttledHandleForgotPassword={throttledHandleForgotPassword}
                  handleAuthViewChange={handleAuthViewChange}
                />
              );
            case appMeta.authView.RESET_PASSWORD:
              return (
                <ResetPassword
                  handleResetPassword={handleResetPassword}
                  throttledHandleResetPassword={throttledHandleResetPassword}
                  handleAuthViewChange={handleAuthViewChange}
                />
              );
            default:
              return (
                <Login
                  handleLogin={handleLogin}
                  handleAuthViewChange={handleAuthViewChange}
                />
              );
          }
        })()}
      </Box>
    </Box>
  );
}

export default Authentication;
