import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import MobileStepper from "@mui/material/MobileStepper";
import Paper from "@mui/material/Paper";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import { useTheme } from "@mui/material/styles";
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import FirstScreen from "./FirstScreen";

import CustomTextField2 from "../../components/text-fields/CustomTextField2";
import { useDispatch, useSelector } from "react-redux";

function CreateProject({ open, close }) {
  const profileDetails = useSelector((state) => state.profileReducer);

  return (
    <div>
      <Dialog fullWidth maxWidth="md" open={open} onClose={() => close(false)}>
        <DialogTitle
          sx={{ m: 0, p: 2, boxShadow: "3" }}
          id="customized-dialog-title"
        >
          <Typography fontWeight="bold" variant="h5">
            Create Project
          </Typography>
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={() => close(false)}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
          <Box>
            <FirstScreen
              close={close}
              profileDetails={profileDetails}
              key="first-screen"
            />
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default CreateProject;
