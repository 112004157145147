import { Box, Paper, Typography } from "@mui/material";
import React, { useEffect } from "react";
import VlcCar from "../../assets/carForVLC.png";

function VlcCarCard({
  availableZones,
  selectedComponent,
  setSelectedComponent,
}) {
  /* useEffects */
  useEffect(() => {
    console.log("availableZones:", availableZones);
    if (availableZones && availableZones.length > 0) {
      setSelectedComponent({
        zone_position_name: availableZones[0].position_name,
      });
    }
  }, [availableZones]);

  const ZoneDetailsBox = (props) => {
    const handleOnClick = () => {
      console.log("-- handleOnClick --");
      console.log(props);
      setSelectedComponent(props);
    };
    return (
      <Paper
        elevation={12}
        sx={{
          borderRadius: "20%",
          height: "80px",
          width: "100px",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          "&:hover": {
            cursor: "pointer",
          },
          border:
            selectedComponent &&
            selectedComponent.zone_position_name === props.zone_position_name
              ? "2px solid blue"
              : "",
        }}
        onClick={handleOnClick}
      >
        <Typography
          sx={{
            fontWeight: "bold",
            fontSize: "14px",
            marginTop: "10px",
            color: "brown",
          }}
        >
          {props.zone_position_name}
        </Typography>
      </Paper>
    );
  };
  const DummyComponent = () => {
    return <span style={{ height: "80px", width: "100px" }}></span>;
  };
  return (
    <Paper
      elevation={12}
      sx={{
        borderRadius: "10px",
        // width: { xs: "95%", sm: "50%" },
        width: "100%",
        // height: "90%",
        height: "470px",
        marginTop: "10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        gap: "15px",
        padding: "10px",
      }}
    >
      <Box
        sx={{
          // width: { xs: "95%", sm: "50%" },
          // height: "20%",
          width: "100%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {/* Front Left Zone */}
        {availableZones.find((data) => data.zone_position === "FRONT-LEFT") ? (
          <ZoneDetailsBox
            zone_position_name={
              availableZones.find((data) => data.zone_position === "FRONT-LEFT")
                .position_name
            }
          />
        ) : (
          <DummyComponent />
        )}
        {/* Front Zone */}
        {availableZones.find((data) => data.zone_position === "FRONT") ? (
          <ZoneDetailsBox
            zone_position_name={
              availableZones.find((data) => data.zone_position === "FRONT")
                .position_name
            }
          />
        ) : (
          <DummyComponent />
        )}
        {/* Front Right Zone */}
        {availableZones.find((data) => data.zone_position === "FRONT-RIGHT") ? (
          <ZoneDetailsBox
            zone_position_name={
              availableZones.find(
                (data) => data.zone_position === "FRONT-RIGHT"
              ).position_name
            }
          />
        ) : (
          <DummyComponent />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-evenly",
          width: "100%",
        }}
      >
        {/* Left Zone */}
        {availableZones.find((data) => data.zone_position === "LEFT") ? (
          <ZoneDetailsBox
            zone_position_name={
              availableZones.find((data) => data.zone_position === "LEFT")
                .position_name
            }
          />
        ) : (
          <DummyComponent />
        )}
        {/* Vehicle Image -> HPC */}
        <Box
          sx={{
            minHeight: "250px",
            minWidth: "150px",
            backgroundImage: `url(${VlcCar})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {/* <Box
            sx={{
              height: "50px",
              width: "50px",
              border: "3px solid gray",
              borderRadius: "10px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Typography
              sx={{
                color: "red",
                fontWeight: "bold",
              }}
            >
              HPC
            </Typography>
          </Box> */}
        </Box>
        {/* Right Zone */}
        {availableZones.find((data) => data.zone_position === "RIGHT") ? (
          <ZoneDetailsBox
            zone_position_name={
              availableZones.find((data) => data.zone_position === "RIGHT")
                .position_name
            }
          />
        ) : (
          <DummyComponent />
        )}
      </Box>
      <Box
        sx={{
          width: "100%",
          // width: { xs: "95%", sm: "50%" },
          // height: "20%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {/* Rear Left Zone */}
        {availableZones.find((data) => data.zone_position === "REAR-LEFT") ? (
          <ZoneDetailsBox
            zone_position_name={
              availableZones.find((data) => data.zone_position === "REAR-LEFT")
                .position_name
            }
          />
        ) : (
          <DummyComponent />
        )}
        {/* Rear Zone */}
        {availableZones.find((data) => data.zone_position === "REAR") ? (
          <ZoneDetailsBox
            zone_position_name={
              availableZones.find((data) => data.zone_position === "REAR")
                .position_name
            }
          />
        ) : (
          <DummyComponent />
        )}
        {/* Rear Right Zone */}
        {availableZones.find((data) => data.zone_position === "REAR-RIGHT") ? (
          <ZoneDetailsBox
            zone_position_name={
              availableZones.find((data) => data.zone_position === "REAR-RIGHT")
                .position_name
            }
          />
        ) : (
          <DummyComponent />
        )}
      </Box>
    </Paper>
  );
}

export default VlcCarCard;
