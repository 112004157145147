/**
 * @author Abdul Rahim M-abdul@au79consulting.com
 * @date 20-07-2024
 * @description  Project Config API documentation Apis
 */

import actionType from "../actionTypes";
const projectConfigAPI = {
  get_project: {
    api_name: "/PCM/project/details",
    https_method: "GET",
    action_type: actionType.GET_PROJECT,
    description: "Get the Admin Settings",
  },
  add_project: {
    api_name: "/PCM/project/create",
    https_method: "POST",
    action_type: actionType.ADD_PROJECT,
    description: "Create Project",
  },
  update_project: {
    api_name: "/PCM/project/details",
    https_method: "PUT",
    action_type: actionType.UPDATE_PROJECT,
    description: "Update Project",
  },
  update_project_status: {
    api_name: "/PCM/project/status",
    https_method: "PUT",
    action_type: actionType.UPDATE_PROJECT_STATUS,
    description: "Update Project Status",
  },
};

export default projectConfigAPI;
