import {
  Box,
  Button,
  Divider,
  Drawer,
  IconButton,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import customConsole from "../../config/customConsole";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import labCar from "../../redux/actions/labCar";
import userManagementApis from "../../redux/actions/userManagent";

function ProjectAccessDrawer(props) {
  const PROJECT_ACCESS = 100;
  const EE_ACCSES = 200;
  const SWM_ACCESS = 300;
  const dispatch = useDispatch();
  const [existingAccess, setExistingAccess] = useState({
    name: "",
    version: "",
    description: "",
    architecture: "",
    owner_details: [],
    co_owner_details: [],
    edit_user_details: [],
    view_user_details: [],
  });
  const [selectType, setSelectedType] = useState(1);
  const [selectedCoOwners, setSelectedCoOwners] = useState([]);
  const [selectedEditors, setSelectedEditors] = useState([]);
  const [selectedViewers, setSelectedViewers] = useState([]);
  const [usersInSystem, setUsersInSystem] = useState([]);
  //Todo: Filter it based on name or role or etc..
  const getUsersInSystem = async () => {
    const apiDetails = userManagementApis.get_users_list;
    delete apiDetails.action_type;
    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        show_toast: false,
        params: {
          page_number: 1,
          page_size: 100,
        },
      })
    );
    if (resp.error) {
      setUsersInSystem([]);
    } else {
      setUsersInSystem(resp.data);
    }
  };
  const getProjectEEAndSWMDetails = async () => {
    let requestDetails = {
      show_toast: false,
    };
    if (props.data.access_type === PROJECT_ACCESS) {
      // get the project details
      const apiDetails = labCar.get_project_details_pa;
      delete apiDetails.action_type;
      requestDetails = {
        ...requestDetails,
        ...apiDetails,
        params: {
          project_id: props.data.project_id,
        },
      };
    } else if (props.data.access_type === EE_ACCSES) {
      // Get the EE Componenet details
      const apiDetails = labCar.get_ee_components_pa;
      delete apiDetails.action_type;
      requestDetails = {
        ...requestDetails,
        ...apiDetails,
        params: {
          project_id: props.data.project_id,
          ee_id: props.data.ee_id,
        },
      };
    } else {
      // Get the Software Module details
      const apiDetails = labCar.get_sw_modules_pa;
      delete apiDetails.action_type;
      requestDetails = {
        ...requestDetails,
        ...apiDetails,
        params: {
          project_id: props.data.project_id,
          ee_id: props.data.ee_id,
          swm_id: props.data.swm_id,
        },
      };
    }
    const resp = await dispatch(HandleApiActions(requestDetails));
    customConsole("-- resp----");
    customConsole(resp);
    if (resp.error || !resp.data) {
      setExistingAccess({
        text1: "",
        text2: "",
        text3: "",
        architecture: "",
        owner_details: [],
        co_owner_details: [],
        edit_user_details: [],
        view_user_details: [],
      });
      props.handleClose();
    } else {
      setExistingAccess({
        text1:
          props.data.access_type === PROJECT_ACCESS
            ? resp.data[0].name
            : props.data.access_type === EE_ACCSES
            ? resp.data[0].description
            : resp.data[0].name,
        text2:
          props.data.access_type === PROJECT_ACCESS
            ? resp.data[0].architecture_name
            : resp.data[0].version,
        text3:
          props.data.access_type === EE_ACCSES ? resp.data[0].description : "",
        owner_details: resp.data[0].owner_details,
        co_owner_details: resp.data[0].co_owner_details,
        edit_user_details: resp.data[0].edit_user_details,
        view_user_details: resp.data[0].view_user_details,
      });
    }
  };
  useEffect(() => {
    customConsole("-- ProjectAccessDrawer --");
    customConsole(props);
    getProjectEEAndSWMDetails();
    getUsersInSystem();
  }, [props]);
  const MapUserDataInList = (details) => {
    return details.data.length && !isNaN(details.data[0].role_id) ? (
      <Select
        variant={"standard"}
        sx={{ minWidth: "200px", gap: 1 }}
        defaultValue={details.data[0].email}
      >
        {details.data.map((data, idx) => {
          return (
            <MenuItem
              value={data.email}
              key={idx}
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                fontSize: "14px",
                color: "black",
              }}
            >
              {data.name}
              <br />
              {data.email}
            </MenuItem>
          );
        })}
      </Select>
    ) : (
      <strong>-</strong>
    );
  };
  const onUserSelection = (details) => {
    customConsole("-- onUserSelection--");
    customConsole(details.target.value);
    customConsole(selectType);
    const newUser = usersInSystem.find(
      (data) => data.user_id === details.target.value
    );
    if (
      !selectedCoOwners.find((data) => data.user_id === details.target.value) &&
      !selectedEditors.find((data) => data.user_id === details.target.value) &&
      !selectedViewers.find((data) => data.user_id === details.target.value)
    ) {
      if (selectType === 1) {
        setSelectedCoOwners([...selectedCoOwners, newUser]);
      } else if (selectType === 2) {
        // Editors
        setSelectedEditors([...selectedEditors, newUser]);
      } else if (selectType === 3) {
        // Editors
        setSelectedViewers([...selectedViewers, newUser]);
      }
    }
  };
  const handleRemoveUserFromSelected = (details, access_type) => {
    customConsole("-- handleRemoveUserFromSelected --");
    customConsole(details);
    customConsole(access_type);
    if (access_type === 1) {
      setSelectedCoOwners(() =>
        selectedCoOwners.filter((data) => data.user_id !== details.user_id)
      );
    } else if (access_type === 2) {
      setSelectedEditors(() =>
        selectedEditors.filter((data) => data.user_id !== details.user_id)
      );
    } else if (access_type === 3) {
      setSelectedViewers(() =>
        selectedViewers.filter((data) => data.user_id !== details.user_id)
      );
    }
  };
  const updateGrantAccess = async () => {
    if (
      selectedCoOwners.length ||
      selectedEditors.length ||
      selectedViewers.length
    ) {
      customConsole("-- updateGrantAccess --");
      const apiDetails = userManagementApis.grant_project_access;
      delete apiDetails.action_type;
      const resp = await dispatch(
        HandleApiActions({
          ...apiDetails,
          show_toast: true,
          params: {
            access_type: props.data.access_type,
            project_id: props.data.project_id,
            ee_id: props.data?.ee_id,
            swm_id: props.data?.swm_id,
            co_owners: selectedCoOwners.length
              ? selectedCoOwners.map((data) => data.user_id)
              : undefined,
            edit_users: selectedEditors.length
              ? selectedEditors.map((data) => data.user_id)
              : undefined,
            view_users: selectedViewers.length
              ? selectedViewers.map((data) => data.user_id)
              : undefined,
          },
        })
      );
      if (!resp.error) {
        setSelectedEditors([]);
        setSelectedCoOwners([]);
        setSelectedViewers([]);
        //Hit the API to get the module details
        await getProjectEEAndSWMDetails();
      } else {
        customConsole(" Error while providing the access!");
        customConsole(resp);
      }
    }else{
      toast.warn("Please add users!")
    }
  };
  useEffect(() => {
    customConsole(selectedCoOwners);
    customConsole(selectedViewers);
    customConsole(selectedEditors);
  }, [selectedCoOwners, selectedViewers, selectedEditors]);
  return (
    <Drawer open={props.open} anchor={"right"}>
      {existingAccess?.text1?.length ? (
        <Box
          sx={{
            minWidth: { xs: "300px", sm: "450px" },
            maxWidth: { xs: "300px", sm: "450px" },
            padding: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              marginTop: "10px",
              marginBottom: "20px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ fontWeight: "bold", fontSize: "18px" }}>
                {props.data.access_type === PROJECT_ACCESS
                  ? "Project Access"
                  : props.data.access_type === EE_ACCSES
                  ? "EE Component Access"
                  : "Software Module Access"}
              </Typography>
              <IconButton onClick={props.handleClose} sx={styles.closeBtn}>
                X
              </IconButton>
            </Box>
            <Typography sx={{ fontWeight: "bold", fontSize: "16px" }}>
              {existingAccess.text1}
            </Typography>
            <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
              {existingAccess.text2}
            </Typography>
            {existingAccess.text3.length ? (
              <Typography sx={{ fontWeight: "bold", fontSize: "14px" }}>
                {existingAccess.text3}
              </Typography>
            ) : null}
            <Divider />
          </Box>
          <Box>
            {/* Existing Users */}
            <Box sx={styles.subBox}>
              <Typography
                sx={{ fontWeight: "bold", fontSize: "16px", color: "green" }}
              >
                {" "}
                Existing users:
              </Typography>
              {/* Owner */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  gap: 2,
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "gray",
                    minWidth: "70px",
                  }}
                >
                  Owner
                </Typography>
                <strong>:</strong>
                <Typography sx={{ fontSize: "14px", color: "black" }}>
                  {existingAccess.owner_details[0].name} <br />{" "}
                  {existingAccess.owner_details[0].email}
                </Typography>
              </Box>
              {/* Co-owners, editors and viewers */}
              {[
                { key: "Co-Owners", value: existingAccess.co_owner_details },
                { key: "Editors", value: existingAccess.edit_user_details },
                { key: "Viewers", value: existingAccess.view_user_details },
              ].map((user, idx) => {
                return (
                  <Box
                    key={idx}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      alignItems: "center",
                      marginTop: "10px",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "gray",
                        minWidth: "70px",
                      }}
                    >
                      {user.key}
                    </Typography>
                    <strong>:</strong>
                    <MapUserDataInList data={user.value} />
                  </Box>
                );
              })}
            </Box>
            {/* Add New Users */}
            <Box
              sx={{
                ...styles.subBox,
                marginTop: "20px",
                border: "2px solid brown",
                maxHeight: "50vH",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  width: "100%",
                  justifyContent: "space-between",
                  marginBottom: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "16px",
                    color: "brown",
                    textAlign: "start",
                  }}
                >
                  {" "}
                  Add users:
                </Typography>
                <Box>
                  {" "}
                  <Button
                    sx={{ ...styles.commonButtonStyle }}
                    onClick={updateGrantAccess}
                  >
                    + Add
                  </Button>
                </Box>
              </Box>

              {/* Seleect Ownership type and users */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  border: "1px dotted black",
                  padding: "10px",
                }}
              >
                <Typography
                  sx={{
                    fontWeight: "bold",
                    fontSize: "14px",
                    color: "gray",
                    marginTop: "10px",
                    marginBottom: "10px",
                  }}
                >
                  {" "}
                  Select ownership type:
                </Typography>
                <Box sx={{ display: "flex", flexDirection: "row", gap: 2 }}>
                  <Button
                    sx={{
                      // fontWeight: "bold",
                      // color: "black",
                      // backgroundColor: selectType === 1 ? "#B183C7" : "gray",
                      ...styles.commonButtonStyle,
                    }}
                    onClick={() => setSelectedType(1)}
                  >
                    Co-Owners {selectType === 1 ? "✅" : ""}
                  </Button>
                  <Button
                    sx={{
                      // fontWeight: "bold",
                      // color: "black",
                      ...styles.commonButtonStyle,
                      // backgroundColor: selectType === 2 ? "#B183C7" : "gray",
                    }}
                    onClick={() => setSelectedType(2)}
                  >
                    editors {selectType === 2 ? "✅" : ""}
                  </Button>
                  {props.data.access_type === SWM_ACCESS ? (
                    <Button
                      sx={{
                        ...styles.commonButtonStyle,
                        // backgroundColor: selectType === 3 ? "#B183C7" : "gray",
                      }}
                      onClick={() => setSelectedType(3)}
                    >
                      Viewers {selectType === 3 ? "✅" : ""}
                    </Button>
                  ) : null}
                </Box>
                <Typography sx={styles.addedUserTextHeader}>
                  Select users:
                </Typography>
                <Select
                  variant="standard"
                  onChange={(data) => onUserSelection(data)}
                  //   defaultValue={
                  //     usersInSystem.length ? usersInSystem[0].user_id : ""
                  //   }
                >
                  {usersInSystem.map((data, idx) => {
                    return (
                      <MenuItem key={idx} value={data.user_id}>
                        {data.fullname} <br />
                        {data.email}
                        <br />
                        {data?.role_details?.role_name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </Box>
              {/* Selected Users */}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  marginTop: "15px",
                }}
              >
                {/* Co-owners */}
                {selectedCoOwners.length > 0 && (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      overflow: "auto",
                    }}
                  >
                    <Typography
                      sx={{
                        ...styles.addedTextHeader,
                        marginBottom: "5px",
                      }}
                    >
                      Co-Owners :
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexWrap: "wrap",
                        maxHeight: "150px",
                        overflow: "auto",
                        gap: "10px",
                      }}
                    >
                      {selectedCoOwners.map((data, idx) => {
                        return (
                          <Typography
                            key={idx}
                            sx={{
                              border: "1px solid black",
                              borderRadius: "5px",
                              fontWeight: "bold",
                              fontSize: "12px",
                              padding: "10px",
                            }}
                          >
                            {data.fullname}
                            <IconButton
                              style={styles.userCancelBtn}
                              onClick={() =>
                                handleRemoveUserFromSelected(data, 1)
                              }
                            >
                              X
                            </IconButton>
                            <br />
                            {data.email}
                            <br />
                            {data?.role_details?.role_name}
                          </Typography>
                        );
                      })}
                    </Box>
                  </Box>
                )}
                {/* Editors */}
                {selectedEditors.length > 0 && (
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography
                      sx={{
                        ...styles.addedTextHeader,
                        marginTop: "10px",
                        marginBottom: "10px",
                      }}
                    >
                      Editors :
                    </Typography>
                    <Box sx={styles.addedUsersbox}>
                      {selectedEditors.map((data, idx) => {
                        return (
                          <Typography key={idx} sx={styles.addedUserText}>
                            {data.fullname}
                            <span
                              style={styles.addedUserCloseBtn}
                              onClick={() =>
                                handleRemoveUserFromSelected(data, 2)
                              }
                            >
                              X
                            </span>{" "}
                            <br />
                            {data.email}
                            <br />
                            {data?.role_details?.role_name}
                          </Typography>
                        );
                      })}
                    </Box>
                  </Box>
                )}
                {/* Viewers */}
                {selectedViewers.length > 0 && (
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    <Typography sx={styles.addedTextHeader}>
                      Viewers :
                    </Typography>
                    <Box sx={styles.addedUsersbox}>
                      {selectedViewers.map((data, idx) => {
                        return (
                          <Typography key={idx} sx={styles.addedUserText}>
                            {data.fullname}
                            <span
                              style={styles.addedUserCloseBtn}
                              onClick={() =>
                                handleRemoveUserFromSelected(data, 3)
                              }
                            >
                              X
                            </span>{" "}
                            <br />
                            {data.email}
                            <br />
                            {data.role_name}
                          </Typography>
                        );
                      })}
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>{" "}
          </Box>
        </Box>
      ) : null}
    </Drawer>
  );
}

export default ProjectAccessDrawer;

const styles = {
  addedTextHeader: { fontWeight: "bold", fontSize: "14px", color: "gray" },
  userCancelBtn: {
    fontWeight: "bold",
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
    height: "20px",
    width: "20px",
    fontSize: "12px",
    cursor: "pointer",
    marginLeft: "20px",
    "&:hover": {
      color: "black",
      backgroundColor: "red",
    },
  },
  closeBtn: {
    fontWeight: "bold",
    backgroundColor: "red",
    color: "white",
    textAlign: "center",
    height: "25px",
    width: "25px",
    fontSize: "18px",
    cursor: "pointer",
    marginLeft: "20px",
    "&:hover": {
      color: "black",
      backgroundColor: "red",
    },
  },
  subBox: {
    display: "flex",
    flexDirection: "column",
    borderRadius: "10px",
    border: "2px solid green",
    padding: "10px",
    overflow: "auto",
  },
  addedUserTextHeader: {
    fontWeight: "bold",
    fontSize: "14px",
    color: "gray",
    marginTop: "10px",
    marginBottom: "10px",
  },
  addedUserCloseBtn: {
    fontWeight: "bold",
    color: "white",
    marginLeft: "15px",
    backgroundColor: "red",
    borderRadius: "50%",
    padding: "2px",
    "&:hover": {
      backgroundColor: "black",
      color: "red",
    },
    cursor: "pointer",
  },
  addedUserText: {
    border: "1px solid black",
    borderRadius: "5px",
    fontWeight: "bold",
    fontSize: "12px",
    padding: "10px",
  },
  addedUsersbox: {
    display: "flex",
    flexWrap: "wrap",
    maxHeight: "150px",
    overflow: "auto",
    gap: "10px",
  },
  commonButtonStyle: {
    backgroundImage:
      "linear-gradient( to bottom, rgb(7, 5, 69), rgb(103, 86, 133))",
    maxHeight: "30px",
    width: "fit-content",
    borderRadius: "20px",
    color: "white",
    "&:hover": {
      backgroundImage: "linear-gradient(#6b8df2,#021636)",
    },
  },
};
