/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 23-07-2024
 * @description
 */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  ReactFlow,
  Controls,
  Background,
  applyEdgeChanges,
  applyNodeChanges,
  addEdge,
  Handle,
  Position,
} from "@xyflow/react";
import "@xyflow/react/dist/style.css";
import dagre from "dagre";
import { Box } from "@mui/material";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import labCar from "../../redux/actions/labCar";
import { HandleApiActions } from "../../redux/actions/actionHandler";

// Define constants for node dimensions and margins
const nodeWidth = 270;
const nodeHeight = 150;
const marginX = 100;
const marginY = 100;

// Initialize dagre graph
const dagreGraph = new dagre.graphlib.Graph();
dagreGraph.setDefaultEdgeLabel(() => ({}));

const createNodesAndEdges = (projectDetails) => {
  const nodes = [];
  const edges = [];

  // Create project node
  nodes.push({
    id: `project-${projectDetails.project_id}`,
    type: "input",
    data: {
      label: (
        <div>
          <h3>{projectDetails.name}</h3>
          <ul style={{ textAlign: "start" }}>
            <li>
              <strong>ID:</strong> {projectDetails.project_id}
            </li>
            <li>
              <strong>Trademark:</strong> {projectDetails.trade_mark}
            </li>
            {/* <li>
              <strong>Status:</strong> {projectDetails.status || "N/A"}
            </li> */}
          </ul>
        </div>
      ),
    },
    position: { x: 0, y: 0 },
    style: {
      background: "#F3D1FF",
      padding: "10px",
      width: nodeWidth,
      height: nodeHeight,
    },
  });

  // Create EE component nodes and edges
  projectDetails.ee_details.forEach((ee, index) => {
    const eeId = `ee-${ee.ee_id}`;
    const yPos = index * (nodeHeight + marginY);

    nodes.push({
      id: eeId,
      data: {
        label: (
          <div>
            <h3>{ee.description}</h3>
            <ul style={{ textAlign: "start" }}>
              <li>
                <strong>ID:</strong> {ee.ee_id}
              </li>
              <li>
                <strong>Version:</strong> {ee.version}
              </li>
              <li>
                <strong>Assembly Number:</strong> {ee.assembly_number}
              </li>
              <li>
                <strong>Supplier Name:</strong> {ee.supplier_name}
              </li>
            </ul>
          </div>
        ),
        parentId: `project-${projectDetails.project_id}`,
      },
      position: { x: 0, y: yPos },
      style: {
        background: "#FCC9C9",
        padding: "10px",
        width: nodeWidth,
        height: nodeHeight,
      },
    });

    edges.push({
      id: `project-${eeId}`,
      source: `project-${projectDetails.project_id}`,
      target: eeId,
      animated: false,
    });

    // Create Software Module nodes and edges
    projectDetails.swm_details.forEach((swm) => {
      if (swm.ee_id === ee.ee_id) {
        const swmIdString = `swm-${swm.swm_id}`;
        const yPosSWM = yPos + (swm.swm_id + 1) * (nodeHeight + marginY);

      nodes.push({
        id: swmIdString,
        data: {
          label: (
            <div>
              <h3>{swm.name}</h3>
              <ul style={{ textAlign: "start" }}>
                <li>
                    <strong>ID:</strong> {swm.swm_id}
                </li>
                <li>
                  <strong>Description:</strong> {swm.description}
                </li>
                <li>
                  <strong>Version:</strong> {swm.version}
                </li>
              </ul>
            </div>
          ),
          parentId: eeId,
        },
          position: { x: 0, y: yPosSWM },
        style: {
          background: "#92FF9D",
          padding: "10px",
          width: nodeWidth,
          height: nodeHeight,
        },
      });

      edges.push({
        id: `${eeId}-${swmIdString}`,
        source: eeId,
        target: swmIdString,
        animated: false,
      });
      }
    });
  });

  return { nodes, edges };
};

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 23-07-2024
 * @description
 * @param {}
 * @return {}
 */
function VlcMindMap({ selectedProjectId }) {
  /* */
  const dispatch = useDispatch();

  /* useSelectors */
  const { projectDetailsMindMap } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );

  /* Project */
  const [mindMapProjectDetails, setMindMapProjectDetails] = useState({});

  /* Nodes */
  const [flowNodes, setFlowNodes] = useState([]);
  const [flowEdges, setFlowEdges] = useState([]);
  const [selectedNode, setSelectedNode] = useState(null);

  /* useEffects */
  useEffect(() => {
    if (selectedProjectId) {
      dispatch(
        HandleApiActions({
          ...labCar.get_project_details_mind_map,
          params: {
            project_id: selectedProjectId,
          },
          show_toast: false,
        })
      );
    }
  }, [selectedProjectId]);

  useEffect(() => {
    if (projectDetailsMindMap && projectDetailsMindMap.zones) {
      setMindMapProjectDetails(projectDetailsMindMap);
    }
  }, [projectDetailsMindMap]);

  useEffect(() => {
    if (mindMapProjectDetails && mindMapProjectDetails.ee_details) {
      const { nodes, edges } = createNodesAndEdges(mindMapProjectDetails);

    // Use dagre to layout nodes
    dagreGraph.setGraph({ rankdir: "TB" });
    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, { width: nodeWidth, height: nodeHeight });
    });
    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });
    dagre.layout(dagreGraph);

    // Update node positions
    const layoutedNodes = nodes.map((node) => {
      const layoutNode = dagreGraph.node(node.id);
      node.position = {
          x: layoutNode.x - nodeWidth / 2,
        y: layoutNode.y - nodeHeight / 2,
      };
      return node;
    });

    setFlowNodes(layoutedNodes);
    setFlowEdges(edges);
    }
  }, [mindMapProjectDetails]);

  const onNodesChange = useCallback(
    (changes) => setFlowNodes((nds) => applyNodeChanges(changes, nds)),
    []
  );

  const onEdgesChange = useCallback(
    (changes) => setFlowEdges((eds) => applyEdgeChanges(changes, eds)),
    []
  );

  const onConnect = useCallback(
    (params) => setFlowEdges((eds) => addEdge(params, eds)),
    []
  );

  const handleNodeClick = useCallback((event, node) => {
    setSelectedNode(node.id);
  }, []);

  const animatedEdges = useMemo(() => {
    return flowEdges.map((edge) => {
      if (edge.source === selectedNode || edge.target === selectedNode) {
        return {
          ...edge,
          animated: true,
        };
      }
      return {
        ...edge,
        animated: false,
      };
    });
  }, [flowEdges, selectedNode]);

  return (
    <Box
      sx={{
        height: "470px",
        width: "100%",
        backgroundColor: "#F5F5F5",
        border: "1px solid grey",
        borderRadius: 1,
        padding: "20px",
        mt: 1,
        boxShadow: 4,
      }}
    >
      <ReactFlow
        nodes={flowNodes}
        edges={animatedEdges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onNodeClick={handleNodeClick}
        fitView
        minZoom={0.0000001}
        snapToGrid={true}
        snapGrid={[16, 16]}
      >
        <Background />
        <Controls />
      </ReactFlow>
    </Box>
  );
}

export default VlcMindMap;
