/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 15-06-2024
 * @description Styling for the Authentication component.
 */
const styles = {
    container: {
        height: "100vh",
        backgroundColor: "black",
    },
    mainContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        // height: '97vh',
        minWidth: { xm: "none", sm: "360px" },
        // maxWidth: '420px',
        // margin: '0 auto',
        left: { md: "20%" },
        zIndex: 100,
        marginLeft: { xs: 0, md: "-50%" },
    },
    // leftContainer: {
    //     // display: { xs: 'none', sm: 'flex' },
    //     // flexDirection: 'column',
    //     // alignItems: 'center',
    //     // justifyContent: 'center',
    //     // // backgroundColor: '#6eacea',
    //     // // backgroundColor: 'white',
    //     // padding: '20px',


    //     backgroundImage: 'url("auth_bg_car.png")',
    //     backgroundSize: "cover",
    //     backgroundPosition: "left",
    //     // width: "100%",
    // },
    // rightContainer: {
    //     // backgroundColor: "white",
    // },
    // avatar: {
    //     mb: 3,
    //     // bgcolor: "white",
    //     width: "100%",
    //     height: 32,
    //     // border: "1px solid red",
    // }
};

export default styles;