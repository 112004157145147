/**
 * @author Tahir Shaik - tahir@bookwater.com - +91-9177327808
 * @date 11-06-2024
 * @description This is the Virtual Type Approval Page for the Application
 */
//Importing the required components/functions from the libraries
import { Typography } from "@mui/material";
import React from "react";

//Main Virtual Type Approval Page Function
function Vta() {
  return (
    <div>
      <Typography sx={{ marginTop: "20px" }}>
        Virtual Type Approvals related content here
      </Typography>
    </div>
  );
}

export default Vta;
