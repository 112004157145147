/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 04-07-2024
 * @description
 */
import {
  Box,
  Dialog,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemText,
  Paper,
  Stack,
  TableCell,
  TableRow,
  Typography,
  styled,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import userManagementApis from "../../redux/actions/userManagent";
import UpdateUser from "./UpdateUser";
import AddIcon from "@mui/icons-material/Add";
import { convertUtcToTimeZone } from "../../utils/convertUtcToTimeZone";
import {
  CustomAddDialog,
  CustomButton,
  CustomDataGridTable,
  CustomTextFieldN,
  PhoneNumberTextField,
  SelectTextField,
} from "../../components";

/**
 * Styled component for customizing TableCell in the table.
 */
const CustomTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    // fontSize: 14,
    // fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

/**
 * Styled component for customizing TableRow in the table.
 */
const CustomTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
  "&:hover": {
    cursor: "pointer",
    backgroundColor: theme.palette.action.hover,
  },
}));

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 04-07-2024
 * @description
 * @param {}
 * @return {}
 */
function Users() {
  const [searchNameValue, setSearchNameValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [isRightSideDrawerOpen, setIsRightSideDrawerOpen] = useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [isAddUserDialogOpen, setIsAddUserDialogOpen] = useState(false);
  const [isUpdateUserDialogOpen, setIsUpdateUserDialogOpen] = useState(false);
  const [drawerData, setDrawerData] = useState(null);
  const { usersList } = useSelector(
    (state) => state.userManagementReducer,
    shallowEqual
  );
  const { countryCodes } = useSelector(
    (state) => state.userManagementReducer,
    shallowEqual
  );
  const { rolesList } = useSelector(
    (state) => state.userManagementReducer,
    shallowEqual
  );
  const dispatch = useDispatch();
  const [tableRowsData, setTableRowsData] = useState([]);
  const [userData, setUserData] = useState({});
  const [rolesPage, setRolesPage] = useState(0);
  const [RolesRowsPerPage, setRolesRowsPerPage] = useState(100);
  const profileDetails = useSelector((store) => store.profileReducer);

  useEffect(() => {
    let filteredUsersList = usersList?.map((user, index) => {
      return {
        ...user,
        id: page * rowsPerPage + index + 1,
        role: user?.role_details?.role_name,
        department: user?.role_details?.department,
        region: user.profile.region,
        created_at: convertUtcToTimeZone(
          user.created_at,
          profileDetails?.profile?.profile?.region
        ),
      };
    });

    setTableRowsData(filteredUsersList);
  }, [usersList]);

  useEffect(() => {
    handleGetUsersList();
  }, [page, rowsPerPage]);

  const handleGetUsersList = () => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_users_list,
        params: {
          page_number: page + 1,
          page_size: rowsPerPage,
          sort_column: "created_at",
          sort_order: "ASC",
        },
        show_toast: false,
      })
    );
  };

  // Toggles the state of the drawer.
  const toggleUpdateDeleteDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  // Toggles the state of the drawer.
  const toggleDrawer = (newOpen) => () => {
    setIsRightSideDrawerOpen(newOpen);
  };

  // Toggles the state of the dialog.
  const addUserToggleDialog = (newOpen) => () => {
    setIsAddUserDialogOpen(newOpen);
  };

  // Toggles the state of the dialog.
  const updateUserToggleDialog = (newOpen) => () => {
    setIsUpdateUserDialogOpen(newOpen);
  };

  // Handles the change of page in pagination.
  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  // Handles the change of rows per page in pagination.
  const handleRowsPerPageChange = (e) => {
    console.log(e.target.value);
    let newValue = +e.target.value;
    setRowsPerPage(newValue);
    setPage(0);
  };

  // Handles the click event for searching names.
  const handleNameSearchClick = () => {
    console.log("searchNameValue: ", searchNameValue);
  };

  // Handles the click event to open dialog.
  const handleAddUserDialog = () => {
    addUserToggleDialog(true)();
  };

  // Handles the click event for table rows for editing
  const handleTableRowClick = ({ params }) => {
    toggleUpdateDeleteDrawer(true)();

    setUserData({ ...params.row });
  };

  // Handles the click event to add new user
  const handleAddNewUserClick = ({
    fullname,
    email,
    phone_code,
    phone,
    region,
    role_id,
  }) => {
    let currentTime = new Date().toISOString();
    console.log({
      fullname,
      email,
      phone_code,
      phone,
      current_time: currentTime,
      region,
      role_id,
    });

    dispatch(
      HandleApiActions({
        ...userManagementApis.add_user,
        params: {
          fullname,
          email,
          ...(phone && phone_code && { phone_code }),
          ...(phone && { phone }),
          current_time: currentTime,
          region,
          role_id,
        },
        show_toast: true,
      })
    ).then(() => {
      handleGetUsersList();
    });

    // setTimeout(handleGetUsersList, 1500);

    addUserToggleDialog(false)();
  };

  // Handles the click event to update user
  const handleUpdateUserClick = ({}) => {};

  // Handles the click event to delete user
  const handleDeleteUserClick = ({ user_id }) => {
    let currentTime = new Date().toISOString();
    console.log({ user_id, current_time: currentTime });

    // dispatch(DeleteUserAction({user_id, current_time: currentTime}));
    // dispatch(DeleteUserAction({user_id: "T2Qpee5RJ", current_time: currentTime}));
  };

  // Handles the click event to change user status
  const handleUserStatusClick = ({}) => {};

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      width: 90,
      filterable: false,
      sortable: false,
    },
    { field: "fullname", headerName: "Name", width: 220 },
    { field: "email", headerName: "Email", width: 220 },
    { field: "role", headerName: "Role", width: 220 },
    { field: "department", headerName: "Department", width: 220 },
    { field: "region", headerName: "Region", width: 220 },
    { field: "created_at", headerName: "Registered At", width: 220 },
    // { field: 'created_by', headerName: 'Created By', width: 220 },
    // { field: 'updated_at', headerName: 'Updated At', width: 220 },
    // { field: 'updated_by', headerName: 'Updated By', width: 220 },
  ];

  return (
    <Box style={{ marginBottom: "60px" }} elevation={3}>
      <Box sx={styles.tblFun}>
        <CustomButton
          startIcon={<AddIcon />}
          size={"medium"}
          variant={"contained"}
          btnName={"Add"}
          handleOnClick={handleAddUserDialog}
          btnStyle={{
            ml: 10,
          }}
        />
      </Box>
      <Paper
        sx={{
          height: 470,
          width: "100%",
          // maxWidth: { xs: "350px", sm: "400px", md: "100%" },
          // overflow: "auto",
        }}
      >
        <CustomDataGridTable
          tableRowsData={tableRowsData}
          columns={columns}
          handleTableRowClick={handleTableRowClick}
          // Pagination //
          rowCount={
            tableRowsData.length >= rowsPerPage
              ? -1
              : page * rowsPerPage + tableRowsData.length
          }
          pageSizeOptions={[25, 50, 100]}
          paginationModel={{ page: page, pageSize: rowsPerPage }}
          onPaginationModelChange={(model) => {
            setPage(model.page);
            setRowsPerPage(model.pageSize);
          }}
          // isLoading={isLoading}
        />
      </Paper>

      <RightSideDrawer
        open={isRightSideDrawerOpen}
        drawerData={drawerData}
        setDrawerData={setDrawerData}
        toggleDrawer={toggleDrawer}
      />
      <AddUserDialog
        open={isAddUserDialogOpen}
        addUserToggleDialog={addUserToggleDialog}
        handleAddNewUserClick={handleAddNewUserClick}
        countryCodes={countryCodes}
        rolesList={rolesList}
      />
      <UpdateDeleteRightSideDrawer
        open={openDrawer}
        toggleDrawer={toggleUpdateDeleteDrawer}
        userData={userData}
      />
    </Box>
  );
}

export default Users;

// Styling for the Users component
const styles = {
  container: {
    width: "100%",
    overflow: "hidden",
  },
  tblFun: {
    //mx: 2,
    mb: 2,
    display: "flex",

    justifyContent: "flex-end",
  },
};

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 04-07-2024
 * @description Component for displaying a right-side drawer with additional details.
 * @param { open, drawerData, setDrawerData, toggleDrawer }
 * @return The rendered right side drawer component.
 */
function RightSideDrawer({ open, drawerData, setDrawerData, toggleDrawer }) {
  const handleOnClose = () => {
    setDrawerData(null);

    toggleDrawer(false)();
  };

  const DrawerList = (
    <Box sx={{ width: 270 }} role="presentation" onClick={toggleDrawer(false)}>
      <Typography mt={1} textAlign={"center"} variant="h5" gutterBottom>
        {drawerData?.heading}
      </Typography>
      <Divider />
      <List>
        {drawerData?.items?.map((text, index) => (
          <ListItem key={text}>
            <ListItemText primary={text} />
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={handleOnClose}>
        {DrawerList}
      </Drawer>
    </div>
  );
}

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 04-07-2024
 * @description
 * @param {}
 * @return {}
 */
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 04-07-2024
 * @description
 * @param {}
 * @return {}
 */
const AddUserDialog = ({
  open,
  addUserToggleDialog,
  handleAddNewUserClick,
  countryCodes,
  rolesList,
}) => {
  const [fullName, setFullName] = useState("");
  const [fullNameError, setFullNameError] = useState("");
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");
  const [role, setRole] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [phoneNumberError, setPhoneNumberError] = useState("");
  const [regionOptions, setRegionOptions] = useState([]);
  const [selectedRegion, setSelectedRegion] = useState("");
  const [roleDepartment, setRoleDepartment] = useState("");
  const [selectedRoleDepartment, setSelectedRoleDepartment] = useState("");
  const [roleDepartmentOptions, setRoleDepartmentOptions] = useState("");
  const [phoneCodeOptions, setPhoneCodeOptions] = useState("");
  const [selectedPhoneCode, setSelectedPhoneCode] = useState("");
  const [roleId, setRoleId] = useState("");

  const clearDataFormFields = () => {
    setFullName("");
    setFullNameError("");
    setEmail("");
    setEmailError("");
    setSelectedRoleDepartment("");
    setSelectedRegion("");
    setSelectedPhoneCode("");
    setPhoneNumber("");
    setPhoneNumberError("");
  };

  const handleClose = () => {
    addUserToggleDialog(false)();

    clearDataFormFields();
  };

  useEffect(() => {
    let filteredRegions = countryCodes
      ?.map((el) => {
        return { value: el.time_zone, label: el.time_zone };
      })
      .sort((a, b) => {
        return a.value.localeCompare(b.value);
      });

    let filteredPhoneCodes = countryCodes
      ?.map((el) => {
        return {
          value: el.phone_code,
          label: `${el.country}: ${el.phone_code}`,
        };
      })
      .sort((a, b) => {
        return a.label.localeCompare(b.label);
      });

    setRegionOptions(filteredRegions);
    setPhoneCodeOptions(filteredPhoneCodes);
  }, [countryCodes]);

  useEffect(() => {
    let filteredRoleDepartment = rolesList
      ?.filter((el) => el.is_active)
      ?.map((el) => {
        return {
          value: el.role_name,
          label: `${el.role_name} - ${el.department}`,
        };
      })
      ?.sort((a, b) => {
        return a.value.localeCompare(b.value);
      });

    setRoleDepartmentOptions(filteredRoleDepartment);
  }, [rolesList]);

  useEffect(() => {
    let getRoleId = rolesList.find(
      (el) => el.role_name == selectedRoleDepartment
    );

    if (getRoleId) {
      console.log("getRoleId", getRoleId.role_id);

      setRoleId(getRoleId.role_id);
    }
  }, [selectedRoleDepartment]);

  const isFormValid = () => {
    return (
      fullName.trim() !== "" &&
      !fullNameError &&
      email.trim() !== "" &&
      !emailError &&
      (phoneNumber.trim() === "" || phoneNumber.length === 10) &&
      selectedRoleDepartment !== "" &&
      selectedRegion !== ""
    );
  };

  return (
    <CustomAddDialog
      open={open}
      onClose={handleClose}
      dialogTitle={"Add User"}
      dialogContent={
        <Stack spacing={2} mb={2}>
          <CustomTextFieldN
            size={"small"}
            label={"Full Name*"}
            placeholder={"Full Name"}
            type={"text"}
            fldType={"text"}
            value={fullName}
            setValue={setFullName}
            valueError={fullNameError}
            setValueError={setFullNameError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Email*"}
            placeholder={"Email"}
            type={"email"}
            fldType={"email"}
            value={email}
            setValue={setEmail}
            valueError={emailError}
            setValueError={setEmailError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
          <SelectTextField
            size={"small"}
            label={"Role-Department*"}
            optionsList={roleDepartmentOptions}
            selectedValue={selectedRoleDepartment}
            setSelectedValue={setSelectedRoleDepartment}
            variant={"standard"}
            sltFldStyle={{
              width: "270px",
            }}
          />
          <SelectTextField
            size={"small"}
            label={"Time Zone*"}
            optionsList={regionOptions}
            selectedValue={selectedRegion}
            setSelectedValue={setSelectedRegion}
            variant={"standard"}
            sltFldStyle={{
              width: "270px",
            }}
          />
          {/* <SearchSelectTextField
              size={"small"}
              label={"Select Region"}
              placeholder={"Select Region"}
              noOptionsText="No region found"
              optionsList={regionOptions}
              selectedValue={selectedRegion}
              setSelectedValue={setSelectedRegion}
              txtFldStyle={{
                width: "270px",
                height: "30px",
              }}
            /> */}
          <PhoneNumberTextField
            size="small"
            variant={"standard"}
            phoneCodeLabel="Country"
            phoneNumberLabel="Phone Number (Optional)"
            phoneCodeOptions={phoneCodeOptions}
            selectedPhoneCode={selectedPhoneCode}
            setSelectedPhoneCode={setSelectedPhoneCode}
            phoneNumber={phoneNumber}
            setPhoneNumber={setPhoneNumber}
            phoneNumberError={phoneNumberError}
            setPhoneNumberError={setPhoneNumberError}
            phoneCodeStyle={{
              width: "75px",
              marginRight: "5px",
            }}
            phoneNumberStyle={{
              width: "190px",
            }}
          />
        </Stack>
      }
      onConfirm={() => {
        handleAddNewUserClick({
          fullname: fullName,
          email,
          phone_code: selectedPhoneCode,
          phone: phoneNumber,
          region: selectedRegion,
          role_id: Number(roleId),
        });
        clearDataFormFields();
      }}
      onDisabled={!isFormValid()}
    />
  );
};

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 06-07-2024
 * @description
 * @param {}
 * @return {}
 */
function UpdateDeleteRightSideDrawer({ open, userData, toggleDrawer }) {
  const handleOnClose = () => {
    toggleDrawer(false)();
  };

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={handleOnClose}>
        <Box width={"390px"}>
          <UpdateUser userData={userData} toggleDrawer={toggleDrawer} />
        </Box>
      </Drawer>
    </div>
  );
}
