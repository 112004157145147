/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 27-06-2024
 * @description Component for managing roles and access permissions view.
 */
import React, { useState } from 'react'
import AddRolesAndAccess from './AddRolesAndAccess';
import RolesAndAccess from './RolesAndAccess';

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 27-06-2024
 * @description Main component for managing roles and access permissions.
 * @param {}
 * @return The rendered main roles and access management component.
 */
function RolesAndAccessMain() {
    const [isAddRole, setIsAddRole] = useState(false);

    return (
        <>
            {
                isAddRole ?
                    <AddRolesAndAccess setIsAddRole={setIsAddRole} />
                    :
                    <RolesAndAccess setIsAddRole={setIsAddRole} />

            }
        </>
    )
}

export default RolesAndAccessMain;
