/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-06-2024
 * @description Component for adding roles and access permissions.
 */

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  DialogContentText,
  Divider,
  FormControlLabel,
  Grid,
  IconButton,
  Tab,
  Tabs,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import userManagementApis from "../../redux/actions/userManagent";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CloseIcon from "@mui/icons-material/Close";
import PropTypes from "prop-types";
import profileActions from "../../redux/actions/profile";
import DeleteIcon from "@mui/icons-material/Delete";
import UpdateIcon from "@mui/icons-material/Update";
import {
  CustomConfirmationDialog,
  CustomTextField,
  CustomTextFieldN,
  SelectTextField,
} from "../../components";

/**
 * CustomTabPanel component renders a tab panel for a tab-based interface.
 */
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-06-2024
 * @description Component for adding roles and access permissions.
 * @param { setIsAddRole }
 * @return The rendered add roles and access component.
 */
function UpdateRolesAndAccess({ rolesAndAccessData, toggleDrawer }) {
  const {
    role_id,
    role_name,
    department: dept,
    sub_department,
    description: desp,
    access_to_pages,
    access_to_features,
    is_active,
    is_deleted,
  } = rolesAndAccessData;

  const [roleName, setRoleName] = useState(role_name || "");
  const [department, setDepartment] = useState(dept || "");
  const [subDepartment, setSubDepartment] = useState(sub_department || "");
  const [subDepartmentError, setSubDepartmentError] = useState("");
  const [description, setDescription] = useState(desp || "");
  const [descriptionError, setDescriptionError] = useState("");

  const { menu_options } = useSelector(
    (state) => state.settingsReducer.metaData,
    shallowEqual
  );
  const { featuresList } = useSelector(
    (state) => state.userManagementReducer,
    shallowEqual
  );
  const [menuOptionsList, setMenuOptionsList] = useState([]);
  const [selectedMenuOption, setSelectedMenuOption] =
    useState("USER MANAGEMENT");
  const [selectedMenus, setSelectedMenus] = useState(access_to_pages || []);
  const [addedSelectedMenus, setAddedSelectedMenus] = useState([]);
  const [removedSelectedMenus, setRemovedSelectedMenus] = useState([]);

  let access_to_features_ids = access_to_features?.map((id) => Number(id));
  const [selectedMenuOptionFeaturesList, setSelectedMenuOptionFeaturesList] =
    useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState(
    access_to_features_ids || []
  );
  const [removedSelectedFeatures, setRemovedSelectedFeatures] = useState([]);
  const [addedSelectedFeatures, setAddedSelectedFeatures] = useState([]);

  const dispatch = useDispatch();
  const [isRoleActive, setIsRoleActive] = useState(is_active || false);
  const [isRoleDeleted, setIsRoleDeleted] = useState(is_deleted || false);
  console.log("DAta: ----------------------", rolesAndAccessData);
  const [openRoleDeleteDialog, setOpenRoleDeleteDialog] = useState(false);

  const [openRoleStatusDialog, setOpenRoleStatusDialog] = useState(false);
  const [pendingRoleStatus, setPendingRoleStatus] = useState(isRoleActive);

  const [selectedRoleDepartment, setSelectedRoleDepartment] = useState("");
  const [roleDepartmentOptions, setRoleDepartmentOptions] = useState("");

  const profileDetails = useSelector((store) => store.profileReducer);
  console.log("profileDetails", profileDetails);
  const [loggedInUserRoleId, setLoggedInUserRoleId] = useState("");

  // Tabs
  const [tabValue, setTabValue] = useState(0);

  useEffect(() => {
    const filteredMenuOptionsList = menu_options?.filter(
      (el) => el !== "DEFAULT"
    );
    setMenuOptionsList(filteredMenuOptionsList);
  }, [menu_options]);

  useEffect(() => {
    console.log("addedSelectedMenus", addedSelectedMenus);
    console.log("removedSelectedMenus", removedSelectedMenus);
    console.log("addedSelectedFeatures", addedSelectedFeatures);
    console.log("removedSelectedFeatures", removedSelectedFeatures);
  }, [
    addedSelectedMenus,
    removedSelectedMenus,
    addedSelectedFeatures,
    removedSelectedFeatures,
  ]);

  const { rolesList } = useSelector(
    (state) => state.userManagementReducer,
    shallowEqual
  );

  useEffect(() => {
    if (profileDetails?.profile?.role_id) {
      setLoggedInUserRoleId(profileDetails?.profile?.role_id);
    }
  }, [profileDetails]);

  useEffect(() => {
    handleGetRolesAndAccessList();
  }, []);

  useEffect(() => {
    let filteredRoleDepartment = rolesList
      ?.map((el) => {
        return {
          value: el.role_name,
          label: `${el.role_name} - ${el.department}`,
        };
      })
      .sort((a, b) => {
        return a.value.localeCompare(b.value);
      });

    setRoleDepartmentOptions(filteredRoleDepartment);
  }, [rolesList]);

  useEffect(() => {
    const filteredFeaturesList = featuresList?.filter(
      (el) => el.page_name == selectedMenuOption
    );
    setSelectedMenuOptionFeaturesList(filteredFeaturesList);
  }, [selectedMenuOption]);

  const handleGetRolesAndAccessList = () => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_roles_list,
        params: {
          page_number: 1,
          page_size: 100,
          sort_column: "created_at",
          sort_order: "ASC",
        },
        show_toast: false,
      })
    );
  };

  // Handles the click event to update role
  const handleUpdateRoleClick = () => {
    let currentTime = new Date().toISOString();

    if (subDepartment !== sub_department || description !== desp) {
      dispatch(
        HandleApiActions({
          ...userManagementApis.update_role,
          params: {
            current_time: currentTime,
            sub_department: subDepartment,
            description,
            role_id,
          },
          show_toast: true,
        })
      ).then(() => {
        handleGetRolesAndAccessList();
      });
    }

    // setTimeout(handleGetRolesAndAccessList, 500);
    // toggleDrawer(false)();
  };

  const handleGetUserProfileDetails = () => {
    dispatch(
      HandleApiActions({
        ...profileActions.get_profile_details,
        show_toast: false,
        params: {},
      })
    );
  };

  // const handleAccessMenuClick = () => {
  //   let currentTime = new Date().toISOString();

  //   if (addedSelectedMenus.length > 0) {
  //     handleAddMenusAccessClick({
  //       current_time: currentTime,
  //       role_id,
  //       access_to_pages: addedSelectedMenus,
  //     });
  //   }

  //   if (removedSelectedMenus.length > 0) {
  //     handleRemoveMenusAccessClick({
  //       current_time: currentTime,
  //       role_id,
  //       access_to_pages: removedSelectedMenus,
  //     });
  //   }

  //   setTimeout(handleGetRolesAndAccessList, 500);
  //   setTimeout(handleGetUserProfileDetails, 500);
  //   toggleDrawer(false)();
  // };

  // const handleAccessFeaturesClick = () => {
  //   let currentTime = new Date().toISOString();

  //   if (addedSelectedFeatures.length > 0) {
  //     handleAddFeaturesAccessClick({
  //       current_time: currentTime,
  //       role_id,
  //       access_to_features: addedSelectedFeatures,
  //     });
  //   }

  //   if (removedSelectedFeatures.length > 0) {
  //     handleRemoveFeaturesAccessClick({
  //       current_time: currentTime,
  //       role_id,
  //       access_to_features: removedSelectedFeatures,
  //     });
  //   }

  //   setTimeout(handleGetRolesAndAccessList, 500);
  //   setTimeout(handleGetUserProfileDetails, 500);
  //   toggleDrawer(false)();
  // };

  const handleRoleStatusChange = (event, newStatus) => {
    if (newStatus !== null) {
      setPendingRoleStatus(newStatus);
      setOpenRoleStatusDialog(true);
    }
  };

  const handleRoleDialogClose = (confirmed) => {
    let currentTime = new Date().toISOString();

    if (confirmed) {
      setIsRoleActive(pendingRoleStatus);

      handleRoleStatusClick({
        current_time: currentTime,
        is_active: pendingRoleStatus,
        role_id,
      });

      // setTimeout(handleGetRolesAndAccessList, 500);
    }
    setOpenRoleStatusDialog(false);
  };

  // Handles the click event to change role status
  const handleRoleStatusClick = ({ current_time, is_active, role_id }) => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.change_role_status,
        params: { current_time, is_active, role_id },
        show_toast: true,
      })
    ).then(() => {
      handleGetRolesAndAccessList();
    });
  };

  // Handles the click event to add menus access
  const handleAddMenusAccessClick = ({ access_to_pages }) => {
    let currentTime = new Date().toISOString();

    if (role_id && access_to_pages?.length > 0) {
      dispatch(
        HandleApiActions({
          ...userManagementApis.add_page_access,
          params: { current_time: currentTime, role_id, access_to_pages },
          show_toast: true,
        })
      ).then(() => {
        handleGetRolesAndAccessList();
        handleGetUserProfileDetails();
      });

      // setTimeout(handleGetRolesAndAccessList, 500);
      // setTimeout(handleGetUserProfileDetails, 500);
    }
  };

  // Handles the click event to remove menus access
  const handleRemoveMenusAccessClick = ({ access_to_pages }) => {
    let currentTime = new Date().toISOString();

    if (role_id && access_to_pages?.length > 0) {
      dispatch(
        HandleApiActions({
          ...userManagementApis.remove_page_access,
          params: { current_time: currentTime, role_id, access_to_pages },
          show_toast: true,
        })
      ).then(() => {
        handleGetRolesAndAccessList();
        handleGetUserProfileDetails();
      });
    }

    // setTimeout(handleGetRolesAndAccessList, 500);
    // setTimeout(handleGetUserProfileDetails, 500);
  };

  // Handles the click event to add features access
  const handleAddFeaturesAccessClick = ({ access_to_features }) => {
    let currentTime = new Date().toISOString();

    if (role_id && access_to_features?.length > 0) {
      dispatch(
        HandleApiActions({
          ...userManagementApis.add_feature_access,
          params: { current_time: currentTime, role_id, access_to_features },
          show_toast: true,
        })
      ).then(() => {
        handleGetRolesAndAccessList();
        handleGetUserProfileDetails();
      });
    }

    // setTimeout(handleGetRolesAndAccessList, 500);
    // setTimeout(handleGetUserProfileDetails, 500);
  };

  // Handles the click event to remove features access
  const handleRemoveFeaturesAccessClick = ({ access_to_features }) => {
    let currentTime = new Date().toISOString();

    if (role_id && access_to_features?.length > 0) {
      dispatch(
        HandleApiActions({
          ...userManagementApis.remove_feature_access,
          params: { current_time: currentTime, role_id, access_to_features },
          show_toast: true,
        })
      ).then(() => {
        handleGetRolesAndAccessList();
        handleGetUserProfileDetails();
      });
    }

    // setTimeout(handleGetRolesAndAccessList, 500);
    // setTimeout(handleGetUserProfileDetails, 500);
  };

  // Handles the click event to delete role
  const handleDeleteRoleClick = () => {
    setOpenRoleDeleteDialog(true);
  };

  const handleConfirmRoleDelete = () => {
    let currentTime = new Date().toISOString();

    dispatch(
      HandleApiActions({
        ...userManagementApis.delete_role,
        params: { current_time: currentTime, role_id },
        show_toast: true,
      })
    ).then(() => {
      handleGetRolesAndAccessList();
    });
    // setTimeout(handleGetRolesAndAccessList, 500);

    toggleDrawer(false)();
  };

  const handleCancelRoleDelete = () => {
    setOpenRoleDeleteDialog(false);
  };

  const isUpdateRoleValid = () => {
    return (
      !subDepartmentError &&
      !descriptionError &&
      (subDepartment.trim() !== sub_department || description.trim() !== desp)
    );
  };

  const isUpdateAccessMenuValid = () => {
    const getAccessToPages = [...access_to_pages];
    const getSelectedMenus = [...selectedMenus];

    const sortedAccessPages = getAccessToPages?.slice()?.sort();
    const sortedSelectedMenus = getSelectedMenus?.slice()?.sort();

    return (
      JSON.stringify(sortedAccessPages) !== JSON.stringify(sortedSelectedMenus)
    );
  };

  const isUpdateAccessFeaturesValid = () => {
    const getAccessToFeaturesIds = [...access_to_features_ids];
    const getSelectedFeaturesIds = [...selectedFeatures];

    const sortedAccessFeaturesIds = getAccessToFeaturesIds?.slice()?.sort();
    const sortedSelectedFeaturesIds = getSelectedFeaturesIds?.slice()?.sort();

    return (
      JSON.stringify(sortedAccessFeaturesIds) !==
      JSON.stringify(sortedSelectedFeaturesIds)
    );
  };

  // handle tab change event
  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  const isFormValid = () => {
    return (
      subDepartment !== sub_department ||
      description !== desp ||
      isRoleActive !== is_active ||
      addedSelectedMenus.length > 0 ||
      removedSelectedMenus.length > 0 ||
      addedSelectedFeatures.length > 0 ||
      removedSelectedFeatures.length > 0
    );
  };

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100vh" }}>
      <Box sx={{ display: "flex", alignItems: "center", p: 1, boxShadow: "3" }}>
        <Box sx={{ flexGrow: 1, textAlign: "center" }}>
          <Typography
            variant="h6"
            textAlign="start"
            ml="10px"
            fontWeight="bold"
          >
            Update Role & Access
          </Typography>
        </Box>
        <IconButton onClick={toggleDrawer(false)}>
          <CloseIcon />
        </IconButton>
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          border: "1px solid lightgrey",
        }}
      >
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab
            sx={{
              "&.Mui-selected": {
                fontWeight: 600,

                color: "black", // Selected tab text color
              },
            }}
            label="Role Details"
            {...a11yProps(0)}
          />
          <Tab
            sx={{
              "&.Mui-selected": {
                fontWeight: 600,

                color: "black", // Selected tab text color
              },
            }}
            label="Menu Access"
            {...a11yProps(1)}
          />
          <Tab
            sx={{
              "&.Mui-selected": {
                fontWeight: 600,

                color: "black", // Selected tab text color
              },
            }}
            label="Features Access"
            {...a11yProps(2)}
          />
        </Tabs>
      </Box>

      <Box sx={{ flexGrow: 1, overflow: "auto" }}>
        <CustomTabPanel value={tabValue} index={0}>
          <Card
            style={{
              marginTop: "8px",
              border: "1px solid lightgrey",
              boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
            }}
          >
            <Typography
              fontWeight="bold"
              mt={1}
              textAlign={"center"}
              variant="h6"
              gutterBottom
            >
              Update Role
            </Typography>
            <Divider />
            <Grid
              container
              columnSpacing={2}
              rowSpacing={1}
              px={3}
              pt={2}
              pb={4}
            >
              {/* <Grid container columnSpacing={2} rowSpacing={1}> */}
              <Grid item xs={12}>
                <CustomTextField
                  size="small"
                  label="Role Name"
                  placeholder="Role Name"
                  variant={"standard"}
                  value={roleName}
                  setValue={setRoleName}
                  inputProps={{
                    disabled: true,
                  }}
                  txtFldStyle={{
                    backgroundColor: "white",
                    mt: 2,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextField
                  size="small"
                  label="Department"
                  placeholder="Department"
                  variant={"standard"}
                  value={department}
                  setValue={setDepartment}
                  inputProps={{
                    disabled: true,
                  }}
                  txtFldStyle={{
                    backgroundColor: "white",
                    mt: 2,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextFieldN
                  size="small"
                  label="Sub Department"
                  placeholder="Sub Department"
                  variant={"standard"}
                  type={"text"}
                  fldType={"textO"}
                  value={subDepartment}
                  setValue={setSubDepartment}
                  valueError={subDepartmentError}
                  setValueError={setSubDepartmentError}
                  txtFldStyle={{
                    backgroundColor: "white",
                    mt: 2,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <CustomTextFieldN
                  size="small"
                  label="Description"
                  placeholder="Description"
                  variant={"standard"}
                  type={"text"}
                  fldType={"text"}
                  value={description}
                  setValue={setDescription}
                  valueError={descriptionError}
                  setValueError={setDescriptionError}
                  isMultiline={true}
                  minRows={1}
                  maxRows={5}
                  txtFldStyle={{
                    backgroundColor: "white",
                    mt: 2,
                  }}
                />
              </Grid>
              <Grid item xs={12}>
                <Box
                  display="flex"
                  alignItems="center"
                  justifyContent="space-around"
                  sx={{ width: "100%", mt: 2 }}
                >
                  <Typography variant="body1" component="span" marginRight={2}>
                    Role Status:
                  </Typography>
                  <ToggleButtonGroup
                    value={isRoleActive}
                    exclusive
                    onChange={handleRoleStatusChange}
                    aria-label="role status"
                    size="small"
                    sx={{
                      // borderRadius: "20px",
                      backgroundColor: "lightgrey",
                    }}
                    // disabled={loggedInUserRoleId === rolesAndAccessData?.role_id}
                  >
                    <ToggleButton
                      value={true}
                      aria-label="active"
                      sx={{
                        width: "90px",
                        // borderRadius: "20px",
                        color: isRoleActive ? "white" : "inherit",
                        backgroundColor: isRoleActive ? "green" : "inherit",
                        transition: "background-color 1.0s, color 1.0s",
                        "&.Mui-selected": {
                          backgroundColor: "green",
                          color: "white",
                        },
                        "&:hover": {
                          backgroundColor: isRoleActive
                            ? "green !important"
                            : "inherit",
                        },
                      }}
                    >
                      {isRoleActive ? "Enabled" : "Enable"}
                    </ToggleButton>
                    <ToggleButton
                      value={false}
                      aria-label="inactive"
                      sx={{
                        width: "90px",
                        // borderRadius: "20px",
                        color: !isRoleActive ? "white" : "inherit",
                        backgroundColor: !isRoleActive ? "red" : "inherit",
                        transition: "background-color 1.0s, color 1.0s",
                        "&.Mui-selected": {
                          backgroundColor: "red",
                          color: "white",
                        },
                        "&:hover": {
                          backgroundColor: !isRoleActive
                            ? "red !important"
                            : "inherit",
                        },
                      }}
                    >
                      {isRoleActive ? "Disable" : "Disabled"}
                    </ToggleButton>
                  </ToggleButtonGroup>
                </Box>
              </Grid>
              <Grid item xs={6}>
                <Button
                  startIcon={<DeleteIcon />}
                  size="medium"
                  color="error"
                  variant="contained"
                  onClick={handleDeleteRoleClick}
                  // disabled={loggedInUserRoleId === rolesAndAccessData?.role_id}
                  sx={{
                    // width: "100%",
                    width: "140px",
                    mt: 2,
                    // borderRadius: "0px",
                    // bgcolor: "#f44336",
                    // "&:hover": {
                    //   bgcolor: "#d32f2f",
                    // },
                  }}
                >
                  Delete
                </Button>
              </Grid>
              <Grid item xs={6} textAlign={"center"}>
                <Button
                  startIcon={<UpdateIcon />}
                  size="medium"
                  color="primary"
                  variant="contained"
                  disabled={!isUpdateRoleValid()}
                  onClick={handleUpdateRoleClick}
                  sx={{
                    // width: "100%",
                    width: "140px",
                    mt: 2,
                    // borderRadius: "0px"
                  }}
                >
                  Update
                </Button>
              </Grid>
            </Grid>
          </Card>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={1}>
          <Box>
            <MenusCard
              menuOptions={menuOptionsList || []}
              selectedMenus={selectedMenus}
              setSelectedMenus={setSelectedMenus}
              // setAddedSelectedMenus={setAddedSelectedMenus}
              // setRemovedSelectedMenus={setRemovedSelectedMenus}
              handleAddMenusAccessClick={handleAddMenusAccessClick}
              handleRemoveMenusAccessClick={handleRemoveMenusAccessClick}
            />
            {/* <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                size="medium"
                variant="contained"
                disabled={!isUpdateAccessMenuValid()}
                onClick={handleAccessMenuClick}
                sx={{ width: "200px", mb: 2 }}
              >
                Update Menu Access
              </Button>
            </Box> */}
          </Box>
        </CustomTabPanel>
        <CustomTabPanel value={tabValue} index={2}>
          <Box>
            {/* <Typography mb={2} textAlign={"center"} variant="h6" gutterBottom>
          Provide Feature Access
              </Typography> */}
            <FeaturesCard
              menuOptions={menu_options || []}
              featuresList={featuresList || []}
              selectedMenuOption={selectedMenuOption}
              setSelectedMenuOption={setSelectedMenuOption}
              selectedMenuOptionFeaturesList={selectedMenuOptionFeaturesList}
              selectedFeatures={selectedFeatures}
              setSelectedFeatures={setSelectedFeatures}
              // setAddedSelectedFeatures={setAddedSelectedFeatures}
              // setRemovedSelectedFeatures={setRemovedSelectedFeatures}
              handleAddFeaturesAccessClick={handleAddFeaturesAccessClick}
              handleRemoveFeaturesAccessClick={handleRemoveFeaturesAccessClick}
            />
            {/* <Box style={{ display: "flex", justifyContent: "flex-end" }}>
              <Button
                size="medium"
                variant="contained"
                disabled={!isUpdateAccessFeaturesValid()}
                onClick={handleAccessFeaturesClick}
                sx={{ width: "220px", mb: 2 }}
              >
                Update Features Access
              </Button>
            </Box> */}
          </Box>
        </CustomTabPanel>
      </Box>

      <CustomConfirmationDialog
        open={openRoleDeleteDialog}
        onClose={handleCancelRoleDelete}
        dialogTitle={"Confirm Delete"}
        dialogContent={
          <DialogContentText>
            Are you sure you want to delete this role?
          </DialogContentText>
        }
        onConfirm={handleConfirmRoleDelete}
      />

      <CustomConfirmationDialog
        open={openRoleStatusDialog}
        onClose={() => handleRoleDialogClose(false)}
        dialogTitle={"Confirm Status Change"}
        dialogContent={
          <DialogContentText>
            Are you sure you want to change the role status to{" "}
            {pendingRoleStatus ? "Enable" : "Disable"}?
          </DialogContentText>
        }
        onConfirm={() => handleRoleDialogClose(true)}
      />
    </Box>
  );
}

export default UpdateRolesAndAccess;

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-06-2024
 * @description
 * @param {  }
 * @return
 */
const MenusCard = ({
  menuOptions,
  selectedMenus,
  setSelectedMenus,
  // setAddedSelectedMenus,
  // setRemovedSelectedMenus,
  handleAddMenusAccessClick,
  handleRemoveMenusAccessClick,
}) => {
  console.log("selectedMenus", selectedMenus);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [currentMenu, setCurrentMenu] = useState(null);
  const [actionMessage, setActionMessage] = useState("");

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (checked) {
      setSelectedMenus((prevItems) => [...prevItems, name]);
      // setAddedSelectedMenus((prevItems) => [...prevItems, name]);
      // setRemovedSelectedMenus((prevItems) =>
      //   prevItems?.filter((item) => item !== name)
      // );
      setActionMessage(`Select: "${name}"`);
    } else {
      setSelectedMenus((prevItems) =>
        prevItems?.filter((item) => item !== name)
      );
      // setRemovedSelectedMenus((prevItems) => [...prevItems, name]);
      // setAddedSelectedMenus((prevItems) =>
      //   prevItems?.filter((item) => item !== name)
      // );
      setActionMessage(`Deselect: "${name}"`);
    }

    setCurrentMenu(name);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = (confirmed) => {
    if (confirmed) {
      // Handle confirmation logic here.
      if (selectedMenus.includes(currentMenu)) {
        if (currentMenu) {
          handleAddMenusAccessClick({ access_to_pages: [currentMenu] });
        }
      } else {
        if (currentMenu) {
          handleRemoveMenusAccessClick({ access_to_pages: [currentMenu] });
        }
      }
    } else {
      // Revert checkbox state if not confirmed
      if (selectedMenus.includes(currentMenu)) {
        setSelectedMenus(selectedMenus?.filter((menu) => menu !== currentMenu));
      } else {
        setSelectedMenus([...selectedMenus, currentMenu]);
      }
    }
    setConfirmDialogOpen(false); // Close the confirmation dialog
  };

  return (
    <Box>
      <Card
        style={{
          // marginBottom: "10px",
          border: "1px solid lightgrey",
          boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
        }}
      >
        <Typography
          mt={1}
          textAlign={"center"}
          variant="h6"
          fontWeight="bold"
          gutterBottom
        >
          Access Menu Options
        </Typography>
        <Divider />
        <CardContent style={{ height: "450px", overflowY: "auto" }}>
          <Grid container spacing={2}>
            {menuOptions?.map((menu, index) => (
              <Grid item xs={12} key={index}>
                <Box display="flex" alignItems="center">
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={selectedMenus.includes(menu)}
                        onChange={handleCheckboxChange}
                        name={menu}
                        disabled={
                          menu === "PROFILE" && selectedMenus.includes(menu)
                        }
                      />
                    }
                    label={menu}
                  />
                </Box>
              </Grid>
            ))}
          </Grid>
        </CardContent>
      </Card>

      <CustomConfirmationDialog
        open={confirmDialogOpen}
        onClose={() => handleConfirmDialogClose(false)}
        dialogTitle={"Confirm Update"}
        dialogContent={
          <DialogContentText>
            Are you sure you want to {actionMessage}?
          </DialogContentText>
        }
        onConfirm={() => handleConfirmDialogClose(true)}
      />
    </Box>
  );
};

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-06-2024
 * @description
 * @param {}
 * @return
 */
const FeaturesCard = ({
  featuresList,
  selectedMenuOption,
  setSelectedMenuOption,
  selectedMenuOptionFeaturesList,
  selectedFeatures,
  setSelectedFeatures,
  // setAddedSelectedFeatures,
  // setRemovedSelectedFeatures,
  handleAddFeaturesAccessClick,
  handleRemoveFeaturesAccessClick,
}) => {
  console.log("selectedFeatures", selectedFeatures);
  const uniqueMenuList = [
    ...new Set(featuresList?.map((menu) => menu.page_name)),
  ];
  const menuOptionsList = uniqueMenuList?.map((pageName) => ({
    value: pageName,
    label: pageName,
  }));

  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [currentFeatureId, setCurrentFeatureId] = useState(null);
  const [actionMessage, setActionMessage] = useState("");

  const handleCheckboxChange = (event, detail) => {
    const { name, checked } = event.target;
    const id = parseInt(name, 10);

    if (checked) {
      setSelectedFeatures((prevItems) => {
        if (!prevItems.includes(id)) {
          return [...prevItems, id];
        }
        return prevItems;
      });
      // setAddedSelectedFeatures((prevItems) => {
      //   if (!prevItems.includes(id)) {
      //     return [...prevItems, id];
      //   }
      //   return prevItems;
      // });
      // setRemovedSelectedFeatures((prevItems) =>
      //   prevItems?.filter((item) => item !== id)
      // );

      if (detail?.description) {
        setActionMessage(`Select: "${detail?.description}"`);
      }
    } else {
      setSelectedFeatures((prevItems) =>
        prevItems?.filter((item) => item !== id)
      );
      // setAddedSelectedFeatures((prevItems) =>
      //   prevItems?.filter((item) => item !== id)
      // );
      // setRemovedSelectedFeatures((prevItems) => {
      //   if (!prevItems.includes(id)) {
      //     return [...prevItems, id];
      //   }
      //   return prevItems;
      // });
      if (detail?.description) {
        setActionMessage(`Deselect: "${detail?.description}"`);
      }
    }

    setCurrentFeatureId(id);
    setConfirmDialogOpen(true);
  };

  const handleConfirmDialogClose = (confirmed) => {
    if (confirmed) {
      // Handle confirmation logic here.
      if (selectedFeatures.includes(currentFeatureId)) {
        if (currentFeatureId) {
          handleAddFeaturesAccessClick({
            access_to_features: [currentFeatureId],
          });
        }
      } else {
        if (currentFeatureId) {
          handleRemoveFeaturesAccessClick({
            access_to_features: [currentFeatureId],
          });
        }
      }
    } else {
      // Revert checkbox state if not confirmed
      if (selectedFeatures.includes(currentFeatureId)) {
        setSelectedFeatures(
          selectedFeatures?.filter((feature) => feature !== currentFeatureId)
        );
      } else {
        setSelectedFeatures([...selectedFeatures, currentFeatureId]);
      }
    }
    setConfirmDialogOpen(false); // Close the confirmation dialog
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <SelectTextField
          size={"small"}
          label={"Select Menu"}
          variant={"standard"}
          optionsList={menuOptionsList}
          selectedValue={selectedMenuOption}
          setSelectedValue={setSelectedMenuOption}
          sltFldStyle={{
            mb: 1,
            width: "220px",
            backgroundColor: "white",
          }}
        />
      </Box>

      {selectedMenuOptionFeaturesList?.map((feature, index) => (
        <Accordion
          key={index}
          style={{
            marginTop: "5px",
            marginBottom: "15px",
            border: "1px solid lightgrey",
            boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
          }}
        >
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography
              mt={1}
              textAlign={"center"}
              variant="body1"
              gutterBottom
            >
              {feature.feature_name}
            </Typography>
          </AccordionSummary>
          <Divider />
          <AccordionDetails>
            <Grid container spacing={2}>
              {feature.details?.map((detail, index1) => (
                <Grid item xs={12} key={index1}>
                  <Box display="flex" alignItems="center">
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedFeatures.includes(detail.id)}
                          onChange={(e) => handleCheckboxChange(e, detail)}
                          name={detail.id.toString()}
                          disabled={
                            feature.feature_name === "Default" &&
                            selectedFeatures.includes(detail.id)
                          }
                        />
                      }
                      label={detail.description}
                    />
                  </Box>
                </Grid>
              ))}
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}

      <CustomConfirmationDialog
        open={confirmDialogOpen}
        onClose={() => handleConfirmDialogClose(false)}
        dialogTitle={"Confirm Update"}
        dialogContent={
          <DialogContentText>
            Are you sure you want to {actionMessage}?
          </DialogContentText>
        }
        onConfirm={() => handleConfirmDialogClose(true)}
      />
    </Box>
  );
};
