import { Reducer } from "redux";
import actionType from "../actionTypes";

const initialState = {
  error: false,
  msg: "",
  architectureTypesData: [],
};

const architectureTypesReducer = (state = initialState, action) => {
  console.log(action);
  switch (action.type) {
    case actionType.ADD_ARCHITECTURE_TYPES:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    // case actionType.GET_ZONE:
    //   return {
    //     ...state,
    //     error: action.payload.error,
    //     msg: action.payload.msg,
    //     architectureTypesData: action.payload,
    //   };

    case actionType.DELETE_ARCHITECTURE_TYPES:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    // case actionType.UPDATE_ZONE:
    //   return {
    //     ...state,
    //     error: action.payload.error,
    //     msg: action.payload.msg,
    //   };
    default:
      return state;
  }
};

export default architectureTypesReducer;
