import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import Snackbar from "@mui/material/Snackbar";
import Alert from "@mui/material/Alert";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";

import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import React, { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

import CustomTextField1 from "../../components/text-fields/CustomTextField1";
import DeleteIcon from "@mui/icons-material/Delete";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import AddIcon from "@mui/icons-material/Add";
import SelectTextField from "../../components/text-fields/SelectTextField";
import moment from "moment";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import settingsApis from "../../redux/actions/settings";
import adminSettingsAPI from "../../redux/actions/adminSetttingAPI";
import CustomTextField2 from "../../components/text-fields/CustomTextField2";
import CustomAddDialog from "../../components/dialog-boxes/CustomAddDialog";

function ZoneDetails({ open, handleClick, zoneDetails }) {
  const dispatch = useDispatch();
  const appMetaZoneDetails = useSelector(
    (state) => state?.settingsReducer,
    shallowEqual
  );

  const [openDialog, setOpenDialog] = useState(false);
  const [selectedZone, setSelectedZone] = useState(
    localStorage.getItem("selectedZone") || ""
  );
  const [zoneName, setZoneName] = useState("");
  const [zoneNameError, setZoneNameError] = useState(false);

  const [zonePositions, setZonePositions] = useState([]); // State to manage add zone error message

  React.useEffect(() => {
    dispatch(
      HandleApiActions({
        ...settingsApis.get_app_meta_data,
        show_toast: false,
      })
    );
  }, []);

  React.useEffect(() => {
    console.log("zoneDetails: ", zoneDetails);
    console.log("appMetaZoneDetails");
    console.log(appMetaZoneDetails?.metaData?.zone_positions);
    if (
      appMetaZoneDetails &&
      appMetaZoneDetails.metaData &&
      appMetaZoneDetails.metaData &&
      appMetaZoneDetails.metaData.zone_positions
    ) {
      let availableZones = appMetaZoneDetails?.metaData?.zone_positions;
      if (zoneDetails && zoneDetails.length) {
        const existingZones = zoneDetails?.map((data) => data.zone_position);

        availableZones = appMetaZoneDetails?.metaData?.zone_positions.filter(
          (data) => !existingZones.includes(data)
        );
      }
      console.log("availableZones: ", availableZones);
      setZonePositions(availableZones);
    }
  }, [appMetaZoneDetails]);

  const handleAddClick = () => {
    const reqObj = {
      current_time: moment().format(),
      zone_position: selectedZone,
      position_name: zoneName,
    };
    console.log("Zone Detailssssssss", zoneDetails);
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.add_zone_details,
        params: reqObj,
        show_toast: false,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...adminSettingsAPI.get_admin_settings,
          show_toast: false,
        })
      );
    });
    // dispatch(AddZoneDetailsAction(reqObj)).then(() => {
    //   dispatch(GetAdminSettingsAction());
    // });
    setZoneName("");
    setSelectedZone("");
    setOpenDialog(false);
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };
  const handleDeleteZone = (params) => {
    console.log("-- handleDeleteZone --");
    console.log(params);
    const reqObj = {
      current_time: moment().format(),
      zone_position: params,
    };
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.delete_zone_details,
        params: reqObj,
        show_toast: false,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...adminSettingsAPI.get_admin_settings,
          show_toast: false,
        })
      );
    });
    // dispatch(DeleteZonesDetailsAction(reqObj)).then(() => {
    //   dispatch(GetAdminSettingsAction());
    // });
  };
  return (
    <div>
      <List
        sx={{
          width: "100%",
          marginTop: "15px",
          backgroundColor: "whitesmoke",
          boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.2)",
          borderRadius: "0px",
          maxWidth: "100%",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={() => handleClick("2")}>
          <ListItemText
            sx={{
              textAlign: "start",

              color: "black",
            }}
            primary="Zone Details"
            primaryTypographyProps={{
              fontWeight: 600,
              fontSize: "18px",
            }}
          />
          {open === "2" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open === "2"} timeout="auto" unmountOnExit>
          <Divider sx={{ margin: "10px" }} />
          <List
            component="div"
            disablePadding
            sx={{
              background: "whitesmoke",
              margin: "10px",
              padding: "10px",
              borderRadius: "10px",
              overflowY: "auto",
              maxHeight: "500px",
            }}
          >
            <Box display="flex" justifyContent="flex-end" padding="10px">
              <Button
                variant="contained"
                startIcon={<AddIcon />}
                sx={
                  {
                    // borderRadius: "0px"
                  }
                }
                onClick={handleOpenDialog}
              >
                Add
              </Button>
            </Box>
            {openDialog && (
              <CustomAddDialog
                open={openDialog}
                onClose={() => setOpenDialog(false)}
                dialogTitle={"Add Zone Name"}
                dialogContent={
                  <Stack spacing={2}>
                    <FormControl fullWidth>
                      <InputLabel>Select Zone Position</InputLabel>
                      <Select
                        value={selectedZone}
                        onChange={(event) =>
                          setSelectedZone(event.target.value)
                        }
                        label="Select Zone Position"
                      >
                        {zonePositions?.map((zone, idx) => (
                          <MenuItem key={zone} value={zone}>
                            {zone}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                    <CustomTextField2
                      size={"small"}
                      label={"Zone Name"}
                      placeholder={"Zone Name"}
                      value={zoneName}
                      setValue={setZoneName}
                      txtFldStyle={{
                        width: "270px",
                      }}
                      validationRules={{ minLength: 3 }}
                      setError={setZoneNameError}
                      error={zoneNameError !== ""}
                    />
                  </Stack>
                }
                onConfirm={handleAddClick}
                onDisabled={
                  zoneName?.trim() !== "" && zoneName?.length > 2 ? false : true
                }
              />
            )}
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {zoneDetails && zoneDetails.length ? (
                zoneDetails?.map((data, index) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        borderRadius: "0px",
                        backgroundColor: "green",
                        alignItems: "center",
                        justifyContent: "center",

                        color: "white",
                        fontWeight: "bold",
                      }}
                    >
                      <Typography sx={{ marginLeft: "10px" }}>
                        {data.zone_position}
                      </Typography>
                      : &nbsp;&nbsp;
                      <Typography>{data.position_name}</Typography>
                      <Button
                        onClick={() => handleDeleteZone(data?.zone_position)}
                      >
                        <CloseIcon sx={{ color: "white" }} />
                      </Button>
                    </Box>
                  );
                })
              ) : (
                <Typography
                  sx={{ color: "red", fontSize: "12px", fontWeight: "bold" }}
                >
                  Zone details not found
                </Typography>
              )}
            </Box>
          </List>
        </Collapse>
      </List>
    </div>
  );
}

export default ZoneDetails;
