import { Reducer } from "redux";
import actionType from "../actionTypes";

const initialState = {
  error: false,
  msg: "",
  powerTrainClassDetails: [],
};

const powerTrainClassReducer = (state = initialState, action) => {
  console.log(action);
  switch (action.type) {
    case actionType.ADD_POWETRAIN_CLASS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_POWETRAIN_CLASS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        powerTrainClassDetails: action.payload,
      };

    case actionType.DELETE_POWETRAIN_CLASS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.UPDATE_POWETRAIN_CLASS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    default:
      return state;
  }
};

export default powerTrainClassReducer;
