/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 29-06-2024
 * @description Reusable select dropdown component for choosing from a list of options.
 */
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React from "react";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 29-06-2024
 * @description Reusable select dropdown component for choosing from a list of options.
 * @param { size, label, optionsList, selectedValue, setSelectedValue, sltFldStyle }
 * @return The rendered select dropdown component.
 */
function SelectTextField({
  size,
  defaultvalue,
  label,
  variant,
  required,
  disabled,
  optionsList,
  selectedValue,
  setSelectedValue,
  sltFldStyle,
}) {
  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 220,
      },
    },
  };

  // Handles change events when a new value is selected from the dropdown.
  const handleChange = (e) => {
    setSelectedValue(e.target.value);
  };

  return (
    <FormControl
      variant={variant}
      fullWidth
      size={size}
      required={required}
      sx={sltFldStyle}
    >
      <InputLabel>{label}</InputLabel>
      <Select
        disabled={disabled}
        value={selectedValue}
        onChange={handleChange}
        MenuProps={menuProps}
      >
        {optionsList?.map((option) => (
          <MenuItem key={option?.value} value={option?.value}>
            {option?.label}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default SelectTextField;
