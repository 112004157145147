import actionType from "../actionTypes";
/**
 * @author : Abdul Rahim M - abdul@au79consulting.com
 * @Date : 04-07-2024
 * @description Reducer function for handling Country,Phone,Timezone related actions and state changes.
 */
const initialState = {
  error: false,
  msg: "",
  countryAndPhoneCode: [],
};
/**
 * @author : Abdul Rahim M - abdul@au79consulting.com
 * @Date : 04-07-2024
 * @description
 * @param {}
 * @return New state after processing the action
 */
const countryAndPhoneCodeReducer = (state = initialState, action) => {
  console.log(action);
  switch (action.type) {
    case actionType.GET_COUNTRIES_PHONECODE:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        countryAndPhoneCode: action.payload.data,
      };

    default:
      return state;
  }
};

export default countryAndPhoneCodeReducer;
