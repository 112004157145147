import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Checkbox from "@mui/material/Checkbox";
import Collapse from "@mui/material/Collapse";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import DeleteIcon from "@mui/icons-material/Delete";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";

import moment from "moment";
import adminSettingsAPI from "../../redux/actions/adminSetttingAPI";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CustomTextField2 from "../../components/text-fields/CustomTextField2";
import CustomAddDialog from "../../components/dialog-boxes/CustomAddDialog";

function ArchitectureTypes1({ open, handleClick, architectureDetails }) {
  const dispatch = useDispatch();

  const [selectedChip, setSelectedChip] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [architectureNameError, setArchitectureNameError] = useState(false);
  const [isZoneChecked, setIsZoneChecked] = useState(false);
  const [newArchitectureName, setNewArchitectureName] = useState("");

  const handleCheckboxChange = (event) => {
    setIsZoneChecked(event.target.checked);
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setNewArchitectureName(null);
  };

  const handleClickChip = (index) => {
    setSelectedChip(index === selectedChip ? null : index);
  };

  const handleDeleteChip = (idx, chipName) => {
    console.log("Delete chip:", idx);
    const reqObj = {
      name: chipName,
      current_time: new Date(),
    };
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.delete_architecture_types,
        show_toast: true,
        params: reqObj,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...adminSettingsAPI.get_admin_settings,
          show_toast: false,
        })
      );
    });

    setSelectedChip(null);
  };
  const regex = /^(?!.*  )(?!.* $).{5,}$/;
  const handleClickAddArchitecture = () => {
    const reqObj = {
      current_time: moment().format(),
      name: regex.test(newArchitectureName) ? newArchitectureName : null,
      is_zones_available: isZoneChecked,
    };
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.add_architecture_types,
        show_toast: "false",
        params: reqObj,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...adminSettingsAPI.get_admin_settings,
          show_toast: false,
        })
      );
    });
    // dispatch(AddArchitectureTypesAction(reqObj)).then(() => {
    //   dispatch(GetAdminSettingsAction());
    // });
    setDialogOpen(false);
    setNewArchitectureName(" ");
  };
  return (
    <div>
      <List
        sx={{
          width: "100%",
          maxWidth: "100%",
          marginTop: "15px",
          backgroundColor: "whitesmoke",
          boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.2)",
          borderRadius: "0px",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={() => handleClick("1")}>
          <ListItemText
            sx={{
              textAlign: "start",

              color: "black",
            }}
            primary="Architecture Types"
            primaryTypographyProps={{
              fontWeight: 600,
              fontSize: "18px",
            }}
          />
          {open === "1" ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItemButton>

        <Collapse in={open === "1"} timeout="auto" unmountOnExit appear>
          <Divider sx={{ margin: "10px", height: "2px" }} />
          <List
            component="div"
            disablePadding
            sx={{
              backgroundColor: "whitesmoke",
              margin: "10px",
              padding: "10px",
              borderRadius: "10px",
              overflowY: "auto",
              maxHeight: "500px",
            }}
          >
            <Box display="flex" justifyContent="flex-end" padding="5px">
              <Button
                variant="contained"
                sx={
                  {
                    // borderRadius: "0px"
                  }
                }
                onClick={handleDialogOpen}
                startIcon={<AddIcon />}
              >
                Add
              </Button>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: 2,
                marginTop: "10px",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {architectureDetails && architectureDetails?.length ? (
                architectureDetails.map((data, index) => {
                  return (
                    <>
                      <Box display="flex">
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            borderRadius: "0px",

                            alignItems: "center",
                            justifyContent: "center",

                            color: "white",
                            fontWeight: "bold",
                          }}
                          backgroundColor={
                            selectedChip === index ? "red" : "green"
                          }
                          onClick={() => handleClickChip(index)}
                        >
                          <Typography sx={{ marginLeft: "10px" }}>
                            {data?.name}
                          </Typography>
                          <Button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleDeleteChip(index, data?.name);
                            }}
                          >
                            <CloseIcon
                              sx={{
                                color: "white",
                                marginLeft: "10px",
                                "&:hover": { color: "white" },
                              }}
                            />
                          </Button>
                        </Box>

                        <Divider orientation="vertical" />
                      </Box>
                    </>
                  );
                })
              ) : (
                <Typography
                  sx={{ color: "red", fontSize: "12px", fontWeight: "bold" }}
                >
                  Architecture Types not found
                </Typography>
              )}
            </Box>
          </List>
        </Collapse>
      </List>

      <CustomAddDialog
        open={dialogOpen}
        onClose={handleDialogClose}
        dialogTitle={"Add Architecture Type"}
        dialogContent={
          <Box>
            <CustomTextField2
              size="small"
              margin="dense"
              label="Architecture Name"
              placeholder="Architecture Name"
              type="text"
              fullWidth
              value={newArchitectureName}
              txtFldStyle={{ width: "270px" }}
              validationRules={{ minLength: 5 }}
              setValue={setNewArchitectureName}
              setError={setArchitectureNameError}
              error={architectureNameError !== ""}
            />
            <Box sx={{ textAlign: "center" }}>
              <Typography mt="10px" textAlign="center">
                Is Zonal Available?
              </Typography>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isZoneChecked}
                    onChange={handleCheckboxChange}
                  />
                }
                label="Yes"
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!isZoneChecked}
                    onChange={() => setIsZoneChecked(false)}
                  />
                }
                label="No"
              />
            </Box>
          </Box>
        }
        onConfirm={handleClickAddArchitecture}
        onDisabled={
          newArchitectureName?.trim() !== "" &&
          newArchitectureName?.trim()?.length > 4
            ? false
            : true
        }
      />
    </div>
  );
}

export default ArchitectureTypes1;
