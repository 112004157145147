/**
 * @author Narender - narender@au79consulting.com
 * @date 12-06-2024
 * @description Component for handling the forgot password functionality.
 */

import { Avatar, Box, IconButton, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import { AuthTextField, AuthButton } from "..";
import appMeta from "../../assets/app-meta/appMeta";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 12-06-2024
 * @description Component for handling the forgot password functionality.
 * @param : handleForgotPassword, handleAuthViewChange
 * @return The rendered forgot password component.
 */
function ForgotPassword({
  handleForgotPassword,
  throttledHandleForgotPassword,
  handleAuthViewChange,
}) {
  const [email, setEmail] = useState("");
  const [emailError, setEmailError] = useState("");

  // Handles click event for "Back to Login" link.
  const handleLoginOnClick = () => {
    handleAuthViewChange(appMeta.authView.LOGIN);
  };

  // Handles click event for "Send OTP" button.
  // Validates email format and triggers forgot password function.
  const handleSendOtpOnClick = () => {
    // Regular expression for a valid email address
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validate email in real-time
    if (!emailRegex.test(email)) {
      setEmailError("Invalid email address");
      return;
    } else {
      setEmailError("");
    }

    // Trigger forgot password function with email
    // handleForgotPassword({ email });
    throttledHandleForgotPassword({ email });
  };

  return (
    <Box sx={styles.mainContainer}>
      <Avatar variant="rounded" sx={styles.avatar}>
        <img height={"100%"} src="deloitteIconBlackLarge.png" alt="Deloitte" />
      </Avatar>

      <Typography style={{ fontWeight: 600 }} component="h1" variant="body1">
        Forgot password
      </Typography>

      <Box sx={styles.fldsContainer}>
        <AuthTextField
          label={"Email"}
          placeHolder={"Email"}
          type={"email"}
          fldType={appMeta.textFieldTypes.EMAIL}
          variant={"standard"}
          value={email}
          setValue={setEmail}
          valueError={emailError}
          setValueError={setEmailError}
          isAutoFocused={true}
          isRequired={true}
          onKeyDown={(event) => { if (event.key === 'Enter') handleSendOtpOnClick(); }}
          txtFldStyle={{
            mt: 3,
          }}
        />
        <AuthButton btnName="Send Code" handleOnClick={handleSendOtpOnClick} />
        {/* <AuthButton
          btnName="Back to login"
          handleOnClick={handleLoginOnClick}
        /> */}
        <Box
          onClick={(e) => {
            e.preventDefault();
            handleLoginOnClick();
          }}
          sx={{ mt: 2 }}
        >
          <IconButton
            sx={{
              color: "#1976d2",
              // padding: '8px',
              "& svg": {
                fontSize: "20px",
              },
            }}
            aria-label="back to login"
          >
            <ArrowBackIcon />
          </IconButton>
          <Link style={styles.linkStyle} href="#" variant="body2">
            Back to Login
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default ForgotPassword;

// Styling for the ForgotPassword component
const styles = {
  mainContainer: {
    width: "100%",
    paddingX: 5,
    paddingY: 4,
    borderRadius: 1,
    backgroundColor: "white",
    border: { sm: "1px solid lightgrey" },
    boxShadow: { sm: "0px 2px 4px rgba(0, 0, 0, 0.1)" },
    boxSizing: "border-box",

    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'center',
    // height: '85vh',
    // textAlign: 'center',
    // maxWidth: '400px',
    // padding: 2,
    // margin: '0 auto',
    // boxSizing: 'border-box',
  },
  fldsContainer: {
    width: "100%",
  },
  avatar: {
    mb: 3,
    bgcolor: "white",
    width: "100%",
    height: 24,
    // border: "1px solid red",
  },
  linkStyle: {
    color: "#1976d2",
  },
};
