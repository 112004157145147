/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 08-07-2024
 * @description Reusable table withe multiple filter and sorting options
 */
import { styled } from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import React from "react";

const StyledDataGrid = styled(DataGrid)(({ theme }) => ({
  "& .MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal.css-1rtad1": {
    display: "block",
  },
  "& .MuiDataGrid-columnHeaders": {
    "& .MuiDataGrid-columnHeaderTitle": {
      fontWeight: "bold",
    },
  },
}));

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 08-07-2024
 * @description Reusable table withe multiple filter and sorting options
 * @param {}
 * @return {}
 */
function CustomDataGridTable({
  tableRowsData,
  columns,
  handleTableRowClick,
  // Pagination //
  rowCount,
  pageSizeOptions,
  paginationModel,
  onPaginationModelChange,
  isLoading
}) {
  return (
    <StyledDataGrid
      localeText={{ toolbarDensity: "View" }}
      // autoHeight={true}
      rows={tableRowsData}
      columns={columns}
      // autoPageSize
      disableColumnMenu
      // disableColumnSorting
      disableColumnSelector
      disableRowSelectionOnClick
      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: { printOptions: { disableToolbarButton: true } },
      }}
      onRowClick={(params) => handleTableRowClick({ params })}
      // Pagination //
      paginationMode="server"
      rowCount={rowCount}
      pageSizeOptions={pageSizeOptions}
      paginationModel={paginationModel}
      onPaginationModelChange={onPaginationModelChange}
      // loading={isLoading}
    />
  );
}

export default CustomDataGridTable;
