/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 27-06-2024
 * @description Component for managing roles and access permissions.
 */
import {
  Box,
  Drawer,
  Paper,
  TableCell,
  TableRow,
  styled,
  tableCellClasses,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import userManagementApis from "../../redux/actions/userManagent";
import UpdateRolesAndAccess from "./UpdateRolesAndAccess";
import AddIcon from "@mui/icons-material/Add";
import { convertUtcToTimeZone } from "../../utils/convertUtcToTimeZone";
import { CustomButton, CustomDataGridTable } from "../../components";

/**
 * Styled component for customizing TableCell in the table.
 */
const CustomTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    // backgroundColor: theme.palette.common.black,
    // color: theme.palette.common.white,
    // fontSize: 14,
    // fontWeight: 600,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

/**
 * Styled component for customizing TableRow in the table.
 */
const CustomTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    // backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    // border: 0,
  },
  "&:hover": {
    cursor: "pointer",
    backgroundColor: theme.palette.action.hover,
  },
}));

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 27-06-2024
 * @description Component for displaying roles and managing access permissions.
 * @param { setIsAddRole }
 * @return The rendered roles and access component.
 */
function RolesAndAccess({ setIsAddRole }) {
  const [searchRoleValue, setSearchRoleValue] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [open, setOpen] = React.useState(false);
  const [openDrawer, setOpenDrawer] = React.useState(false);
  const [drawerData, setDrawerData] = useState(null);
  const { rolesList } = useSelector(
    (state) => state.userManagementReducer,
    shallowEqual
  );
  const { featuresList } = useSelector(
    (state) => state.userManagementReducer,
    shallowEqual
  );
  const dispatch = useDispatch();
  const [tableRowsData, setTableRowsData] = useState([]);
  const [rolesAndAccessData, setRolesAndAccessData] = useState({});
  const profileDetails = useSelector((store) => store.profileReducer);

  useEffect(() => {
    let filteredRolesList = rolesList?.map((role, index) => {
      return {
        ...role,
        // id: index + 1,
        id: page * rowsPerPage + index + 1,
        access_to_pages: role?.access_to_pages,
        access_to_features: role?.access_to_features,
        created_at: convertUtcToTimeZone(
          role?.created_at,
          profileDetails?.profile?.profile?.region
        ),
        // created_by: role.created_by.fullname,
        // updated_by: role.updated_by.fullname,
      };
    });

    setTableRowsData(filteredRolesList);
  }, [rolesList]);

  useEffect(() => {
    handleGetRolesAndAccessList();
  }, [page, rowsPerPage]);

  const handleGetRolesAndAccessList = () => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_roles_list,
        params: {
          page_number: page + 1,
          page_size: rowsPerPage,
          sort_column: "created_at",
          sort_order: "ASC",
        },
        show_toast: false,
      })
    );
  };

  // Toggles the state of the drawer.
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };

  // Toggles the state of the drawer.
  const toggleUpdateDeleteDrawer = (newOpen) => () => {
    setOpenDrawer(newOpen);
  };

  // Handles the change of page in pagination.
  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  // Handles the change of rows per page in pagination.
  const handleRowsPerPageChange = (e) => {
    console.log(e.target.value);
    let newValue = +e.target.value;
    setRowsPerPage(newValue);
    setPage(0);
  };

  // Handles the click event for searching roles.
  const handleRoleSearchClick = () => {
    console.log("searchRoleValue: ", searchRoleValue);
  };

  // Handles the click event for adding a new role.
  const handleAddRoleClick = () => {
    setIsAddRole(true);
  };

  // Handles the click event for viewing list of pages associated with a role.
  const handleListOfPagesAccessClick = ({ params }) => {
    // alert("handleNoOfUsersClick");

    setDrawerData({
      heading: "Access To Menus",
      role: params.row.role_name,
      department: params.row.department,
      items: params.row.access_to_pages,
    });

    toggleDrawer(true)();
  };

  // Handles the click event for viewing list of features associated with a role.
  const handleListOfFeaturesAccessClick = ({ params }) => {
    // alert("handleListOfFeaturesClick");

    const idsAsNumbers = params.rows.access_to_features?.map((id) =>
      parseInt(id, 10)
    );

    let featuresNamesList = featuresList
      ?.flatMap((page) => page.details)
      ?.filter((detail) => idsAsNumbers.includes(detail.id))
      ?.map((detail) => detail.description);

    setDrawerData({
      heading: "Access To Features",
      role: params.row.role_name,
      department: params.row.department,
      items: featuresNamesList,
    });

    toggleDrawer(true)();
  };

  // Handles the click event for table rows for editing
  const handleTableRowClick = ({ params }) => {
    toggleUpdateDeleteDrawer(true)();

    setRolesAndAccessData({ ...params.row });
  };

  const columns = [
    {
      field: "id",
      headerName: "S.No",
      width: 90,
      filterable: false,
      sortable: false,
    },
    {
      field: "role_name",
      headerName: "Role",
      width: 220,
    },
    {
      field: "department",
      headerName: "Department",
      width: 220,
    },
    {
      field: "sub_department",
      headerName: "Sub Department",
      width: 220,
      renderCell: (params) => (params.value ? params.value : "-"),
    },
    {
      field: "description",
      headerName: "Description",
      width: 220,
    },
    { field: "created_at", headerName: "Created At", width: 220 },
    // { field: 'created_by', headerName: 'Created By', width: 220 },
    // { field: 'updated_at', headerName: 'Updated At', width: 220 },
    // { field: 'updated_by', headerName: 'Updated By', width: 220 },
  ];

  return (
    <Box style={{ marginBottom: "60px" }}>
      <Box sx={styles.tblFun}>
        <CustomButton
          startIcon={<AddIcon />}
          size={"medium"}
          variant={"contained"}
          btnName={"Add"}
          handleOnClick={handleAddRoleClick}
          btnStyle={{
            ml: 2,
            // borderRadius: "0px",
          }}
        />
      </Box>
      <Paper
        sx={{
          height: 470,
          width: "100%",
          // maxWidth: { xs: "350px", sm: "400px", md: "100%" },
          // overflow: "auto",
        }}
      >
        <CustomDataGridTable
          tableRowsData={tableRowsData}
          columns={columns}
          handleTableRowClick={handleTableRowClick}
          // Pagination //
          rowCount={
            tableRowsData.length >= rowsPerPage
              ? -1
              : page * rowsPerPage + tableRowsData.length
          }
          pageSizeOptions={[25, 50, 100]}
          paginationModel={{ page: page, pageSize: rowsPerPage }}
          onPaginationModelChange={(model) => {
            setPage(model.page);
            setRowsPerPage(model.pageSize);
          }}
        // isLoading={isLoading}
        />
      </Paper>
      {/* <RightSideDrawer open={open} drawerData={drawerData} setDrawerData={setDrawerData} toggleDrawer={toggleDrawer} /> */}
      <UpdateDeleteRightSideDrawer
        open={openDrawer}
        toggleDrawer={toggleUpdateDeleteDrawer}
        rolesAndAccessData={rolesAndAccessData}
      />
    </Box>
  );
}

export default RolesAndAccess;

// Styling for the RolesAndAccess component
const styles = {
  container: {
    width: "100%",
    overflow: "hidden",
  },
  tblFun: {
    mx: 2,
    mb: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 06-07-2024
 * @description
 * @param {}
 * @return {}
 */
function UpdateDeleteRightSideDrawer({
  open,
  rolesAndAccessData,
  toggleDrawer,
}) {
  const handleOnClose = () => {
    toggleDrawer(false)();
  };

  return (
    <div>
      <Drawer anchor="right" open={open} onClose={handleOnClose}>
        <Box sx={{ width: "425px" }}>
          <UpdateRolesAndAccess
            rolesAndAccessData={rolesAndAccessData}
            toggleDrawer={toggleDrawer}
          />
        </Box>
      </Drawer>
    </div>
  );
}
