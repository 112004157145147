/**
 * @author Tahir Shaik - tahir@bookwater.com - +91-9177327808
 * @date 11-06-2024
 * @description This is the Home Page for the Application
 */
//Importing the required components/functions from the libraries
import { Box, Typography } from "@mui/material";
import React from "react";
import { toast } from "react-toastify";

//Main Home Function component
function Home() {
  const handleToast = () => {
    console.log("-- handleToast --");
    toast.error("🧑🏻‍🚒 Error toast message!");
    toast.info("💁🏻‍♂️ Info toast message!");
    toast.success("👩🏻‍🏫 Success toast message!");
    toast.warn("🙅🏻 Warning toast message!");
  };
  return (
    <div>
      <Typography sx={{ marginTop: "20px" }}>
        Dashboard related content here
      </Typography>
      {/* <button onClick={handleToast}>Toast</button> */}
    </div>
  );
}

export default Home;
