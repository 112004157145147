/**
 * @author Tahir Shaik - tahir@bookwater.com - +91-9177327808
 * @date 11-06-2024
 * @description Root reducer-> Combines all the individual reducer together for better readability and understanding.
 */
import { combineReducers } from "redux";
import spinnerReducer from "./spinner";
import authReducer from "./authReducer";
import profileReducer from "./profileReducer";
import countryAndPhoneCodeReducer from "./countryAndPhoneCodeReducer";
import userManagementReducer from "./userManagementReducer";
import fileHandlingReducers from "./fileHandlingReducers";
import settingsReducer from "./settingsReducer";
import adminSettingsReducer from "./adminSettingsReducer";
import vehicleFunctionReducer from "./vehicleFunctionReducer";
import powerTrainClassReducer from "./powerTrainClassReducer";
import vehicleClassReducer from "./vehicleClassReducer";
import architectureTypesReducer from "./architectureTypesReducer";
import swaggerReducer from "./swaggerDocs";
import projectConfigReducer from "./projectConfigReducer";
import labCarReducer from "./labCarReducer";
const RootReducer = combineReducers({
  spinnerReducer: spinnerReducer,
  authReducer: authReducer,
  userManagementReducer: userManagementReducer,
  settingsReducer: settingsReducer,
  profileReducer: profileReducer,
  fileHandlingReducers: fileHandlingReducers,
  countryAndPhoneCodeReducer: countryAndPhoneCodeReducer,
  adminSettingsReducer: adminSettingsReducer,
  powerTrainClassReducer: powerTrainClassReducer,
  vehicleFunctionReducer: vehicleFunctionReducer,
  vehicleClassReducer: vehicleClassReducer,
  architectureTypesReducer: architectureTypesReducer,
  swagger: swaggerReducer,
  projectConfigReducer: projectConfigReducer,
  labCarReducer: labCarReducer,
});
export default RootReducer;
