import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useDispatch } from "react-redux";
import profileActions from "../../redux/actions/profile";
import {
  GetSingleImages,
  HandleApiActions,
} from "../../redux/actions/actionHandler";
function FrontBox1({ index, handleClickBox, boxes, profileDetails }) {
  const [userDetails, setUserDetails] = useState([]);

  const [image, setImage] = useState("");

  console.log("profileDetailswertyui", profileDetails);
  const imageURL = async (imageDetails) => {
    const resp = await GetSingleImages(imageDetails);
    console.log("imagesURL RESP: ");
    console.log(resp);
    setImage(resp);
    return resp;
  };
  useEffect(() => {
    setUserDetails(profileDetails?.profile);
  }, [userDetails]);

  useEffect(() => {
    console.log("boxes.vehicle_class_details", boxes.vehicle_class_details);
    if (boxes?.vehicle_class_details && boxes?.vehicle_class_details.length) {
      imageURL({ image: boxes.vehicle_class_details[0].image });
      // setImage(detail.image);
    }
  }, [boxes]);
  // useEffect(() => {
  //   if (image) {
  //     // Dispatch action only when image is available
  //     dispatch(
  //       HandleApiActions({
  //         ...profileActions.get_profile_pic,
  //         show_toast: true,
  //         file_name: image,
  //         is_file: true,
  //       })
  //     );
  //   }
  // }, [image]);

  // useEffect(() => {
  //   if (profileDetails?.profile_pic_path) {
  //     setVcImage(profileDetails?.profile_pic_path);
  //   }
  // }, [profileDetails]);

  // console.log("vcImage", vcImage);

  const projectStatus = {
    100: (
      <Chip
        sx={{
          boxShadow: "3",
          fontWeight: "bold",
          borderRadius: "0px 15px 0px 15px",
          backgroundColor: "#c8e8fa",
          color: "black",
        }}
        label={"Active Pre SOP"}
        size="medium"
      />
    ),
    200: (
      <Chip
        sx={{
          boxShadow: "3",
          fontWeight: "bold",
          borderRadius: "0px 15px 0px 15px",
          backgroundColor: "#f7f6cd",
          color: "black",
        }}
        label={"Active Post SOP"}
        size="medium"
      />
    ),
    300: (
      <Chip
        sx={{
          boxShadow: "3",
          fontWeight: "bold",
          borderRadius: "0px 15px 0px 15px",
          backgroundColor: "red",
          color: "white",
        }}
        label={"Deactivated"}
        size="medium"
      />
    ),
    500: (
      <Chip
        sx={{
          boxShadow: "3",
          fontWeight: "bold",
          borderRadius: "0px 15px 0px 15px",
          backgroundColor: "#9af5a2",
          color: "green",
        }}
        label={"Completed"}
        size="medium"
      />
    ),
  };
  //{image:""}

  const getStatusName = (statusCode) => {
    return projectStatus[statusCode] || "Unknown Status";
  };
  function capitalizeFirstLetter(str) {
    if (!str) return ""; // Handle empty string or null input
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  function capitalizeFirstLetterOnly(str) {
    if (!str) return ""; // Handle empty string or null input
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  const filterUserDetails = (user_id, id) => {
    return userDetails?.user_id === id ? userDetails?.fullname : null;
  };

  const truncateAddress = (address, maxLength = 10) => {
    if (!address) return ""; // Handle case where address might be undefined or null
    return address.length > maxLength
      ? address.slice(0, maxLength) + "..."
      : address;
  };
  return (
    <div key={index}>
      <Box display="flex" justifyContent="flex-end" mb="2px"></Box>
      <Paper
        elevation={24}
        //height="auto"
        mb="10px"
        //boxShadow="7px 10px  rgba(177, 255, 150)"
        //sx={{ backgroundColor: "#e6e6e6" }}
      >
        <Box
          display="flex"
          justifyContent="space-between"
          padding="10px"
          //boxShadow="2px 10px 40px rgba(177, 255, 150)"
          sx={{ backgroundColor: "#302e33", borderRadius: "5px 5px 0px 0px " }}
        >
          <Box sx={{ padding: "5px", background: "wheatsmoke" }}>
            <Typography
              whiteSpace="normal"
              overflow="hidden"
              textOverflow="ellipsis"
              width="200px"
              display="block"
              textAlign="start"
              height="20px"
              fontWeight="bold"
              color="white"
            >
              {boxes?.name}
            </Typography>
            <Typography
              textAlign="start"
              fontSize="12px"
              fontWeight="bold"
              fontStyle="normal"
              color="#bcbdb7"
            >
              {capitalizeFirstLetter(boxes?.architecture_name)}
            </Typography>
          </Box>

          <Box>
            <Typography color="white">
              {getStatusName(boxes?.status)}
            </Typography>
          </Box>
        </Box>
        <Box
          paddingRight="5px"
          paddingLeft="5px"
          width="95%"
          height="40px"
          ml="10px"
          mt="10px"
          mb="10px"
          mr="10px"
          boxShadow="5px 5px 15px rgba(0, 0, 0, 0.2)"
          sx={{ backgroundColor: "white", borderRadius: "5px" }}
        >
          <Typography textAlign="start" fontWeight="bold" fontSize="9px">
            Trademark
          </Typography>
          <Typography textAlign="start" fontWeight="bold">
            {boxes?.trade_mark}
          </Typography>
        </Box>
        <Box display="flex" justifyContent="space-between" mt="10px">
          <Box>
            <Box
              paddingRight="5px"
              paddingLeft="5px"
              width="100px"
              height="40px"
              ml="10px"
              mt="10px"
              mb="10px"
              boxShadow="5px 5px 15px rgba(0, 0, 0, 0.2)"
              sx={{ backgroundColor: "white", borderRadius: "5px" }}
            >
              <Typography textAlign="start" fontWeight="bold" fontSize="9px">
                Vehicle Platform
              </Typography>
              <Typography textAlign="start" fontWeight="bold">
                {boxes?.vehicle_platform}
              </Typography>
            </Box>
          </Box>

          <Box
            paddingRight="5px"
            paddingLeft="5px"
            height="auto"
            width="250px"
            ml="10px"
            mt="10px"
            mb="10px"
            sx={{ backgroundColor: "white", borderRadius: "5px" }}
            mr="10px"
            boxShadow="5px 5px 15px rgba(0, 0, 0, 0.2)"
          >
            <Typography textAlign="start" fontWeight="bold" fontSize="9px">
              Vehicle Class Details
            </Typography>
            <Box display="flex" justifyContent="space-between" mt="3px">
              <Box borderRadius="5px" fontSize="20px">
                <Typography fontSize="13px" textAlign="start" fontWeight="bold">
                  {boxes?.vehicle_class_details[0]?.name}
                </Typography>
                <Typography fontSize="12px" textAlign="start">
                  {boxes?.vehicle_class_details[0]?.class}-{" "}
                  {boxes?.vehicle_class_details[0]?.sub_class}
                </Typography>
              </Box>
              <Box width="56px" height="56px">
                <img alt="" src={image} style={{ width: 46, height: 46 }} />
              </Box>
            </Box>
          </Box>
        </Box>

        <Box
          padding="10px"
          ml="10px"
          mt="3px"
          mr="10px"
          mb="10px"
          sx={{ backgroundColor: "white", borderRadius: "5px" }}
          boxShadow="5px 5px 15px rgba(0, 0, 0, 0.2)"
        >
          <Box display="flex" justifyContent="space-between">
            <Typography fontSize="15px" fontWeight="bold">
              Name
            </Typography>
            <Typography fontWeight="bold" fontSize="15px">
              {boxes?.mft_details?.name}
            </Typography>
          </Box>
          <Box display="flex" justifyContent="space-between">
            <Typography fontSize="11px">Address</Typography>
            <Typography fontSize="11px">
              {truncateAddress(boxes?.mft_details?.address, 30)}
            </Typography>
          </Box>
        </Box>

        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            mt: "5px",
            padding: "10px",
            boxShadow: "5px 5px 15px rgba(0, 0, 0, 0.2)",
          }}
        >
          <Box display="flex" mt="5px">
            <Typography
              sx={{ fontWeight: "bold", fontSize: "13px", color: "green" }}
            >
              Created :
            </Typography>
            <Typography sx={{ fontSize: "13px" }}>
              {boxes?.created_at}
            </Typography>
          </Box>
          <Box display="flex" ml="30px">
            <Typography
              sx={{ fontWeight: "bold", fontSize: "13px", color: "white " }}
            >
              Updated :
            </Typography>
            <Button
              endIcon={<DoubleArrowIcon />}
              size="small"
              sx={{ fontWeight: "bold" }}
              variant="outlined"
              onClick={() => handleClickBox(boxes?.project_id)}
            >
              View
            </Button>
          </Box>
        </Box>
      </Paper>
    </div>
  );
}

export default FrontBox1;
