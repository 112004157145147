/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 25-07-2024
 * @description Lab Car API details to perform a action
 */

import actionType from '../actionTypes';

const labCar = {
    get_project_details_mind_map_pa: {
        api_name: "/PCM/project/mind-map",
        https_method: "GET",
        action_type: actionType.GET_PROJECT_DETAILS_MIND_MAP_PA,
        description: "",
    },
    get_project_details_pa: {
        api_name: "/PCM/project/details",
        https_method: "GET",
        action_type: actionType.GET_PROJECT_DETAILS_PA,
        description: "",
    },
    get_ee_components_pa: {
        api_name: "/PCM/ee_components/details",
        https_method: "GET",
        action_type: actionType.GET_EE_COMPONENTS_PA,
        description: "",
    },
    get_sw_modules_pa: {
        api_name: "/PCM/swm/details",
        https_method: "GET",
        action_type: actionType.GET_SW_MODULES_PA,
        description: "",
    },
    get_project_details_mind_map: {
        api_name: "/PCM/project/mind-map",
        https_method: "GET",
        action_type: actionType.GET_PROJECT_DETAILS_MIND_MAP,
        description: "",
    },
    get_project_details: {
        api_name: "/PCM/project/details",
        https_method: "GET",
        action_type: actionType.GET_PROJECT_DETAILS,
        description: "",
    },
    get_ee_components: {
        api_name: "/PCM/ee_components/details",
        https_method: "GET",
        action_type: actionType.GET_EE_COMPONENTS,
        description: "",
    },
    add_ee_component: {
        api_name: "/PCM/ee_components/add",
        https_method: "POST",
        action_type: actionType.Add_EE_COMPONENT,
        description: "",
    },
    update_ee_component: {
        api_name: "/PCM/ee_components/details",
        https_method: "PUT",
        action_type: actionType.UPDATE_EE_COMPONENT,
        description: "",
    },
    replace_ee_component: {
        api_name: "/PCM/ee_components/replace",
        https_method: "POST",
        action_type: actionType.REPLACE_EE_COMPONENT,
        description: "",
    },
    get_sw_modules: {
        api_name: "/PCM/swm/details",
        https_method: "GET",
        action_type: actionType.GET_SW_MODULES,
        description: "",
    },
    get_main_sw_modules: {
        api_name: "/PCM/swm/details",
        https_method: "GET",
        action_type: actionType.GET_MAIN_SW_MODULES,
        description: "",
    },
    get_linked_sw_modules: {
        api_name: "/PCM/swm/details",
        https_method: "GET",
        action_type: actionType.GET_LINKED_SW_MODULES,
        description: "",
    },
    add_sw_module: {
        api_name: "/PCM/swm/add",
        https_method: "POST",
        action_type: actionType.Add_SW_MODULE,
        description: "",
    },
    update_sw_module: {
        api_name: "/PCM/swm/details",
        https_method: "PUT",
        action_type: actionType.Update_SW_MODULE,
        description: "",
    },
    replace_sw_module: {
        api_name: "/PCM/swm/replace",
        https_method: "POST",
        action_type: actionType.REPLACE_SW_MODULE
    },
    link_sw_module: {
        api_name: "/PCM/swm/link",
        https_method: "PUT",
        action_type: actionType.LINK_SW_MODULE,
        description: "",
    },
    unlink_sw_module: {
        api_name: "/PCM/swm/un-link",
        https_method: "PUT",
        action_type: actionType.UNLINK_SW_MODULE,
        description: "",
    },
    get_ee_components_main: {
        api_name: "/PCM/ee_components/details",
        https_method: "GET",
        action_type: actionType.GET_EE_COMPONENTS_MAIN,
        description: "",
    },
    get_ee_components_linked: {
        api_name: "/PCM/ee_components/details",
        https_method: "GET",
        action_type: actionType.GET_EE_COMPONENTS_LINKED,
        description: "",
    },
    get_ee_components_main_linked: {
        api_name: "/PCM/ee_components/details",
        https_method: "GET",
        action_type: actionType.GET_EE_COMPONENTS_MAIN_LINKED,
        description: "",
    },
}

export default labCar;