/**
 * @author Tahir Shaik - tahir@au79consulting.com
 * @date 01-06-2024
 * @description This is the profile page where user can edit and view the profile
 */

import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from "react-redux";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import DefaultProfilePic from "./../../assets/logoIcons/defaultProfilePic.png";
import { toast } from "react-toastify";
import { useEffect, useState } from "react";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import proileActions from "../../redux/actions/profile";
import EditUserProfile from "../../components/dialog-boxes/EditUserProfile";
import styles from "./profileStyle";
import customConsole from "../../config/customConsole";
import moment from "moment";
function ProfileConfig() {
  const profileDetails = useSelector((store) => store.profileReducer);
  const countryRegionsStore = useSelector(
    (store) => store.countryAndPhoneCodeReducer
  );
  const dispatch = useDispatch();
  const [isProfilePicUploaded, setIsProfilePicUploaded] = useState(false);
  const [userDetails, setUserDetails] = useState([]);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [userEditDetails, setUserEditDetails] = useState({});
  const [profilePictPath, setProfilePictPath] = useState("");
  const [profilePicFileName, setProfilePicFileName] = useState("");
  //Get the Profile details for the first time when component Mounts

  useEffect(() => {
    handleGetProfileDetails();
    dispatch(
      HandleApiActions({
        ...proileActions.get_coutry_regions,
        show_toast: false,
      })
    );
  }, []);
  useEffect(() => {}, [countryRegionsStore]);
  // Use Effect to check the Profile details updated or not
  useEffect(() => {
    if (!profileDetails.error) {
      // Success response
      //If profile Picture Uploaded and got the profile picture name
      //Then Update the Profile with the details
      if (
        isProfilePicUploaded &&
        profileDetails.profile_pic_name &&
        profileDetails.profile_pic_name.length
      ) {
        customConsole("Prodile pic uploaded, update the profile");
        customConsole(profileDetails.profile_pic_name);
        //Update the Profile details with the profile picture name
        //  && Get the Updated profile details
        setIsProfilePicUploaded(false);
        handleUpdateProfileDetails({});
        handleUpdateProfileDetails({
          profile_pic: profileDetails.profile_pic_name,
          current_time: moment().format(),
        });
      } else {
        //Profile Details
        if (
          profileDetails.profile &&
          Object.keys(profileDetails.profile).length
        ) {
          setUserDetails([
            {
              header: "Name",
              data: profileDetails.profile.fullname,
              show_edit_icon: true,
            },
            {
              header: "Email",
              data: profileDetails.profile.email,
              show_edit_icon: false,
            },
            {
              header: "Role",
              data:
                profileDetails.profile.role_details &&
                profileDetails.profile.role_details.role_name
                  ? profileDetails.profile.role_details.role_name
                  : "-",
              show_edit_icon: false,
            },
            {
              header: "Department",
              data:
                profileDetails.profile.role_details &&
                profileDetails.profile.role_details.department
                  ? profileDetails.profile.role_details.department
                  : "-",
              show_edit_icon: false,
            },
            {
              header: "Phone",
              data:
                profileDetails.profile.phone_code +
                "-" +
                profileDetails.profile.phone,
              show_edit_icon: true,
            },
            {
              header: "Time Zone",
              data:
                profileDetails.profile.profile &&
                profileDetails.profile.profile.region
                  ? profileDetails.profile.profile.region
                  : "-",
              show_edit_icon: true,
            },
          ]);
          if (
            profileDetails.profile.profile &&
            profileDetails.profile.profile.profile_pic &&
            profilePicFileName !== profileDetails.profile.profile.profile_pic
          ) {
            customConsole("Profile Picture changed!");
            setProfilePicFileName(
              profileDetails?.profile?.profile?.profile_pic
            );
          }
        }
        //Get the Profile Picture
        if (profilePictPath !== profileDetails.profile_pic_path) {
          customConsole(
            "profileDetails.profile_pic_pathsss: ",
            profileDetails.profile_pic_path
          );
          setProfilePictPath(profileDetails?.profile_pic_path);
        }
      }
    } else {
      // Error response
      if (isProfilePicUploaded) {
        setIsProfilePicUploaded(false);
      }
    }
  }, [profileDetails]);
  useEffect(() => {
    if (profilePicFileName && profilePicFileName.length) {
      dispatch(
        HandleApiActions({
          ...proileActions.get_profile_pic,
          show_toast: true,
          file_name: profileDetails.profile.profile.profile_pic,
          is_file: true,
        })
      );
    }
  }, [profilePicFileName]);
  const handelUploadProfile = (event) => {
    const file = event.target.files[0];
    // Check if a file is selected
    if (file) {
      // Get the file extension
      const fileExtension = file.name.split(".").pop().toLowerCase();
      const allowedExtensions = ["jpg", "jpeg", "png"];
      if (allowedExtensions.includes(fileExtension)) {
        setIsProfilePicUploaded(true);
        // Upload the Profile Picture => Get the file name
        dispatch(
          HandleApiActions({
            ...proileActions.upload_profile_pic,
            show_toast: true,
            file: file,
            is_file: true,
          })
        );
        //  Update the profile with the profile picture
      } else {
        toast.dark("Please select a valid image file", {
          type: "error",
          position: "bottom-right",
          autoClose: 3000,
        });
      }
    }
  };
  const handleUpdateProfileDetails = (data) => {
    dispatch(
      HandleApiActions({
        ...proileActions.update_profile_details,
        show_toast: true,
        params: data,
      })
    );
    setTimeout(handleGetProfileDetails, 500);
  };
  const handleGetProfileDetails = () => {
    dispatch(
      HandleApiActions({
        ...proileActions.get_profile_details,
        show_toast: false,
        params: {},
      })
    );
  }; //Function to Edit the User Profile
  const handleEditProfile = (data) => {
    customConsole("-- handleEditProfile --");
    customConsole(data);
    setUserEditDetails(data);
    setOpenEditDialog(true);
  };
  const handleUpdateUserDetails = (data) => {
    customConsole("-- handleUpdateUserDetails --");
    customConsole(data);
    setOpenEditDialog(false);
    handleUpdateProfileDetails(data);
  };
  return (
    <Box>
      <Grid container spacing={2} sx={styles.gridContainer}>
        {/* Profile Piture (Upload and display the existing) */}
        <Grid
          item
          xs={12}
          md={6}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box>
            {/* Display the Image */}
            <Box>
              <Avatar
                sx={styles.displayImageBox}
                alt="Profile picture."
                src={profilePictPath ? profilePictPath : DefaultProfilePic}
                variant="square"
              />
            </Box>
            {/* Upload the image */}
            <Button
              variant="standard"
              component="label"
              sx={styles.uploadImageBox}
            >
              <UploadFileIcon color="blue" size={18} />
              <input
                accept="image/*"
                hidden
                type="file"
                onChange={handelUploadProfile}
              />
            </Button>
          </Box>
        </Grid>
        <Grid xs={12} md={6} sx={styles.gi1}>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: 2,
              marginTop: { xs: 0, sm: "30px" },
            }}
          >
            {userDetails.map((data, index) => {
              return (
                <Box sx={styles.profileDataBox} key={index}>
                  <Typography sx={styles.profileHeader}>
                    {data.header}
                  </Typography>{" "}
                  <strong>:</strong>
                  <Typography sx={styles.profileContent}>
                    {data.data}
                  </Typography>
                  {data.show_edit_icon ? (
                    <Button onClick={() => handleEditProfile(data)}>
                      🖋️ Edit
                    </Button>
                  ) : null}
                </Box>
              );
            })}
          </Box>
        </Grid>
      </Grid>
      {/* Dialog box to edit the profile details */}
      {openEditDialog && (
        <EditUserProfile
          open={openEditDialog}
          handleClose={() => setOpenEditDialog(false)}
          handleUpdate={handleUpdateUserDetails}
          data={userEditDetails.data}
          header={userEditDetails.header}
          phone_codes={
            countryRegionsStore.countryAndPhoneCode &&
            countryRegionsStore.countryAndPhoneCode.length
              ? countryRegionsStore.countryAndPhoneCode
                  .map((data) => data.country + ": " + data.phone_code)
                  .sort((a, b) => a.localeCompare(b))
              : []
          }
          regions={
            countryRegionsStore.countryAndPhoneCode &&
            countryRegionsStore.countryAndPhoneCode.length
              ? countryRegionsStore.countryAndPhoneCode
                  .map((data) => data?.time_zone)
                  .sort((a, b) => a.localeCompare(b))
              : []
          }
        />
      )}
    </Box>
  );
}

export default ProfileConfig;
