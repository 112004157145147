import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import styled from "@mui/system/styled";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Typography from "@mui/material/Typography";
import React, { useEffect, useMemo, useState } from "react";
import SelectTextField from "../../components/text-fields/SelectTextField";
import CustomTextField2 from "../../components/text-fields/CustomTextField2";
import CustomUpdateDialog from "../../components/dialog-boxes/CustomUpdateDialog";
import { useDispatch, useSelector } from "react-redux";
import AddIcon from "@mui/icons-material/Add";
import {
  GetSingleImages,
  HandleApiActions,
} from "../../redux/actions/actionHandler";
import projectConfigAPI from "../../redux/actions/projectConfigAPI";
import moment from "moment";
import profileActions from "../../redux/actions/profile";
import adminSettingsAPI from "../../redux/actions/adminSetttingAPI";
import settingsApis from "../../redux/actions/settings";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import MultiSelectTextField4 from "../../components/text-fields/MultiSelectTextField4";
import { Avatar, Chip, Grid, IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { convertUtcToTimeZone } from "../../utils/convertUtcToTimeZone";
import UpdateStatus from "./UpdateStatus";
import EditVehicleFunction from "./EditVehicleFunction";
function ViewDialog1({ boxes, profileDetails }) {
  console.log(boxes);

  const projectStatus = {
    100: (
      <Chip
        sx={{
          boxShadow: "3",
          fontWeight: "bold",
          borderRadius: "0px 15px 0px 15px",
          backgroundColor: "#c8e8fa",
          color: "black",
        }}
        label={"Active Pre SOP"}
        size="medium"
      />
    ),
    200: (
      <Chip
        sx={{
          boxShadow: "3",
          fontWeight: "bold",
          borderRadius: "0px 15px 0px 15px",
          backgroundColor: "#f7f6cd",
          color: "black",
        }}
        label={"Active Post SOP"}
        size="medium"
      />
    ),
    300: (
      <Chip
        sx={{
          boxShadow: "3",
          fontWeight: "bold",
          borderRadius: "0px 15px 0px 15px",
          backgroundColor: "red",
          color: "white",
        }}
        label={"Deactivated"}
        size="medium"
      />
    ),
    500: (
      <Chip
        sx={{
          boxShadow: "3",
          fontWeight: "bold",
          borderRadius: "0px 15px 0px 15px",
          backgroundColor: "#9af5a2",
          color: "green",
        }}
        label={"Completed"}
        size="medium"
      />
    ),
  };
  const dispatch = useDispatch();

  const vehicleFunctionDetails = useSelector(
    (state) => state.vehicleFunctionReducer
  );
  const projectStatusDetails = useSelector(
    (state) => state.settingsReducer?.metaData?.project_status
  );

  const projectStatusList = Object.keys(projectStatusDetails);

  const [openDialog, setOpenDialog] = useState(false);
  const [editTradeMark, setEditTradeMark] = useState("");
  const [editRegions, setEditRegions] = useState("");
  const [editVF, setEditVF] = useState([]);
  const [value, setValue] = React.useState("1");
  const [selectPS, setSelectPS] = useState("");
  const [regions, setRegions] = useState([]);
  const [trademarkError, setTradeMarkError] = useState(false);
  const [vfData, setVfData] = useState([]);
  const [vcImage, setVcImage] = useState("");
  const [image, setImage] = useState("");

  const [openVfDialog, setOpenVfDialog] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const [projectData, setProjectData] = useState([]);
  const [openStatus, setOpenStatus] = useState(false);
  const imageURL = async (imageDetails) => {
    const resp = await GetSingleImages(imageDetails);
    console.log("imagesURL RESP: ");
    console.log(resp);
    setImage(resp);
    return resp;
  };

  useEffect(() => {
    console.log("vehicleFunctionDetails......");

    console.log(vehicleFunctionDetails?.vehicleFunctionDetails?.data);
    setVfData(vehicleFunctionDetails?.vehicleFunctionDetails?.data);
  }, [vehicleFunctionDetails, openDialog]);

  React.useEffect(() => {
    console.log("-- boxes ---");
    console.log(boxes);
    if (boxes) {
      setEditTradeMark(boxes?.trade_mark || "");

      setEditVF(
        boxes?.vehicle_function_details.map((vf) => String(vf?.id)) || []
      );
      if (
        boxes?.vehicle_class_details &&
        boxes?.vehicle_class_details?.length
      ) {
        imageURL({ image: boxes.vehicle_class_details[0].image });
      }
    }
  }, [boxes]);

  useEffect(() => {
    let projectStatusData = projectStatusList?.map((el, idx) => {
      return {
        value: idx,
        label: formatString(el),
      };
    });

    setProjectData(projectStatusData);
  }, [projectStatusDetails]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleClickEdit = () => {
    const reqObj = {
      project_id: parseInt(boxes?.project_id),

      ...(openVfDialog === false ? { trade_mark: editTradeMark } : {}),
      ...(openVfDialog === true
        ? { vehicle_funcs: editVF?.map((str) => parseInt(str)) }
        : {}),

      current_time: moment().format(),
    };
    console.log("reqObj", reqObj);
    dispatch(
      HandleApiActions({
        ...projectConfigAPI.update_project,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(HandleApiActions({ ...projectConfigAPI.get_project }));
    });
    setOpenDialog(false);
    setOpenVfDialog(false);
  };

  const getStatusName = (statusCode) => {
    return projectStatus[statusCode] || "Unknown Status";
  };

  function capitalizeFirstLetterOnly(str) {
    if (!str) return ""; // Handle empty string or null input
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  function capitalizeFirstLetter(str) {
    if (!str) return ""; // Handle empty string or null input
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }

  function formatString(str) {
    let result = str.charAt(0).toUpperCase() + str.slice(1);

    result = result
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return result;
  }
  return (
    <div>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={4}>
            {/* Project details and status */}
            <Paper
              elevation={12}
              sx={{ padding: "10px", minHeight: "250px", borderRadius: "10px" }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  marginBottom: "5px",
                  textAlign: "center",
                }}
              >
                Project details
              </Typography>

              {[
                { key: "Name", value: boxes?.name || "-" },
                {
                  key: "Architecture",
                  value: boxes?.architecture_name || "-",
                },
                {
                  key: "Trademark",
                  value: boxes?.trade_mark || "-",
                },
                {
                  key: "Zones",
                  value: boxes?.zones.length || "-",
                },
                {
                  key: "EE Components",
                  value: boxes?.ee_ids.length || "-",
                },
                {
                  key: "Status",
                  value: boxes?.status || "-",
                },
              ].map((data) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        textAlign: "left",
                        minWidth: "100px",
                        color: "gray",
                      }}
                    >
                      {data.key}
                    </Typography>
                    <strong>:</strong>
                    {data.key.toLowerCase() === "status" ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "10px",
                          marginBottom: "10px",
                        }}
                      >
                        <Typography ml="10px">
                          {getStatusName(boxes?.status)}
                        </Typography>
                        <IconButton
                          sx={{ color: "blue" }}
                          size="small"
                          onClick={() => setOpenStatus(true)}
                        >
                          <EditIcon sx={{ width: "20px", height: "20px" }} />
                        </IconButton>
                      </Box>
                    ) : (
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          overflow: "auto",
                        }}
                      >
                        {data.value}{" "}
                        {data.key.toLowerCase() === "trademark" ? (
                          <IconButton
                            sx={{ color: "blue" }}
                            size="small"
                            onClick={() => setOpenDialog(true)}
                          >
                            <EditIcon sx={{ width: "20px", height: "20px" }} />
                          </IconButton>
                        ) : undefined}
                      </Typography>
                    )}
                  </Box>
                );
              })}
            </Paper>
          </Grid>
          {/* Project Vehicle details & its Status */}
          <Grid item xs={12} sm={4}>
            <Paper
              elevation={12}
              sx={{ padding: "10px", minHeight: "250px", borderRadius: "10px" }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  marginBottom: "5px",
                  textAlign: "center",
                }}
              >
                Vehicle details
              </Typography>
              {[
                {
                  key: "Platform",
                  value: boxes?.vehicle_platform || "-",
                },
                {
                  key: "Name",
                  value: boxes?.vehicle_class_details[0].name || "-",
                },
                {
                  key: "Class",
                  value: boxes?.vehicle_class_details[0].class || "-",
                },
                {
                  key: "Sub-Class",
                  value: boxes?.vehicle_class_details[0].sub_class || "-",
                },
                {
                  key: "Status",
                  value: boxes?.vehicle_status || "-",
                },
              ].map((data) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        textAlign: "left",
                        minWidth: "100px",
                        color: "gray",
                      }}
                    >
                      {data.key}
                    </Typography>
                    <strong>:</strong>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      {data.value}
                    </Typography>
                  </Box>
                );
              })}
              {/* Vehicle Image */}
              <Box
                sx={{
                  // textAlign: "center",
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <img
                  src={image}
                  style={{ width: "100px", height: "60px" }}
                  alt="vehicle pic!"
                />
              </Box>
            </Paper>
          </Grid>
          {/* Other Details like Mft Details, created at, timezone, country, etc.. */}
          <Grid item xs={12} sm={4}>
            <Paper
              elevation={12}
              sx={{ padding: "10px", minHeight: "250px", borderRadius: "10px" }}
            >
              <Typography
                sx={{
                  fontWeight: "bold",
                  fontSize: "18px",
                  marginBottom: "5px",
                  textAlign: "center",
                }}
              >
                Other details
              </Typography>
              {[
                {
                  key: "Manufacturer Name",
                  value: boxes?.mft_details?.name || "-",
                },
                {
                  key: "Manufacturer Address",
                  value: boxes?.mft_details?.address || "-",
                },
                {
                  key: "Project Created at",
                  value: boxes?.created_at || "-",
                },
                {
                  key: "Last updated at",
                  value: boxes?.updated_at || "-",
                },
                {
                  key: "Country",
                  value: boxes?.country || "-",
                },
                {
                  key: "Timezone",
                  value: boxes?.region || "-",
                },
              ].map((data) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 2,
                      alignItems: "start",
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        textAlign: "left",
                        minWidth: "155px",
                        color: "gray",
                      }}
                    >
                      {data.key}
                    </Typography>
                    <strong>:</strong>
                    <Typography
                      sx={{
                        fontSize: "14px",
                        fontWeight: "bold",
                        overflow: "auto",
                      }}
                    >
                      {data.value}
                    </Typography>
                  </Box>
                );
              })}
            </Paper>
          </Grid>
        </Grid>

        <Paper elevation={3}>
          <Box sx={{ width: "100%", mt: "20px", borderRadius: "10px" }}>
            <TabContext value={value} variant="scrollable">
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <StyledTabs
                  variant="scrollable"
                  value={value}
                  onChange={handleChange}
                  aria-label="styled tabs example"
                >
                  {boxes?.zones && boxes?.zones.length && (
                    <StyledTab label="Zones" value="2" />
                  )}
                  <StyledTab label="Vehicle Function" value="1" />
                  <StyledTab label="PowerTrain Class" value="3" />
                  <StyledTab label="Project Owner" value="4" />
                  <StyledTab label="Co-Owners" value="5" />
                  <StyledTab label="Editors" value="6" />
                  <StyledTab label="Viewers" value="7" />
                </StyledTabs>
              </Box>
              <TabPanel value="2">
                {boxes?.zones && boxes?.zones?.length ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    {boxes?.zones?.map((data) => {
                      return (
                        <Paper
                          elevation={12}
                          sx={{ height: "30px", padding: "5px" }}
                        >
                          <Typography
                            sx={{ fontWeight: "bold", fontSize: "14px" }}
                          >
                            &nbsp;{data?.position}&nbsp;&nbsp;-&nbsp;&nbsp;
                            {data?.zone_name}&nbsp;
                          </Typography>
                        </Paper>
                      );
                    })}
                  </Box>
                ) : (
                  <Typography>No Zones</Typography>
                )}
              </TabPanel>
              <TabPanel value="1">
                <Box display="flex" justifyContent="flex-end">
                  <Button
                    startIcon={<AddIcon />}
                    variant="contained"
                    onClick={() => setOpenVfDialog(true)}
                  >
                    ADD
                  </Button>
                </Box>
                {openVfDialog && (
                  <EditVehicleFunction
                    openVfDialog={openVfDialog}
                    setOpenVfDialog={setOpenVfDialog}
                    editVF={editVF}
                    setEditVF={setEditVF}
                    handleClickEdit={handleClickEdit}
                  />
                  // <CustomUpdateDialog
                  //   open={openVfDialog}
                  //   onClose={() => {
                  //     setOpenVfDialog(false);
                  //   }}
                  //   dialogTitle={"Add Vehicle Function"}
                  //   dialogContent={
                  //     <Stack spacing={2}>
                  //       <Box mt="10px">
                  //         <Box sx={{ mt: "25px" }}>
                  //           <Typography fontWeight={"bold"} ml="2px">
                  //             Select the Vehicle Function
                  //           </Typography>
                  //           <MultiSelectTextField4
                  //             mutiple
                  //             size={"small"}
                  //             label={"Select Vehicle Function"}
                  //             optionsList={vfData}
                  //             selectedValues={editVF}
                  //             setSelectedValues={setEditVF}
                  //             sltFldStyle={{
                  //               width: "400px",
                  //               textAlign: "start",
                  //               mt: "10px",
                  //               backgroundColor: "white",
                  //             }}
                  //           />
                  //         </Box>
                  //       </Box>
                  //     </Stack>
                  //   }
                  //   onConfirm={handleClickEdit}
                  // />
                )}
                {boxes?.vehicle_function_details &&
                boxes?.vehicle_function_details?.length ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "10px",
                      marginTop: "10px",
                    }}
                  >
                    {boxes?.vehicle_function_details?.map((data, idx) => {
                      return (
                        <Paper
                          key={idx}
                          elevation={12}
                          sx={{ padding: "10px", borderRadius: "10px" }}
                        >
                          <Typography
                            sx={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            {data.name} - ({data.abbreviation})
                          </Typography>
                          {data.other_fields?.description && (
                            <Typography
                              sx={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              {data.other_fields?.description}
                            </Typography>
                          )}
                        </Paper>
                      );
                    })}{" "}
                  </Box>
                ) : (
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "12px", color: "red" }}
                  >
                    Details not found!
                  </Typography>
                )}
                {/* </Box> */}
              </TabPanel>

              <TabPanel value="3">
                {boxes?.powertrain_class_details &&
                boxes?.powertrain_class_details?.length > 0 ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    {boxes?.powertrain_class_details?.map((data, idx) => {
                      return (
                        <Paper
                          key={idx}
                          elevation={12}
                          sx={{ padding: "10px", borderRadius: "10px" }}
                        >
                          <Typography
                            sx={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            {data.name} - ({data.abbreviation})
                          </Typography>
                          {data.other_fields?.description && (
                            <Typography
                              sx={{ fontSize: "12px", fontWeight: "bold" }}
                            >
                              {data.other_fields?.description}
                            </Typography>
                          )}
                        </Paper>
                      );
                    })}
                  </Box>
                ) : (
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "12px", color: "red" }}
                  >
                    Details not found!
                  </Typography>
                )}
                {/* </Box> */}
              </TabPanel>
              <TabPanel value="4">
                {" "}
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    gap: "10px",
                  }}
                >
                  <Paper
                    elevation={12}
                    sx={{ padding: "10px", borderRadius: "10px" }}
                  >
                    {[
                      {
                        key: "Name",
                        value:
                          (boxes?.owner_details &&
                            boxes?.owner_details[0]?.name) ||
                          "-",
                      },
                      {
                        key: "Email",
                        value:
                          (boxes?.owner_details &&
                            boxes?.owner_details[0]?.email) ||
                          "-",
                      },
                    ].map((data) => {
                      return (
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            gap: 2,
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            sx={{
                              fontWeight: "bold",
                              fontSize: "14px",
                              textAlign: "left",
                              minWidth: "100px",
                              color: "gray",
                            }}
                          >
                            {data.key}
                          </Typography>
                          <strong>:</strong>
                          <Typography
                            sx={{ fontSize: "14px", fontWeight: "bold" }}
                          >
                            {data.value}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Paper>
                </Box>
              </TabPanel>
              <TabPanel value="5">
                {boxes?.co_owner_details &&
                boxes.co_owner_details.length &&
                boxes.co_owner_details[0]?.role_id ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    {boxes.co_owner_details.map((details, index) => {
                      return (
                        <Paper
                          key={index}
                          elevation={12}
                          sx={{ padding: "10px", borderRadius: "10px" }}
                        >
                          {[
                            {
                              key: "Name",
                              value: details.name || "-",
                            },
                            {
                              key: "Email",
                              value: details.email || "-",
                            },
                          ].map((data, idx) => {
                            return (
                              <Box
                                key={idx}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 2,
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    textAlign: "left",
                                    minWidth: "100px",
                                    color: "gray",
                                  }}
                                >
                                  {data.key}
                                </Typography>
                                <strong>:</strong>
                                <Typography
                                  sx={{ fontSize: "14px", fontWeight: "bold" }}
                                >
                                  {data.value}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Paper>
                      );
                    })}
                  </Box>
                ) : (
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "12px", color: "red" }}
                  >
                    Details not found!
                  </Typography>
                )}
              </TabPanel>
              <TabPanel value="6">
                {boxes?.edit_user_details &&
                boxes.edit_user_details.length &&
                boxes.edit_user_details[0]?.role_id ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    {boxes.edit_user_details.map((details, index) => {
                      return (
                        <Paper
                          key={index}
                          elevation={12}
                          sx={{ padding: "10px", borderRadius: "10px" }}
                        >
                          {[
                            {
                              key: "Name",
                              value: details.name || "-",
                            },
                            {
                              key: "Email",
                              value: details.email || "-",
                            },
                          ].map((data, idx) => {
                            return (
                              <Box
                                key={idx}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 2,
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    textAlign: "left",
                                    minWidth: "100px",
                                    color: "gray",
                                  }}
                                >
                                  {data.key}
                                </Typography>
                                <strong>:</strong>
                                <Typography
                                  sx={{ fontSize: "14px", fontWeight: "bold" }}
                                >
                                  {data.value}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Paper>
                      );
                    })}
                  </Box>
                ) : (
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "12px", color: "red" }}
                  >
                    Details not found!
                  </Typography>
                )}
              </TabPanel>
              <TabPanel value="7">
                {" "}
                {boxes?.view_user_details &&
                boxes.view_user_details.length &&
                boxes.view_user_details[0]?.role_id ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      flexWrap: "wrap",
                      gap: "10px",
                    }}
                  >
                    {boxes.view_user_details.map((details, index) => {
                      return (
                        <Paper
                          key={index}
                          elevation={12}
                          sx={{ padding: "10px", borderRadius: "10px" }}
                        >
                          {[
                            {
                              key: "Name",
                              value: details.name || "-",
                            },
                            {
                              key: "Email",
                              value: details.email || "-",
                            },
                          ].map((data, idx) => {
                            return (
                              <Box
                                key={idx}
                                sx={{
                                  display: "flex",
                                  flexDirection: "row",
                                  gap: 2,
                                  alignItems: "center",
                                }}
                              >
                                <Typography
                                  sx={{
                                    fontWeight: "bold",
                                    fontSize: "14px",
                                    textAlign: "left",
                                    minWidth: "100px",
                                    color: "gray",
                                  }}
                                >
                                  {data.key}
                                </Typography>
                                <strong>:</strong>
                                <Typography
                                  sx={{ fontSize: "14px", fontWeight: "bold" }}
                                >
                                  {data.value}
                                </Typography>
                              </Box>
                            );
                          })}
                        </Paper>
                      );
                    })}
                  </Box>
                ) : (
                  <Typography
                    sx={{ fontWeight: "bold", fontSize: "12px", color: "red" }}
                  >
                    Details not found!
                  </Typography>
                )}
              </TabPanel>
            </TabContext>
          </Box>
        </Paper>
        {openStatus && (
          <UpdateStatus
            openStatus={openStatus}
            setOpenStatus={setOpenStatus}
            boxes={boxes}
          />
          // <CustomUpdateDialog
          //   open={openStatus}
          //   onClose={() => {
          //     setOpenStatus(false);
          //   }}
          //   dialogTitle={"Update Status"}
          //   dialogContent={
          //     <Stack spacing={2}>
          //       <Box sx={{ marginTop: "10px" }}>
          //         <SelectTextField
          //           required
          //           size={"small"}
          //           label={"Project Status"}
          //           optionsList={projectData}
          //           selectedValue={selectPS}
          //           setSelectedValue={setSelectPS}
          //           variant={"standard"}
          //           sltFldStyle={{
          //             width: "200px",
          //           }}
          //         />
          //       </Box>
          //     </Stack>
          //   }
          //   onConfirm={() => handleClickEditStatus(boxes)}
          // />
        )}

        {openDialog && (
          <CustomUpdateDialog
            open={openDialog}
            onClose={() => {
              setOpenDialog(false);
            }}
            dialogTitle={"Edit Project"}
            dialogContent={
              <Stack spacing={2}>
                <CustomTextField2
                  size={"small"}
                  label={"Trademark"}
                  placeholder={"Trademark"}
                  value={editTradeMark}
                  setValue={setEditTradeMark}
                  txtFldStyle={{
                    width: "400px",
                  }}
                  validationRules={{ minLength: 3 }}
                  setError={setTradeMarkError}
                  error={trademarkError}
                />
              </Stack>
            }
            onConfirm={handleClickEdit}
          />
        )}
      </Box>
    </div>
  );
}

export default ViewDialog1;

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontWeight: "bold",
    fontSize: "1rem",
    marginRight: theme.spacing(1),
    color: "green",
    "&.Mui-selected": {
      color: "black",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    maxWidth: 60,
    width: "100%",
    backgroundColor: "#635ee7",
  },
});
