import React from "react";
import Checkbox from "@mui/material/Checkbox";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ListItemText from "@mui/material/ListItemText";
import MenuItem from "@mui/material/MenuItem";
import OutlinedInput from "@mui/material/OutlinedInput";
import Select from "@mui/material/Select";

function MultiSelectTextField4({
  size,
  label,
  optionsList,
  selectedValues,
  setSelectedValues,
  sltFldStyle,
}) {
  const menuProps = {
    PaperProps: {
      style: {
        maxHeight: 48 * 4.5 + 8,
        width: 220,
      },
    },
  };

  // Handles change events for the multi-select dropdown.
  const handleChange = (event) => {
    console.log(event.target.value);
    const {
      target: { value },
    } = event;
    setSelectedValues(typeof value === "string" ? value.split(",") : value);
  };

  return (
    <FormControl size={size} sx={sltFldStyle}>
      <InputLabel>{label}</InputLabel>
      <Select
        multiple
        value={selectedValues}
        onChange={handleChange}
        input={<OutlinedInput label={label} />}
        renderValue={(selected) =>
          selected
            .map((id) => {
              const option = optionsList?.find((opt) => opt.id === id);
              return option ? option.abbreviation : "";
            })
            .join(", ")
        }
        MenuProps={menuProps}
      >
        {optionsList?.map((data) => (
          <MenuItem key={data?.id} value={data?.id}>
            <Checkbox
              checked={selectedValues.indexOf(data?.id) > -1}
              disabled={selectedValues.includes(data?.id)}
            />
            <ListItemText primary={`${data?.name}-${data?.abbreviation}`} />
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
}

export default MultiSelectTextField4;
