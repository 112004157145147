/**
 * @author : Abdul Rahim M - abdul@au79consulting.com
 * @Date : 06-07-2024
 * @description Reducer function for handling Files related actions and state changes.
 */

import actionType from "../actionTypes";

const initialProfileState = {
  error: true,
  fileName: "",
  msg: "",
};

/**
 * @author : Abdul Rahim M - abdul@au79consulting.com
 * @Date : 04-07-2024
 * @description
 * @param {}
 * @return New state after processing the action
 */

const fileHandlingReducers = (state = initialProfileState, action) => {
  console.log(action);
  switch (action.type) {
    case actionType.GET_FILE:
      return {
        error: action.payload.data.error,
        fileName: action.payload,
      };
    case actionType.ADD_FILE:
      return {
        error: action.payload.data.error,
        fileName: action.payload,
      };
    case actionType.SET_FILE:
      console.log("action.payload");
      console.log(action.payload);

      return {
        fileName: action.payload,
      };

    default:
      return state;
  }
};

export default fileHandlingReducers;
