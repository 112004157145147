/**
 * @author Tahir Shaik
 * @date 01-08-2024
 * @description Component to control the Project Access -> Grant/Revoke
 */
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import customConsole from "../../config/customConsole";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import labCar from "../../redux/actions/labCar";
import ProjectAccessDrawer from "./ProjectAccessDrawer";
import { useDispatch } from "react-redux";
function ProjectAccess() {
  const dispatch = useDispatch();
  const accessTypes = { PROJECT: 100, EE: 200, SWM: 300 };
  const [selectedProject, setSelectedProject] = useState(0);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [projectDetails, setProjectDetails] = useState({});
  const [isAccessDrawerOpened, setIsAccessDrawerOpened] = useState(false);
  const [accessControlData, setAccessControlData] = useState({});
  const handleCloseDrawer = () => {
    setIsAccessDrawerOpened(false);
    setAccessControlData({});
  };
  //Function to the COmplete project details
  const getProjectDetails = async (projectId) => {
    let apiDetails = labCar.get_project_details_mind_map_pa;
    delete apiDetails.action_type;
    customConsole({
      ...apiDetails,
      params: {
        project_id: projectId,
      },
      show_toast: true,
    });
    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        params: {
          project_id: projectId,
        },
        show_toast: true,
      })
    );
    if (resp.error) {
      setProjectDetails({});
    } else {
      setProjectDetails(resp.data);
    }
  };
  // Function to get the user assigned projects
  const existingProjects = async () => {
    const apiDetails = labCar.get_project_details_pa;
    delete apiDetails.action_type;
    const resp = await dispatch(
      HandleApiActions({
        ...apiDetails,
        show_toast: false,
      })
    );
    console.log("--resp--");
    console.log(resp);
    if (!resp.error && resp.data && resp.data.length) {
      setAvailableProjects(resp.data);
    } else {
      setAvailableProjects([]);
    }
  };
  //Get the User Project on loading the component
  useEffect(() => {
    customConsole("-- Componented mounted --");
    // get the Projects of the user
    existingProjects();
  }, []);
  // On getting the project details
  useEffect(() => {
    customConsole(availableProjects);
    if (availableProjects.length) {
      setSelectedProject(availableProjects[0].project_id);
    } else {
      setSelectedProject(0);
    }
  }, [availableProjects]);
  // On changing the project -> hit the complete project details API
  useEffect(() => {
    if (selectedProject) {
      getProjectDetails(selectedProject);
    } else {
      setProjectDetails({});
    }
  }, [selectedProject]);
  const handleAccessButton = (params) => {
    customConsole("handleAccessButton: ");
    customConsole(params);
    setAccessControlData(params);
    setIsAccessDrawerOpened(true);
  };
  const EEAndSWMDetails = ({ details }) => {
    customConsole("-- EEAndSWMDetails --");
    customConsole(details);
    return projectDetails.ee_details.map((eD, idxE) => {
      return (details.sequence !== 0 &&
        eD?.zone_details?.sequence &&
        parseInt(eD?.zone_details?.sequence) === parseInt(details.sequence)) ||
        details.sequence === 0 ? (
        <Paper
          key={idxE}
          elevation={12}
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            padding: "10px",
            // border: "3px solid rgb(84, 13, 51)",
            borderRadius: "10px",
          }}
        >
          <Typography
            sx={{
              ...styles.boxHeaderText,
              color: "rgb(84, 13, 51)",
            }}
          >
            EE Component
          </Typography>
          {/* EE Component details */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                // border: "2px solid gray",
                borderRadius: "5px",
                padding: "2px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {[
                  {
                    key: "Description",
                    value: eD.description,
                  },
                  {
                    key: "Version",
                    value: eD.version,
                  },
                  {
                    key: "Supplier",
                    value: eD.supplier_name,
                  },
                  {
                    key: "Assembly Number",
                    value: eD.assembly_number,
                  },
                ].map((data, idA) => {
                  return (
                    <Box sx={styles.textBox} key={idA}>
                      <Typography sx={styles.headerText}>{data.key}</Typography>
                      <strong>:</strong>
                      <Typography sx={styles.dataText}>{data.value}</Typography>
                    </Box>
                  );
                })}
              </Box>
              <Button
                sx={{
                  ...styles.accessDetailsButton,
                  marginTop: "10px",
                  marginBottom: "10px",
                }}
                onClick={() =>
                  handleAccessButton({
                    access_type: accessTypes.EE,
                    project_id: projectDetails.project_id,
                    ee_id: eD.ee_id,
                  })
                }
              >
                Access Details &gt;&gt;
              </Button>
            </Box>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexWrap: "wrap",
              gap: 2,
              padding: "10px",
            }}
          >
            {/* Software Module details for the EE Component */}
            {projectDetails.swm_details.map((sD, idxS) => {
              return parseInt(sD.ee_id) === parseInt(eD.ee_id) ? (
                <Paper
                  key={idxS}
                  elevation={12}
                  sx={{
                    padding: "10px",
                    // border: "3px solid green",
                    borderRadius: "10px",
                  }}
                >
                  <Typography
                    sx={{
                      ...styles.boxHeaderText,
                      color: "green",
                    }}
                  >
                    Software Module
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      padding: "10px",
                    }}
                  >
                    {[
                      {
                        key: "Name",
                        value: sD.name,
                      },
                      {
                        key: "Version",
                        value: sD.version,
                      },
                      {
                        key: "Description",
                        value: sD.description,
                      },
                    ].map((data, idB) => {
                      return (
                        <Box sx={styles.textBox}key={idB}>
                          <Typography sx={styles.headerText}>
                            {data.key}
                          </Typography>
                          <strong>:</strong>
                          <Typography sx={styles.dataText}>
                            {data.value}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                  <Button
                    sx={{
                      ...styles.accessDetailsButton,
                      marginTop: "10px",
                      marginBottom: "10px",
                    }}
                    onClick={() =>
                      handleAccessButton({
                        access_type: accessTypes.SWM,
                        project_id: projectDetails.project_id,
                        ee_id: eD.ee_id,
                        swm_id: sD.swm_id,
                      })
                    }
                  >
                    Access Details &gt;&gt;
                  </Button>
                </Paper>
              ) : null;
            })}
          </Box>
        </Paper>
      ) : null;
    });
  };
  return (
    <Box sx={{ maxWidth: "95vW" }}>
      {availableProjects.length ? (
        <Box>
          {/* Project selection from the options */}
          <FormControl>
            <InputLabel
              sx={{
                fontSize: "16px",
                fontWeight: "bold",
                marginBottom: "10px",
              }}
            >
              Select Project
            </InputLabel>
            <Select
              variant="standard"
              value={selectedProject}
              onChange={(e) => {
                setSelectedProject(e.target.value);
              }}
              sx={{
                minWidth: "200px",
                textAlign: "start",
              }}
            >
              {availableProjects.map((data, idx) => {
                return (
                  <MenuItem
                    key={idx}
                    value={data.project_id}
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography sx={{ fontSize: "16px", fontWeight: "bold" }}>
                      {data.name}
                    </Typography>
                    <Typography sx={{ fontSize: "14px", fontWeight: "bold" }}>
                      {data.architecture_name}
                    </Typography>
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          {/* Complete Project Details */}
          {Object.keys(projectDetails).length ? (
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 2,
                padding: "10px",
              }}
            >
              {/* Project Details */}
              <Paper
                elevation={12}
                sx={{
                  padding: "10px",
                  // border: "3px solid black",
                  borderRadius: "10px",
                }}
              >
                <Typography sx={styles.boxHeaderText}>
                  Project details
                </Typography>
                <Box
                  sx={{
                    gap: "20px",
                    display: "flex",
                    flexDirection: { xs: "column", sm: "row" },
                    justifyContent: "space-evenly",
                  }}
                >
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {[
                      {
                        key: "Project ID",
                        value: projectDetails.project_id,
                      },
                      {
                        key: "Name",
                        value: projectDetails.name,
                      },
                      {
                        key: "Architecture",
                        value: projectDetails.architecture_name || "-",
                      },
                    ].map((data, idxP) => {
                      return (
                        <Box sx={styles.textBox} key={idxP}>
                          <Typography sx={styles.headerText}>
                            {data.key}
                          </Typography>
                          <strong>:</strong>
                          <Typography sx={styles.dataText}>
                            {data.value}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                  <Box sx={{ display: "flex", flexDirection: "column" }}>
                    {[
                      {
                        key: "Trademark",
                        value: projectDetails.trade_mark,
                      },
                      {
                        key: "Zones",
                        value: projectDetails.zones.length || "-",
                      },
                      {
                        key: "EE Components",
                        value: projectDetails.ee_ids.length,
                      },
                    ].map((data, idZ) => {
                      return (
                        <Box sx={styles.textBox} key={idZ}>
                          <Typography sx={styles.headerText}>
                            {data.key}
                          </Typography>
                          <strong>:</strong>
                          <Typography sx={styles.dataText}>
                            {data.value}
                          </Typography>
                        </Box>
                      );
                    })}
                  </Box>
                  <Button
                    sx={styles.accessDetailsButton}
                    onClick={() =>
                      handleAccessButton({
                        access_type: accessTypes.PROJECT,
                        project_id: projectDetails.project_id,
                      })
                    }
                  >
                    Access Details &gt;&gt;
                  </Button>
                </Box>
              </Paper>
              <Box
                sx={{
                  display: "flex",
                  flexWrap: "wrap",
                  gap: 2,
                  // padding: "10px",
                  // width: { xs: "95vW" },
                }}
              >
                {/* If Zones Exists map the EE Components Based on the Zones */}
                {projectDetails.zones.length ? (
                  projectDetails.zones.map((zD, idxZ) => {
                    return (
                      <Paper
                        key={idxZ}
                        elevation={12}
                        sx={{
                          padding: "10px",
                          // border: "3px solid rgb(27, 102, 161)",
                          borderRadius: "10px",
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              ...styles.boxHeaderText,
                              color: "rgb(27, 102, 161)",
                            }}
                          >
                            Zone details
                          </Typography>
                          <Typography
                            sx={{ fontWeight: "bold", fontSize: "16px" }}
                          >
                            {zD.position}&nbsp;:&nbsp;{zD.zone_name}
                          </Typography>
                        </Box>
                        {/* EE Components */}

                        <Box
                          sx={{
                            display: "flex",
                            flexWrap: "wrap",
                            gap: 2,
                            padding: "10px",
                          }}
                        >
                          <EEAndSWMDetails details={{ ...zD }} />
                        </Box>
                      </Paper>
                    );
                  })
                ) : (
                  //  EE Components
                  <Box
                    sx={{
                      display: "flex",
                      flexWrap: "wrap",
                      gap: 2,
                      padding: "10px",
                    }}
                  >
                    <EEAndSWMDetails details={{ sequence: 0 }} />
                  </Box>
                )}
                {/* Map the EE Components with the associated software modules */}
              </Box>
            </Box>
          ) : (
            <Typography
              sx={{ fontSize: "14px", fontWeight: "bold", color: "red" }}
            >
              Select Projects not found!
            </Typography>
          )}
        </Box>
      ) : (
        <Typography sx={{ fontSize: "14px", fontWeight: "bold", color: "red" }}>
          Projects not found!
        </Typography>
      )}
      {/* Access Control drawer - GRANT or REVOKE */}
      {isAccessDrawerOpened && (
        <ProjectAccessDrawer
          open={isAccessDrawerOpened}
          handleClose={handleCloseDrawer}
          data={accessControlData}
        />
      )}
    </Box>
  );
}

export default ProjectAccess;

const styles = {
  boxHeaderText: {
    fontWeight: "bold",
    fontSize: "18px",
    marginBottom: "5px",
    textAlign: "center",
  },
  textBox: {
    display: "flex",
    flexDirection: "row",
    gap: 2,
    alignItems: "center",
  },
  headerText: {
    fontWeight: "bold",
    fontSize: "14px",
    textAlign: "left",
    minWidth: "100px",
    color: "gray",
  },
  dataText: {
    fontSize: "14px",
    fontWeight: "bold",
    overflow: "auto",
  },
  accessDetailsButton: {
    backgroundImage:
      "linear-gradient( to bottom, rgb(7, 5, 69), rgb(103, 86, 133))",
    maxHeight: "30px",
    width: "fit-content",
    borderRadius: "20px",
    color: "white",
    "&:hover": {
      backgroundImage: "linear-gradient(#6b8df2,#021636)",
    },
  },
};
