/**
 * @author Tahir Shaik - tahir@bookwater.com - +91-9177327808
 * @date 11-06-2024
 * @description This file is to configure the single store of the Redux for the state management and Enable or disable the dev tools
 */

import { thunk } from "redux-thunk";
import { configureStore } from "@reduxjs/toolkit";
import RootReducer from "./reducers/RootReducer";

const store = configureStore({
  reducer: RootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(thunk), // Add thunk middleware
  devTools: process.env.REACT_APP_ENV === "DEV" ? true : false,
});
export default store;
