/**
 * @author Tahir Shaik - tahir@bookwater.com - +91-9177327808
 * @date 11-06-2024
 * @description This is the Virtual Labcar Page for the Application
 */
//Importing the required components/functions from the libraries
import { Box, Button, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SearchSelectTextField } from "../../components";
import VlcMindMap from "./VlcMindMap";
import VlcCarCard from "./VlcCarCard";
import { useNavigate } from "react-router-dom";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import VLcLinkSWModules from "./VLcLinkSWModules";
import labCar from "../../redux/actions/labCar";
import customConsole from "../../config/customConsole";
import VlcEEComponents from "./VlcEEComponents";
import VlcZonalMindMap from "./VlcZonalMindMap";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 19-07-2024
 * @description
 * @param {}
 * @return {}
 */
function LabCar() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  /* useSelectors */
  const { projectDetails } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );

  /* Project */
  const [projectOptionsList, setProjectOptionsList] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [selectedProjectDetails, setSelectedProjectDetails] = useState(null);

  /* */
  const [showNoProjectsFound, setShowNoProjectsFound] = useState(false);

  /* useEffects */

  useEffect(() => {
    dispatch(HandleApiActions({ ...labCar.get_project_details }));
  }, []);

  useEffect(() => {
    /* One second delay for the show no project */
    if (projectDetails.length === 0) {
      const timer = setTimeout(() => {
        setShowNoProjectsFound(true);
      }, 1000);
      // Clean up the timer if the component unmounts
      return () => clearTimeout(timer);
    } else {
      setShowNoProjectsFound(false);
    }
  }, []);

  useEffect(() => {
    /* Project */
    if (projectDetails) {
      let filteredProjectOptionsList = projectDetails?.map((el) => {
        return {
          value: el?.project_id,
          label: el.name,
        };
      });
      setProjectOptionsList(filteredProjectOptionsList);

      /* Select first project by default */
      if (projectDetails[0]?.project_id && projectDetails[0]?.name) {
        setSelectedProject({
          value: projectDetails[0]?.project_id,
          label: projectDetails[0]?.name,
        });
      }
    }
  }, [projectDetails]);

  useEffect(() => {
    /* Selected project */
    if (selectedProject) {
      let filteredSelectedProjectDetails = projectDetails?.find(
        (el) => el?.project_id == selectedProject?.value
      );

      setSelectedProjectDetails(filteredSelectedProjectDetails);
    }
  }, [selectedProject]);

  useEffect(() => {
    console.log("projectDetails:", projectDetails);
    console.log("selectedProject:", selectedProject);
    console.log("selectedProjectDetails:", selectedProjectDetails);
  }, [selectedProjectDetails]);

  return (
    <Box>
      {projectDetails.length > 0 ? (
        <LabCarMain
          projectOptionsList={projectOptionsList}
          selectedProject={selectedProject}
          setSelectedProject={setSelectedProject}
          selectedProjectDetails={selectedProjectDetails}
        />
      ) : (
        showNoProjectsFound && (
          <Box
            sx={{
              mt: 12,
            }}
          >
            <Typography
              variant="h6"
              // color="primary"
              sx={{
                mb: 1,
              }}
            >
              No Projects Found
            </Typography>
            {/* <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={() => navigate("/dashboard/project-config")}
            >
              Create Project
            </Button> */}
          </Box>
        )
      )}
    </Box>
  );
}

export default LabCar;

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 23-07-2024
 * @description
 * @param {}
 * @return {}
 */
function LabCarMain({
  projectOptionsList,
  selectedProject,
  setSelectedProject,
  selectedProjectDetails,
}) {
  /* */
  const dispatch = useDispatch();

  /*  */
  const { eeComponentsList } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );

  /* */
  const [isLinkSoftwareModulesScreen, setIsLinkSoftwareModulesScreen] =
    useState(false);

  /* Car Card */
  const [selectedView, setSelectedView] = useState("CAR");

  /* Zones */
  // const [zonesOptionsList, setZonesOptionsList] = useState([]);
  const [availableZones, setAvailableZones] = useState([]);
  const [selectedZone, setSelectedZone] = useState(null);
  const [selectedZoneDetails, setSelectedZoneDetails] = useState(null);
  // const [selectedZoneEE, setSelectedZoneEE] = useState(null);
  // const [selectedZoneEEDetails, setSelectedZoneEEDetails] = useState(null);

  /* Zone - EE */
  const [zoneEEList, setZoneEEList] = useState([]);
  /* Centralized - EE */
  const [centralizedEEList, setCentralizedEEList] = useState([]);

  /* useEffects */
  useEffect(() => {
    /* Make to default state when the project is changed */
    setIsLinkSoftwareModulesScreen(false);
    // setSelectedView("CAR");
    setAvailableZones([]);
    setSelectedZone(null);
    setSelectedZoneDetails(null);
    setZoneEEList([]);
    setCentralizedEEList([]);
  }, [selectedProjectDetails]);

  useEffect(() => {
    /* Zones */
    if (selectedProjectDetails && selectedProjectDetails?.is_zonal) {
      // let filteredZonesOptionsList = selectedProjectDetails?.zones?.map(
      //   (el) => {
      //     return {
      //       value: el.position,
      //       label: el.zone_name,
      //     };
      //   }
      // );
      // setZonesOptionsList(filteredZonesOptionsList);

      let filteredAvailableZones = selectedProjectDetails?.zones?.map((el) => {
        return {
          zone_position: el.position,
          position_name: el.zone_name,
        };
      });
      setAvailableZones(filteredAvailableZones);
    }
  }, [selectedProjectDetails]);

  useEffect(() => {
    if (
      selectedZone &&
      selectedZone?.zone_position_name &&
      selectedProjectDetails &&
      selectedProjectDetails?.zones
    ) {
      let filteredSelectedZoneDetails = selectedProjectDetails?.zones?.find(
        (el) => el.zone_name == selectedZone?.zone_position_name
      );
      setSelectedZoneDetails(filteredSelectedZoneDetails);
    }
  }, [selectedZone]);

  const getEEComponentsList = () => {
    if (
      (selectedZoneDetails &&
        selectedZoneDetails.sequence &&
        selectedProjectDetails &&
        selectedProjectDetails?.project_id) ||
      (selectedProjectDetails &&
        selectedProjectDetails?.project_id &&
        !selectedProjectDetails.is_zonal)
    ) {
      dispatch(
        HandleApiActions({
          ...labCar.get_ee_components,
          params: {
            project_id: selectedProjectDetails?.project_id,
            ...(selectedProjectDetails.is_zonal && {
              zone_sequence: selectedZoneDetails.sequence,
            }),
          },
          show_toast: false,
        })
      );
    }
  };

  useEffect(() => {
    getEEComponentsList();
  }, [selectedZoneDetails, selectedProjectDetails]);

  useEffect(() => {
    if (eeComponentsList) {
      if (selectedProjectDetails && selectedProjectDetails.is_zonal) {
        setZoneEEList(eeComponentsList);
      } else {
        setCentralizedEEList(eeComponentsList);
      }
    }
  }, [eeComponentsList]);

  useEffect(() => {
    console.log("selectedZoneDetails:", selectedZoneDetails);
    console.log("selectedZone:", selectedZone);
    console.log("selectedProjectDetails:", selectedProjectDetails);
    console.log("zoneEEList", zoneEEList);
  }, [selectedZoneDetails, zoneEEList]);

  /* -------------- Handle Zone EE functions -------------- */
  const handleZoneEEAdd = ({
    assembly_number,
    supplier_name,
    version,
    summary,
    description,
  }) => {
    if (
      (selectedZoneDetails &&
        selectedZoneDetails.sequence &&
        selectedProjectDetails &&
        selectedProjectDetails?.project_id) ||
      (selectedProjectDetails &&
        selectedProjectDetails?.project_id &&
        !selectedProjectDetails.is_zonal)
    ) {
      let currentTime = new Date().toISOString();

      customConsole({
        project_id: Number(selectedProjectDetails?.project_id),
        ...(selectedProjectDetails.is_zonal && {
          zone_sequence: selectedZoneDetails.sequence,
        }),
        assembly_number,
        supplier_name,
        version,
        ...(summary && { summary }),
        description,
        current_time: currentTime,
      });

      dispatch(
        HandleApiActions({
          ...labCar.add_ee_component,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            ...(selectedProjectDetails.is_zonal && {
              zone_sequence: selectedZoneDetails.sequence,
            }),
            assembly_number,
            supplier_name,
            version,
            ...(summary && { summary }),
            description,
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        getEEComponentsList();
      });

      // setTimeout(getEEComponentsList, 700);
    }
  };

  const handleZoneEEUpdate = ({ ee_id, version }) => {
    if (selectedProjectDetails && selectedProjectDetails?.project_id) {
      let currentTime = new Date().toISOString();

      customConsole({
        project_id: Number(selectedProjectDetails?.project_id),
        ee_id,
        version,
        current_time: currentTime,
      });

      dispatch(
        HandleApiActions({
          ...labCar.update_ee_component,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            ee_id,
            version,
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        getEEComponentsList();
      });

      // setTimeout(getEEComponentsList, 700);
    }
  };

  const handleZoneEEReplace = ({
    ee_id,
    assembly_number,
    supplier_name,
    version,
    summary,
    description,
  }) => {
    if (selectedProjectDetails && selectedProjectDetails?.project_id) {
      let currentTime = new Date().toISOString();

      customConsole({
        project_id: Number(selectedProjectDetails?.project_id),
        ee_id,
        assembly_number,
        supplier_name,
        version,
        ...(summary && { summary }),
        description,
        current_time: currentTime,
      });

      dispatch(
        HandleApiActions({
          ...labCar.replace_ee_component,
          params: {
            project_id: Number(selectedProjectDetails?.project_id),
            ee_id,
            assembly_number,
            supplier_name,
            version,
            ...(summary && { summary }),
            description,
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        getEEComponentsList();
      });

      // setTimeout(getEEComponentsList, 700);
    }
  };

  return (
    <Box sx={{ overflow: "auto" }}>
      <Box
        sx={{
          mt: 1,
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: { xs: "center", sm: "center" },
        }}
      >
        {!isLinkSoftwareModulesScreen && (
          <Box sx={{ textAlign: "start" }}>
          <SearchSelectTextField
            size={"small"}
            label={"Search Project"}
            placeholder={"Search Project"}
            noOptionsText={"No project found"}
            optionsList={projectOptionsList}
            selectedValue={selectedProject}
            setSelectedValue={setSelectedProject}
            txtFldStyle={{
              width: "215px",
              backgroundColor: "white",
            }}
          />
          </Box>
        )}
        {selectedProject &&
          selectedView == "CAR" &&
          !isLinkSoftwareModulesScreen && (
            <Box sx={{ textAlign: "center" }}>
              <Button
                sx={{ mt: { xs: 2, sm: 0 }, ml: { sm: 4 }, width: "215px" }}
                // size="small"
                color="primary"
                variant="contained"
                onClick={() => setIsLinkSoftwareModulesScreen(true)}
                // disabled={
                //   !(
                //     selectedProjectDetails &&
                //     selectedProjectDetails?.ee_ids?.length > 1
                //   )
                // }
              >
                Link Software Modules
              </Button>
            </Box>
          )}
      </Box>

      {selectedProject && (
        <Box>
          {selectedProjectDetails &&
          selectedProjectDetails?.project_id &&
          selectedProjectDetails?.zones &&
          selectedProjectDetails?.name &&
          selectedProjectDetails?.architecture_name &&
          selectedProjectDetails?.trade_mark &&
          isLinkSoftwareModulesScreen ? (
            <VLcLinkSWModules
              selectedProjectId={selectedProjectDetails?.project_id}
              selectedProjectIsZonal={selectedProjectDetails?.is_zonal}
              selectedProjectZonesList={selectedProjectDetails?.zones}
              selectedProjectName={selectedProjectDetails?.name}
              selectedProjectArchitecture={
                selectedProjectDetails?.architecture_name
              }
              selectedProjectTradeMark={selectedProjectDetails?.trade_mark}
              isLinkSoftwareModulesScreen={isLinkSoftwareModulesScreen}
              setIsLinkSoftwareModulesScreen={setIsLinkSoftwareModulesScreen}
            />
          ) : (
            <Box
              sx={{
                margin: "20px",
                marginBottom: "100px",
                display: "flex",
                flexDirection: "column",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  gap: "10px",
                  marginBottom: "7px",
                }}
              >
                <Typography
                  sx={{
                    flex: 1,
                    textAlign: "center",
                    // fontWeight: "bold",
                  }}
                  variant="h6"
                >
                  <span style={{ fontWeight: "bold" }}>Project: </span>
                  {selectedProjectDetails?.name}
                </Typography>
                <Button
                  sx={{
                    color: selectedView === "CAR" ? "white" : "black",
                    border: "1px solid black",
                    fontWeight: "bold",
                    backgroundImage:
                      selectedView === "CAR"
                        ? "linear-gradient(to bottom, blue, gray)"
                        : "",
                  }}
                  onClick={() => setSelectedView("CAR")}
                >
                  CAR VIEW
                </Button>
                <Button
                  sx={{
                    color: selectedView === "MAP" ? "white" : "black",
                    border: "1px solid black",
                    fontWeight: "bold",
                    backgroundImage:
                      selectedView === "MAP"
                        ? "linear-gradient(to bottom, blue, gray)"
                        : "",
                  }}
                  onClick={() => setSelectedView("MAP")}
                >
                  MIND MAP
                </Button>
              </Box>
              {selectedView === "CAR" ? (
                <Box
                  sx={{
                    width: "100%",
                    display: "flex",
                    flexDirection: { xs: "column", lg: "row" },
                    justifyContent: { xs: "center", lg: "space-evenly" },
                    alignItems: { xs: "center", lg: "space-evenly" },
                    overflowX: "auto",
                    px: 2,
                    pb: 4,
                  }}
                >
                  {/* <Box sx={{ width: { xs: "100%", lg: "40%" } }}> */}
                  <Box sx={{ width: "520px" }}>
                    <VlcCarCard
                      availableZones={availableZones}
                      selectedComponent={selectedZone}
                      setSelectedComponent={setSelectedZone}
                    />
                  </Box>

                  {((selectedZone && selectedZoneDetails?.zone_name) ||
                    (selectedProjectDetails &&
                      !selectedProjectDetails.is_zonal)) && (
                    <Box
                      sx={{
                        // width: { xs: "100%", lg: "60%" },
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      <Box>
                        <VlcEEComponents
                          heading={
                            selectedProjectDetails.is_zonal
                              ? selectedZoneDetails?.zone_name
                              : "Centralized"
                            // : // : "EE Components"
                          }
                          eEComponentsList={
                            selectedProjectDetails.is_zonal
                              ? zoneEEList
                              : centralizedEEList
                          }
                          handleEEComponentAdd={handleZoneEEAdd}
                          handleEEComponentUpdate={handleZoneEEUpdate}
                          handleEEComponentReplace={handleZoneEEReplace}
                          selectedProjectDetails={selectedProjectDetails}
                          selectedZone={selectedZone}
                        />
                      </Box>
                    </Box>
                  )}
                </Box>
              ) : (
                <>
                  {selectedProjectDetails &&
                  selectedProjectDetails?.project_id &&
                  !selectedProjectDetails.is_zonal ? (
                    <VlcMindMap
                      selectedProjectId={selectedProjectDetails?.project_id}
                    />
                  ) : (
                    <VlcZonalMindMap
                      selectedProjectId={selectedProjectDetails?.project_id}
                    />
                  )}
                </>
              )}
            </Box>
          )}
        </Box>
      )}
    </Box>
  );
}
