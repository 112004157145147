import {
  AppBar,
  Box,
  Button,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  List,
  ListItemButton,
  ListItemText,
  Paper,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  tableCellClasses,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Typography,
} from "@mui/material";
import UpdateIcon from "@mui/icons-material/Update";
import DeleteIcon from "@mui/icons-material/Delete";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import SearchTextField from "../../components/text-fields/SearchTextField";
import CustomButton from "../../components/buttons/CustomButton";
import CustomTextField1 from "../../components/text-fields/CustomTextField1";
import customConsole from "../../config/customConsole";
import CloseIcon from "@mui/icons-material/Close";
import CustomTextField2 from "../../components/text-fields/CustomTextField2";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import profileActions from "../../redux/actions/profile";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import adminSettingsAPI from "../../redux/actions/adminSetttingAPI";
import moment from "moment";
import { AddCircleOutline, DeleteOutline } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import { convertUtcToTimeZone } from "../../utils/convertUtcToTimeZone";
import CustomAddDialog from "../../components/dialog-boxes/CustomAddDialog";
import CustomUpdateDialog from "../../components/dialog-boxes/CustomUpdateDialog";
import CustomConfirmationDialog from "../../components/dialog-boxes/CustomConfirmationDialog";

function PowerTrainClass({ open, handleClick }) {
  const dispatch = useDispatch();
  const powerTrainClassDetails = useSelector(
    (state) => state.powerTrainClassReducer
  );
  const timeZoneDetails = useSelector((state) => state.profileReducer);

  const [page, setPage] = React.useState(0);
  const [tableRowsData, setTableRowsData] = useState([]);
  const [rowsPerPage, setRowsPerPage] = React.useState(25);
  const [getPowerTrainClass, setGetPowerTrainClass] = React.useState([]);
  const [openAddPowerDialog, setOpenAddPowerDialog] = React.useState(false);
  const [openEditPowerDialog, setOpenEditPowerDialog] = React.useState(false);
  const [selectedRow, setSelectedRow] = React.useState(null);
  const [openDeletePowerDialog, setOpenDeletePowerDialog] =
    React.useState(false);
  const [timeZone, setTimeZone] = React.useState([]);

  const columns = [
    { field: "sno", headerName: "S.No", width: 100 },
    {
      field: "abbreviation",
      headerName: "Abbreviation",
      width: 150,
    },
    { field: "name", headerName: "Name", width: 150 },
    {
      field: "description",
      headerName: "Description",
      width: 300,
    },
    {
      field: "created_at",
      headerName: "Created",
      width: 200,
    },
    {
      field: "updated_at",
      headerName: "Updated",
      width: 200,
    },
    {
      field: "actions",
      headerName: "Actions",
      width: 150,
      renderCell: (params) => (
        <IconButton
          size="small"
          color="error"
          onClick={(e) => {
            e.stopPropagation();
            handleTableRowClick(params, true);
          }}
        >
          <DeleteIcon />
        </IconButton>
      ),
    },
  ];

  useEffect(() => {
    let filteredUsersList = getPowerTrainClass?.map((data, index) => {
      return {
        ...data,
        sno: index + 1,
        id: data?.id,
        abbreviation: data?.abbreviation,
        name: data?.name,
        description: data?.other_fields?.description,
        created_at: convertUtcToTimeZone(
          data?.created_at,
          timeZoneDetails?.profile?.profile?.region
        ),
        updated_at: convertUtcToTimeZone(data.updated_at, timeZone),
      };
    });

    setTableRowsData(filteredUsersList);
  }, [getPowerTrainClass]);
  // Toggles the state of the dialog.
  const toggleDialog = (newOpen) => () => {
    setOpenAddPowerDialog(newOpen);
  };

  // Handles the change of page in pagination.
  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  // Handles the change of rows per page in pagination.
  const handleRowsPerPageChange = (e) => {
    console.log(e.target.value);
    let newValue = +e.target.value;
    setRowsPerPage(newValue);
    setPage(0);
  };

  const handleAddPowerTrainClick = () => {
    toggleDialog(true)();
  };

  const handleTableRowClick = (params, isButtonClicked) => {
    setSelectedRow(params);
    console.log("Row Selected");
    console.log(params);
    if (isButtonClicked) {
      setOpenDeletePowerDialog(true);
    } else {
      setOpenEditPowerDialog(true);
    }
  };

  React.useEffect(() => {
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.get_powertrain_class,
        show_toast: false,
      })
    );
    dispatch(HandleApiActions({ ...profileActions.get_coutry_regions }));
  }, []);

  React.useEffect(() => {
    console.log("timeZoneDetails?.data?.profile?.region");
    console.log(timeZoneDetails?.profile?.profile?.region);
    setTimeZone(timeZoneDetails?.profile?.profile?.region);
  }, [timeZoneDetails]);

  React.useEffect(() => {
    console.log("Power Train Details");
    customConsole(powerTrainClassDetails);

    setGetPowerTrainClass(
      powerTrainClassDetails?.powerTrainClassDetails?.data?.length
        ? powerTrainClassDetails?.powerTrainClassDetails?.data
        : []
    );
  }, [powerTrainClassDetails]);

  return (
    <div>
      <List
        sx={{
          width: "100%",
          maxWidth: "100%",
          marginTop: "15px",
          backgroundColor: "whitesmoke",
          boxShadow: "10px 10px 30px rgba(0, 0, 0, 0.2)",
          borderRadius: " 0px",
        }}
        component="nav"
        aria-labelledby="nested-list-subheader"
      >
        <ListItemButton onClick={() => handleClick("3")}>
          <ListItemText
            sx={{
              textAlign: "start",

              color: "black",
            }}
            primary="Powertrain Classes"
            primaryTypographyProps={{
              fontWeight: 600,
              fontSize: "18px",
            }}
          />
          {open === "3" ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>

        <Collapse in={open === "3"} timeout="auto" unmountOnExit>
          <Divider sx={{ margin: "5px" }} />
          <List
            component="div"
            disablePadding
            sx={{
              background: "whitesmoke",
              margin: "5px",
              padding: "10px",
              borderRadius: "10px",
              //overflowY: "auto",
              maxHeight: "500px",
            }}
          >
            <Box>
              <Box sx={styles.tblFun}>
                <CustomButton
                  size={"medium "}
                  variant={"contained"}
                  btnName={"Add "}
                  startIcon={<AddIcon />}
                  handleOnClick={handleAddPowerTrainClick}
                  btnStyle={{
                    ml: 2,
                    // borderRadius: "0px",
                  }}
                />
              </Box>
              <Paper sx={styles.container}>
                <div style={{ height: 300, width: "100%" }}>
                  <DataGrid
                    sx={{
                      "& .MuiDataGrid-scrollbar.MuiDataGrid-scrollbar--horizontal.css-1rtad1":
                        {
                          display: "block",
                        },
                      "& .MuiDataGrid-columnHeaders": {
                        "& .MuiDataGrid-columnHeaderTitle": {
                          fontWeight: "bold",
                        },
                      },
                    }}
                    localeText={{ toolbarDensity: "View" }}
                    autoHeight={true}
                    rows={tableRowsData}
                    columns={columns}
                    disableColumnMenu
                    disableColumnSelector
                    disableRowSelectionOnClick
                    slots={{ toolbar: GridToolbar }}
                    pageSize={rowsPerPage}
                    rowCount={
                      tableRowsData.length >= rowsPerPage
                        ? -1
                        : page * rowsPerPage + tableRowsData.length
                    }
                    onPaginationModelChange={(model) => {
                      setPage(model.page);
                      setRowsPerPage(model.pageSize);
                    }}
                    paginationModel={{ page: page, pageSize: rowsPerPage }}
                    pageSizeOptions={[25, 50, 100]}
                    slotProps={{
                      toolbar: { printOptions: { disableToolbarButton: true } },
                    }}
                    onRowClick={(params) => {
                      handleTableRowClick(params, false);
                    }}
                  />
                </div>

                <AddPowerTrainClass
                  open={openAddPowerDialog}
                  toggleDialog={toggleDialog}
                />
                <EditPowerTrainClass
                  open={openEditPowerDialog}
                  setOpenEditPowerDialog={setOpenEditPowerDialog}
                  selectedRow={selectedRow}
                />

                <DeletePowerTrainClass
                  openDeletePowerDialog={openDeletePowerDialog}
                  setOpenDeletePowerDialog={setOpenDeletePowerDialog}
                  selectedRow={selectedRow}
                />
              </Paper>
            </Box>
          </List>
        </Collapse>
      </List>
    </div>
  );
}

export default PowerTrainClass;

// Styling for the Users component
const styles = {
  container: {
    width: "100%",
    overflow: "auto",
  },
  tblFun: {
    mt: 2,
    mb: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};

const AddPowerTrainClass = ({ open, toggleDialog }) => {
  const dispatch = useDispatch();

  const [name, setName] = useState("");
  const [abbreviation, setAbbreviation] = useState("");
  const [description, setDescription] = useState("");
  const [nameError, setNameError] = useState(false);
  const [abbreviationError, setAbbreviationError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);

  const handleClose = () => {
    toggleDialog(false)();
    setName("");
    setAbbreviation("");
    setDescription("");
  };

  const handleClickAdd = () => {
    const reqObj = {
      current_time: new Date(),
      abbreviation: abbreviation,
      name: name,
    };

    if (description?.trim()) {
      reqObj.description = description;
    }
    // const reqObj = {
    //   current_time: new Date(),
    //   abbreviation: abbreviation,
    //   name: name,
    //   description: description,
    // };
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.add_powertrain_class,
        show_toast: true,
        params: reqObj,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...adminSettingsAPI.get_powertrain_class,
          show_toast: false,
        })
      );
    });
    toggleDialog(false)();
    setName("");
    setDescription("");
    setAbbreviation("");
  };
  // React.useEffect(() => {
  //   handleValidation();
  // }, [name, abbreviation, description]);

  return (
    <>
      <CustomAddDialog
        open={open}
        onClose={handleClose}
        dialogTitle={"Add Powertrain Class"}
        dialogContent={
          <Stack spacing={2}>
            <CustomTextField2
              size="small"
              label="Name"
              placeholder="Name"
              value={name}
              setValue={setName}
              txtFldStyle={{ width: "270px" }}
              validationRules={{ minLength: 3 }}
              setError={setNameError}
              error={nameError !== ""}
            />
            <CustomTextField2
              size="small"
              label="Abbreviation"
              placeholder="Abbreviation"
              value={abbreviation}
              setValue={setAbbreviation}
              txtFldStyle={{ width: "270px" }}
              validationRules={{ minLength: 2 }}
              setError={setAbbreviationError}
              error={abbreviationError !== ""}
            />
            <CustomTextField2
              size="small"
              label="Description"
              placeholder="Description"
              value={description}
              setValue={setDescription}
              txtFldStyle={{ width: "270px" }}
              validationRules={{ minLength: 5 }}
              setError={setDescriptionError}
              error={descriptionError !== ""}
            />
          </Stack>
        }
        onConfirm={handleClickAdd}
        onDisabled={
          name?.length > 2 &&
          name?.trim() !== "" &&
          abbreviation?.length > 1 &&
          abbreviation?.trim() !== ""
            ? false
            : true
        }
      />
    </>
  );
};

const EditPowerTrainClass = ({ open, setOpenEditPowerDialog, selectedRow }) => {
  const dispatch = useDispatch();
  console.log("selectedRow");

  console.log(selectedRow);

  const [editName, setEditName] = useState("");
  const [editAbbreviation, setEditAbbreviation] = useState("");
  const [editDescription, setEditDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState(false);

  React.useEffect(() => {
    if (selectedRow) {
      setEditName(selectedRow?.row?.name || "");
      setEditAbbreviation(selectedRow?.row?.abbreviation || "");
      setEditDescription(selectedRow?.row?.other_fields?.description || "");
    }
  }, [selectedRow]);
  React.useEffect(() => {
    console.log("selectedRow");
    console.log(selectedRow);
    console.log("name");
    console.log(selectedRow?.name);
  }, [selectedRow]);

  const handleClose = () => {
    setOpenEditPowerDialog(false);
  };

  const handleClickEdit = () => {
    const reqObj = {
      current_time: new Date().toISOString(),
      id: selectedRow?.row?.id,
      description: editDescription,
    };
    console.log("reqObjhhhhhhhhhhhh");
    console.log(reqObj);
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.update_powertrain_class,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(HandleApiActions({ ...adminSettingsAPI.get_powertrain_class }));
    });
    setOpenEditPowerDialog(false);
  };

  return (
    <>
      <CustomUpdateDialog
        open={open}
        onClose={handleClose}
        dialogTitle={"Edit Powertrain Class"}
        dialogContent={
          <Stack spacing={2}>
            <CustomTextField1
              size={"small"}
              label={"Name"}
              placeholder={"Name"}
              value={editName}
              setValue={setEditName}
              txtFldStyle={{
                width: "270px",
              }}
              disabled={true}
            />
            <CustomTextField1
              size={"small"}
              label={"Abbreviation"}
              placeholder={"Abbreviation"}
              value={editAbbreviation}
              setValue={setEditAbbreviation}
              txtFldStyle={{
                width: "270px",
              }}
              disabled={true}
            />
            <CustomTextField2
              size={"small"}
              label={"Description"}
              placeholder={"Description"}
              value={editDescription}
              setValue={setEditDescription}
              txtFldStyle={{
                width: "270px",
              }}
              validationRules={{ minLength: 5 }}
              setError={setDescriptionError}
              error={descriptionError !== ""}
            />
          </Stack>
        }
        onConfirm={handleClickEdit}
        onDisabled={
          editDescription.length > 5 && editDescription?.trim() !== ""
            ? false
            : true
        }
      />
    </>
  );
};

const DeletePowerTrainClass = ({
  openDeletePowerDialog,
  setOpenDeletePowerDialog,
  selectedRow,
}) => {
  const dispatch = useDispatch();
  const handleDelete = () => {
    const reqObj = {
      current_time: new Date(),
      id: selectedRow?.row?.id,
    };
    console.log("reqObj");
    console.log(reqObj);
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.delete_powertrain_class,
        show_toast: true,
        params: reqObj,
      })
    ).then(() => {
      setTimeout(() => {
        dispatch(
          HandleApiActions({
            ...adminSettingsAPI.get_powertrain_class,
            show_toast: false,
          })
        );
      }, 500);
    });
    setOpenDeletePowerDialog(false);
  };
  return (
    <CustomConfirmationDialog
      open={openDeletePowerDialog}
      onClose={() => setOpenDeletePowerDialog(false)}
      dialogTitle={"Confirm Delete"}
      dialogContent={
        <DialogContentText>Are you sure want to delete?</DialogContentText>
      }
      onConfirm={handleDelete}
    />
  );
};
