/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 17-06-2024
 * @description Reusable button component.
 */

import { Button } from "@mui/material";
import React from "react";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 17-06-2024
 * @description Reusable button component.
 * @param { size, variant, btnName, handleOnClick, btnStyle }
 * @return The rendered button component.
 */
function CustomButton({
  endIcon,
  startIcon,
  size,
  variant,
  btnName,
  handleOnClick,
  disabled = false,
  btnStyle,
}) {
  return (
    <Button
      size={size}
      variant={variant}
      onClick={handleOnClick}
      disabled={disabled}
      sx={btnStyle}
      startIcon={startIcon}
      endIcon={endIcon}
    >
      {btnName}
    </Button>
  );
}

export default CustomButton;
