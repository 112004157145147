import moment from "moment";
import "moment-timezone";

/**
 * @author : Tahir Shaik - tahir@au79consulting.com
 * @Date : 31-07-2024
 * @description Function to validate and return the proper date and time
 */
export const convertUtcToTimeZone = (utcTimestamp, timeZone) => {
  if (utcTimestamp) {
    const TZ =
      timeZone && timeZone.toLowerCase() !== "string"
        ? timeZone
        : "asia/kolkata";
    console.log(TZ);
    return moment(utcTimestamp).tz(TZ).format("DD-MM-YYYY hh:mm:ss A");
  } else {
    return "-";
  }
};
