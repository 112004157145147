/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 24-07-2024
 * @description
 */
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Stack,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddIcon from "@mui/icons-material/Add";
import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { TbReplace } from "react-icons/tb";
import {
  CustomAddDialog,
  CustomReplaceDialog,
  CustomTextFieldN,
  CustomUpdateDialog,
} from "../../components";
import MultiSelectTextFieldN from "../../components/text-fields/MultiSelectTextFieldN";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 24-07-2024
 * @description
 * @param {}
 * @return {}
 */
function VlcSWModules({
  heading,
  sWModulesList,
  handleSWModuleAdd,
  handleSWModuleUpdate,
  handleSWModuleReplace,
  setIsEEComponentSelected,
  setSelectedEEComponentDetails,
  selectedProjectDetails,
}) {
  /* -------------- Dialogs -------------- */
  const [openSWModuleAddDialog, setOpenSWModuleAddDialog] = useState(false);
  const [openSWModuleUpdateDialog, setOpenSWModuleUpdateDialog] =
    useState(false);
  const [openSWModuleReplaceDialog, setOpenSWModuleReplaceDialog] =
    useState(false);

  /* -------------- Selected sw module update/replace details  -------------- */
  const [selectedSWModuleUpdateDetails, setSelectedSWModuleUpdateDetails] =
    useState(null);
  const [selectedSWModuleReplaceDetails, setSelectedSWModuleReplaceDetails] =
    useState(null);

  /* -------------- Vehicle Functions-------------- */
  const [vfOptionsList, setVfOptionsList] = useState([]);

  /* -------------- Handle update/replace functions -------------- */
  const handleSelectedSWModuleUpdateDetails = (sWModule) => {
    setOpenSWModuleUpdateDialog(true);

    if (sWModule) {
      setSelectedSWModuleUpdateDetails(sWModule);
    }
  };

  const handleSelectedSWModuleReplaceDetails = (sWModule) => {
    setOpenSWModuleReplaceDialog(true);

    if (sWModule) {
      setSelectedSWModuleReplaceDetails(sWModule);
    }
  };

  /* -------------- useEffects -------------- */
  useEffect(() => {
    if (
      selectedProjectDetails &&
      selectedProjectDetails?.vehicle_function_details
    ) {
      setVfOptionsList(selectedProjectDetails?.vehicle_function_details || []);
    }
  }, [selectedProjectDetails]);

  useEffect(() => {
    console.log(
      "selectedSWModuleUpdateDetails:",
      selectedSWModuleUpdateDetails
    );
    console.log(
      "selectedSWModuleReplaceDetails:",
      selectedSWModuleReplaceDetails
    );
  }, [selectedSWModuleUpdateDetails, selectedSWModuleReplaceDetails]);

  return (
    <>
      <Card
        sx={{
          mt: 4,
          mx: 2,
          width: "370px",
          boxShadow: 12,
        }}
      >
        <CardHeader
          sx={{
            py: 1,
            backgroundColor: "lightgrey",
            borderBottom: "1px solid lightgrey",
          }}
          title={
            <>
            <Typography
              textAlign={"start"}
              fontWeight={600}
              variant="subtitle1"
            >
              {heading}
            </Typography>
              <Typography
                textAlign={"start"}
                // variant="body2"
                fontSize="13px"
                color="textSecondary"
              >
                {"Software Modules"}
              </Typography>
            </>
          }
          action={
            <Box
              sx={{
                pt: 1.5,
              }}
            >
            <Button
              startIcon={<AddIcon />}
              color="primary"
              size="small"
              variant="contained"
              onClick={() => setOpenSWModuleAddDialog(true)}
            >
              Add
            </Button>
            </Box>
          }
        />
        <CardContent
          sx={{
            height: "340px",
            overflowY: "auto",
          }}
        >
          {sWModulesList && sWModulesList?.length > 0 ? (
            sWModulesList?.map((item, index) => (
              <Box
                key={index}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  mb: 1.5,
                  pl: 1.4,
                  pr: 1,
                  backgroundColor: "background.paper",
                  borderRadius: 1,
                  // boxShadow: 2,
                  boxShadow: 4,
                  border: "1px solid lightgrey",
                  // cursor: "pointer",
                  "&:hover": {
                    // backgroundColor: "lightgrey",
                  },
                }}
              >
                <Box sx={{ flexGrow: 1, textAlign: "start", my: 1 }}>
                  <Typography variant="body2">
                    <strong>Name:</strong> {item?.name}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Description:</strong> {item?.description}
                  </Typography>
                  <Typography variant="body2">
                    <strong>Version:</strong> {item?.version}
                  </Typography>
                </Box>
                <Box
                // sx={{
                //   display: "flex",
                //   flexDirection: "column",
                //   alignItems: "center",
                //   justifyContent: "center",
                //   gap: 1,
                // }}
                >
                  <Tooltip title="Update">
                  <IconButton
                    size="small"
                    color="info"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelectedSWModuleUpdateDetails(item);
                    }}
                  >
                    <EditIcon />
                  </IconButton>
                  </Tooltip>
                  <Tooltip title="Replace">
                  <IconButton
                    size="small"
                    color="info"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleSelectedSWModuleReplaceDetails(item);
                    }}
                  >
                    <TbReplace style={{ fontSize: "22px" }} />
                  </IconButton>
                  </Tooltip>
                </Box>
              </Box>
            ))
          ) : (
            <Typography variant="body1" sx={{ textAlign: "center", mt: 2 }}>
              No data available
            </Typography>
          )}
        </CardContent>
        <CardActions
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            borderTop: "1px solid lightgrey",
          }}
        >
          <Button
            startIcon={<CloseIcon />}
            color="error"
            size="small"
            variant="contained"
            onClick={() => {
              setIsEEComponentSelected(false);
              setSelectedEEComponentDetails(null);
            }}
          >
            Cancel
          </Button>
        </CardActions>
      </Card>

      <VlcSWModuleAddDialog
        openSWModuleAddDialog={openSWModuleAddDialog}
        setOpenSWModuleAddDialog={setOpenSWModuleAddDialog}
        handleSWModuleAdd={handleSWModuleAdd}
        vfOptionsList={vfOptionsList}
      />

      <VlcSWModuleUpdateDialog
        openSWModuleUpdateDialog={openSWModuleUpdateDialog}
        setOpenSWModuleUpdateDialog={setOpenSWModuleUpdateDialog}
        handleSWModuleUpdate={handleSWModuleUpdate}
        selectedSWModuleUpdateDetails={selectedSWModuleUpdateDetails}
        setSelectedSWModuleUpdateDetails={setSelectedSWModuleUpdateDetails}
      />

      <VlcSWModuleReplaceDialog
        openSWModuleReplaceDialog={openSWModuleReplaceDialog}
        setOpenSWModuleReplaceDialog={setOpenSWModuleReplaceDialog}
        handleSWModuleReplace={handleSWModuleReplace}
        selectedSWModuleReplaceDetails={selectedSWModuleReplaceDetails}
        setSelectedSWModuleReplaceDetails={setSelectedSWModuleReplaceDetails}
        vfOptionsList={vfOptionsList}
      />
    </>
  );
}

export default VlcSWModules;

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 26-07-2024
 * @description
 * @param {}
 * @return {}
 */
function VlcSWModuleAddDialog({
  openSWModuleAddDialog,
  setOpenSWModuleAddDialog,
  handleSWModuleAdd,
  vfOptionsList,
}) {
  /* Add SW Modules Fields */
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [version, setVersion] = useState("");
  const [versionError, setVersionError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [summary, setSummary] = useState("");
  const [summaryError, setSummaryError] = useState("");
  const [selectedVfIds, setSelectedVFIds] = useState([]);

  /* -------------- UseEffects -------------- */

  /* -------------- Add SW Modules -------------- */

  const clearAddSWModuleFields = () => {
    setName("");
    setNameError("");
    setVersion("");
    setVersionError("");
    setDescription("");
    setDescriptionError("");
    setSummary("");
    setSummaryError("");
    setSelectedVFIds([]);
  };

  const isAddSWModuleValid = () => {
    return (
      name.trim() !== "" &&
      !nameError &&
      version.trim() !== "" &&
      !versionError &&
      description.trim() !== "" &&
      !descriptionError &&
      selectedVfIds?.length > 0 &&
      (summary.trim().length === 0 || !summaryError)
    );
  };

  const handleCloseSWModuleAdd = () => {
    setOpenSWModuleAddDialog(false);

    clearAddSWModuleFields();
  };

  const handleAddSWModule = () => {
    handleSWModuleAdd({
      version: version,
      name: name,
      summary: summary,
      description: description,
      vf_ids: selectedVfIds,
    });

    handleCloseSWModuleAdd();
  };

  return (
    <CustomAddDialog
      open={openSWModuleAddDialog}
      onClose={handleCloseSWModuleAdd}
      dialogTitle={"Add SW Module"}
      dialogContent={
        <Stack spacing={2} mb={2}>
          <CustomTextFieldN
            size={"small"}
            label={"Name*"}
            placeholder={"Name*"}
            type={"text"}
            fldType={"text"}
            value={name}
            setValue={setName}
            valueError={nameError}
            setValueError={setNameError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Version*"}
            placeholder={"Version*"}
            type={"text"}
            fldType={"textR"}
            value={version}
            setValue={setVersion}
            valueError={versionError}
            setValueError={setVersionError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Description*"}
            placeholder={"Description*"}
            type={"text"}
            fldType={"text"}
            value={description}
            setValue={setDescription}
            valueError={descriptionError}
            setValueError={setDescriptionError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Summary"}
            placeholder={"Summary"}
            type={"text"}
            fldType={"textO"}
            value={summary}
            setValue={setSummary}
            valueError={summaryError}
            setValueError={setSummaryError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
          <MultiSelectTextFieldN
            required
            mutiple
            size={"small"}
            label={"Select Vehicle Functions*"}
            optionsList={vfOptionsList}
            selectedValues={selectedVfIds}
            setSelectedValues={setSelectedVFIds}
            sltFldStyle={{
              width: "270px",
            }}
          />
        </Stack>
      }
      onConfirm={() => {
        handleAddSWModule({});
        clearAddSWModuleFields();
      }}
      onDisabled={!isAddSWModuleValid()}
    />
  );
}

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 26-07-2024
 * @description
 * @param {}
 * @return {}
 */
function VlcSWModuleUpdateDialog({
  openSWModuleUpdateDialog,
  setOpenSWModuleUpdateDialog,
  handleSWModuleUpdate,
  selectedSWModuleUpdateDetails,
  setSelectedSWModuleUpdateDetails,
}) {
  /* Update SW Modules Fields */
  const [version, setVersion] = useState("");
  const [versionError, setVersionError] = useState("");

  useEffect(() => {
    if (openSWModuleUpdateDialog == true && selectedSWModuleUpdateDetails) {
      setVersion(selectedSWModuleUpdateDetails?.version || "");
    }
  }, [openSWModuleUpdateDialog, selectedSWModuleUpdateDetails]);

  /* -------------- Update SW Module -------------- */

  const clearUpdateSWModuleFields = () => {
    setVersion("");
    setVersionError("");
  };

  const isUpdateSWModuleValid = () => {
    return (
      !versionError && version.trim() !== selectedSWModuleUpdateDetails?.version
    );
  };

  const handleCloseSWModuleUpdate = () => {
    setOpenSWModuleUpdateDialog(false);

    clearUpdateSWModuleFields();

    setSelectedSWModuleUpdateDetails(null);
  };

  const handleUpdateSWModule = ({}) => {
    if (
      selectedSWModuleUpdateDetails &&
      selectedSWModuleUpdateDetails?.swm_id
    ) {
      handleSWModuleUpdate({
        swm_id: Number(selectedSWModuleUpdateDetails?.swm_id),
        version: version,
      });

      handleCloseSWModuleUpdate();
    }
  };

  return (
    <CustomUpdateDialog
      open={openSWModuleUpdateDialog}
      onClose={handleCloseSWModuleUpdate}
      dialogTitle={"Update SW Module"}
      dialogContent={
        <Stack spacing={2} mb={2}>
          <CustomTextFieldN
            size={"small"}
            label={"Version*"}
            placeholder={"Version*"}
            type={"text"}
            fldType={"textR"}
            value={version}
            setValue={setVersion}
            valueError={versionError}
            setValueError={setVersionError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
        </Stack>
      }
      onConfirm={() => {
        handleUpdateSWModule({});
        clearUpdateSWModuleFields();
      }}
      onDisabled={!isUpdateSWModuleValid()}
    />
  );
}

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 26-07-2024
 * @description
 * @param {}
 * @return {}
 */
function VlcSWModuleReplaceDialog({
  openSWModuleReplaceDialog,
  setOpenSWModuleReplaceDialog,
  handleSWModuleReplace,
  selectedSWModuleReplaceDetails,
  setSelectedSWModuleReplaceDetails,
  vfOptionsList,
}) {
  /* Replace SW Modules Fields */
  const [name, setName] = useState("");
  const [nameError, setNameError] = useState("");
  const [version, setVersion] = useState("");
  const [versionError, setVersionError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [summary, setSummary] = useState("");
  const [summaryError, setSummaryError] = useState("");
  const [selectedVfIds, setSelectedVfIds] = useState([]);

  /* -------------- UseEffects -------------- */

  useEffect(() => {
    if (openSWModuleReplaceDialog == true && selectedSWModuleReplaceDetails) {
      setName(selectedSWModuleReplaceDetails?.name || "");
      setVersion(selectedSWModuleReplaceDetails?.version || "");
      setDescription(selectedSWModuleReplaceDetails?.description || "");
      setSummary(selectedSWModuleReplaceDetails?.summary || "");
      setSelectedVfIds(
        (selectedSWModuleReplaceDetails?.vf_ids || [])
          .map((vfId) => Number(vfId))
          .filter((vfId) => !isNaN(vfId))
      );
    }
  }, [openSWModuleReplaceDialog, selectedSWModuleReplaceDetails]);

  /* -------------- Replace SW Module -------------- */

  const clearReplaceSWModuleFields = () => {
    setName("");
    setNameError("");
    setVersion("");
    setVersionError("");
    setDescription("");
    setDescriptionError("");
    setSummary("");
    setSummaryError("");
    setSelectedVfIds([]);
  };

  const isReplaceSWModuleValid = () => {
    return (
      !nameError &&
      !versionError &&
      !descriptionError &&
      selectedVfIds?.length > 0 &&
      (summary.trim().length === 0 || !summaryError) &&
      (name.trim() !== selectedSWModuleReplaceDetails?.name ||
        version.trim() !== selectedSWModuleReplaceDetails?.version ||
        description.trim() !== selectedSWModuleReplaceDetails?.description ||
        summary.trim() !== selectedSWModuleReplaceDetails?.summary ||
        selectedVfIds.length !== selectedSWModuleReplaceDetails?.vf_ids.length)
    );
  };

  const handleCloseSWModuleReplace = () => {
    setOpenSWModuleReplaceDialog(false);

    clearReplaceSWModuleFields();

    setSelectedSWModuleReplaceDetails(null);
  };

  const handleReplaceSWModule = ({}) => {
    if (
      selectedSWModuleReplaceDetails &&
      selectedSWModuleReplaceDetails?.swm_id
    ) {
      handleSWModuleReplace({
        swm_id: Number(selectedSWModuleReplaceDetails?.swm_id),
        version: version,
        name: name,
        summary: summary,
        description: description,
        vf_ids: selectedVfIds,
      });

      handleCloseSWModuleReplace();
    }
  };

  return (
    <CustomReplaceDialog
      open={openSWModuleReplaceDialog}
      onClose={handleCloseSWModuleReplace}
      dialogTitle={"Replace SW Module"}
      dialogContent={
        <Stack spacing={2} mb={2}>
          <CustomTextFieldN
            size={"small"}
            label={"Name*"}
            placeholder={"Name*"}
            type={"text"}
            fldType={"text"}
            value={name}
            setValue={setName}
            valueError={nameError}
            setValueError={setNameError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Version*"}
            placeholder={"Version*"}
            type={"text"}
            fldType={"textR"}
            value={version}
            setValue={setVersion}
            valueError={versionError}
            setValueError={setVersionError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Description*"}
            placeholder={"Description*"}
            type={"text"}
            fldType={"text"}
            value={description}
            setValue={setDescription}
            valueError={descriptionError}
            setValueError={setDescriptionError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
          <CustomTextFieldN
            size={"small"}
            label={"Summary"}
            placeholder={"Summary"}
            type={"text"}
            fldType={"textO"}
            value={summary}
            setValue={setSummary}
            valueError={summaryError}
            setValueError={setSummaryError}
            variant={"standard"}
            txtFldStyle={{
              width: "270px",
            }}
          />
          <MultiSelectTextFieldN
            required
            mutiple
            size={"small"}
            label={"Select Vehicle Functions"}
            optionsList={vfOptionsList}
            selectedValues={selectedVfIds}
            setSelectedValues={setSelectedVfIds}
            sltFldStyle={{
              width: "270px",
            }}
          />
        </Stack>
      }
      onConfirm={() => {
        handleReplaceSWModule({});
        clearReplaceSWModuleFields();
      }}
      onDisabled={!isReplaceSWModuleValid()}
    />
  );
}
