import actionType from "../actionTypes";

const initialState = {
  error: false,
  msg: "",
  projectConfig: [],
};

const projectConfigReducer = (state = initialState, action) => {
  console.log(action);
  switch (action.type) {
    case actionType.ADD_PROJECT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.GET_PROJECT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
        projectConfig: action.payload.error ? [] : action.payload.data,
      };

    case actionType.UPDATE_PROJECT:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.UPDATE_PROJECT_STATUS:
      return {
        ...state,
        error: action.payload.error,
        msg: action.payload.msg,
      };
    case actionType.RESET_PROJECT_CONFIG:
      return initialState;
    default:
      return state;
  }
};

export default projectConfigReducer;
