/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 06-07-2024
 * @description Reusable phone number text field with phone code select option
 */
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 06-07-2024
 * @description Reusable phone number text field with phone code select option
 * @param {}
 * @return The rendered phone number component.
 */
function PhoneNumberTextField({
  size,
  variant,
  phoneCodeLabel,
  phoneNumberLabel,
  phoneCodeOptions,
  selectedPhoneCode,
  setSelectedPhoneCode,
  phoneNumber,
  setPhoneNumber,
  phoneNumberError,
  setPhoneNumberError,
  phoneCodeStyle,
  phoneNumberStyle,
}) {
  // Handles change events for phone code
  const handlePhoneCodeChange = (e) => {
    setSelectedPhoneCode(e.target.value);
    setPhoneNumberError('');
  };

  // Handles change events for phone number
  const handlePhoneNumberChange = (e) => {
    const newValue = e.target.value.replace(/\D/g, '');
    if (!selectedPhoneCode) {
      setPhoneNumberError('Select country for phone number.');
    } else {
      setPhoneNumberError('');
      if (newValue.length <= 10) {
        setPhoneNumber(newValue);
      }
    }
  };

  return (
    <Box display="flex" alignItems="center" flexDirection="column">
      <Box>
      <FormControl size={size} variant={variant} sx={phoneCodeStyle}>
        <InputLabel>{phoneCodeLabel}</InputLabel>
        <Select value={selectedPhoneCode} error={Boolean(phoneNumberError)} onChange={handlePhoneCodeChange}>
          {phoneCodeOptions?.map((option) => (
            <MenuItem key={option.value} value={option?.value}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <TextField
        variant={variant}
        size={size}
        label={phoneNumberLabel}
        value={phoneNumber}
        error={Boolean(phoneNumberError)}
        onChange={handlePhoneNumberChange}
        sx={phoneNumberStyle}
      />
    </Box>
      {phoneNumberError && (
        <Box mt={1} textAlign="center" color="error.main">
          <Typography variant="body2" color="error">
            {phoneNumberError}
          </Typography>
        </Box>
      )}
    </Box>
  );
}

export default PhoneNumberTextField;
