/**
 * @author Abdul Rahim M - abdul@au79consulting.com
 * @date 30-06-2024
 * @description Component for handling the AdminSettings.
 */

import React, { useRef, useState } from "react";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import StarBorder from "@mui/icons-material/StarBorder";
import Divider from "@mui/material/Divider";

import {
  AppBar,
  Button,
  Card,
  CardContent,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Slide,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Toolbar,
  Typography,
  styled,
} from "@mui/material";
import "./adminSettingsStyle.css";

import ZoneDetails from "./ZoneDetails";
import { useDispatch, useSelector } from "react-redux";
import PowerTrainClass from "./PowerTrainClass";
import VehicleFunction from "./VehicleFunction";
import VehicleClass from "./VehicleClass";
import ArchitectureTypes1 from "./ArchitectureTypes1";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import adminSettingsAPI from "../../redux/actions/adminSetttingAPI";
import profileActions from "../../redux/actions/profile";

function AdminSettings() {
  const dispatch = useDispatch();
  const adminSettingsDetails = useSelector(
    (state) => state.adminSettingsReducer
  );

  const [open, setOpen] = React.useState(null);
  const [architectureDetails, setArchitectureDetails] = React.useState([]);
  const [zoneDetails, setZoneDetails] = React.useState([]);

  React.useEffect(() => {
    dispatch(
      HandleApiActions({
        ...adminSettingsAPI.get_admin_settings,
      })
    ).then(() => {
      dispatch(
        HandleApiActions({
          ...profileActions.get_profile_details,
        })
      );
    });
  }, []);

  React.useEffect(() => {
    console.log("Admin Settings Details");
    console.log(adminSettingsDetails);
    setArchitectureDetails(
      adminSettingsDetails?.adminSettings?.data?.architecture_types
    );
    setZoneDetails(adminSettingsDetails?.adminSettings?.data?.zone_details);
  }, [adminSettingsDetails]);

  const handleClick = (value) => {
    setOpen(open === value ? null : value);
  };

  const [open1, setOpen1] = useState(false);

  return (
    <Box padding="50px" mb="20px">
      {/* <ArchitectureTypes
        open={open}
        handleClick={handleClick}
        architectureDetails={architectureDetails}
      /> */}

      <ArchitectureTypes1
        open={open}
        handleClick={handleClick}
        architectureDetails={architectureDetails}
      />

      <ZoneDetails
        open={open}
        handleClick={handleClick}
        zoneDetails={zoneDetails}
      />

      <PowerTrainClass open={open} handleClick={handleClick} />

      <VehicleFunction open={open} handleClick={handleClick} />

      <VehicleClass open={open} handleClick={handleClick} />
    </Box>
  );
}

export default AdminSettings;
