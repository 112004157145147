import Box from "@mui/material/Box";
import Checkbox from "@mui/material/Checkbox";
import Divider from "@mui/material/Divider";
import FormControlLabel from "@mui/material/FormControlLabel";
import IconButton from "@mui/material/IconButton";

import Typography from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  GetSingleImages,
  HandleApiActions,
} from "../../redux/actions/actionHandler";
import profileActions from "../../redux/actions/profile";
import SelectTextField from "../../components/text-fields/SelectTextField";
import DownloadIcon from "@mui/icons-material/Download";

import CustomTextField2 from "../../components/text-fields/CustomTextField2";
import adminSettingsAPI from "../../redux/actions/adminSetttingAPI";

import MultiSelectTextField2 from "../../components/text-fields/MutiSelectTextField2";
import MultiSelectTextField3 from "../../components/text-fields/MutiSelectTextField3";
import moment from "moment";
import _ from "underscore";
import Button from "@mui/material/Button";
import projectConfigAPI from "../../redux/actions/projectConfigAPI";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

function FirstScreen({ close, profileDetails }) {
  //Redux
  const dispatch = useDispatch();
  const timeZoneDetails = useSelector(
    (state) => state.countryAndPhoneCodeReducer.countryAndPhoneCode
  );
  const vehicleFunctionDetails = useSelector(
    (state) => state.vehicleFunctionReducer
  );
  const powerTrainClassDetails = useSelector(
    (state) => state.powerTrainClassReducer
  );
  const architectureTypesDetails = useSelector(
    (state) => state.adminSettingsReducer
  );
  const projectStatusDetails = useSelector(
    (state) => state.settingsReducer?.metaData?.project_status
  );
  const vehicleClassDetails = useSelector((state) => state.vehicleClassReducer);

  const projectStatusList = Object.keys(projectStatusDetails);

  ///useState
  const [vfData, setVfData] = useState([]);
  const [pcData, setPcData] = useState([]);
  const [architectureTypes, setArchitectureTypes] = useState([]);
  const [zoneData, setZoneData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [vcData, setVcData] = useState([]);
  const [image, setImage] = useState([]);

  const [region, setRegion] = useState("");
  const [regions, setRegions] = useState([]);
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [selectPS, setSelectPS] = useState("");

  const [name, setName] = useState("");
  const [commercialName, setCommercialName] = useState("");
  const [manufacturerName, setManufacturerName] = useState("");
  const [manufacturerAddress, setManufacturerAddress] = useState("");
  const [vehiclePlatform, setVehiclePlatform] = useState("");
  const [vehicleStatus, setVehicleStatus] = useState("");

  const [selectedItemsVf, setSelectedItemsVf] = useState([]);
  const [selectedItemsPc, setSelectedItemsPc] = useState([]);
  const [selectedItemsAt, setSelectedItemsAt] = useState(null);
  const [selectedItemsZone, setSelectedItemsZone] = useState([]);
  const [selectedClass, setSelectedClass] = useState(null);
  const [selectedSubClass, setSelectedSubClass] = useState(null);

  //error
  const [nameError, setNameError] = useState(false);
  const [commercialNameError, setCommercialNameError] = useState(false);
  const [manufacturerNameError, setManufacturerNameError] = useState(false);
  const [manufacturerAddressError, setManufacturerAddressError] = useState("");
  const [vehiclePlatformError, setVehiclePlatformError] = useState("");
  const [vehicleStatusError, setVehicleStatusError] = useState("");

  //useEffect
  useEffect(() => {
    dispatch(HandleApiActions({ ...profileActions.get_coutry_regions }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_powertrain_class }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_function }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_admin_settings }));
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_class }));
  }, []);

  console.log("selectedItemsAt", selectedItemsAt);

  useEffect(() => {
    console.log("TimezoneDetails", timeZoneDetails);
    if (timeZoneDetails && timeZoneDetails.length > 0) {
      const sortedRegions = timeZoneDetails
        ?.map((data) => {
          return { value: data.time_zone, label: data.time_zone };
        })
        .sort((a, b) => {
          return a.value.localeCompare(b.value);
        });
      setRegions(sortedRegions);
      const sortedCountries = timeZoneDetails
        .map((data) => {
          return { value: data.country, label: data.country };
        })
        .sort((a, b) => {
          return a.value.localeCompare(b.value);
        });
      setCountries(sortedCountries);
    }
  }, [timeZoneDetails]);

  useEffect(() => {
    console.log("vehicleFunctionDetails......");

    console.log(vehicleFunctionDetails?.vehicleFunctionDetails?.data);
    setVfData(vehicleFunctionDetails?.vehicleFunctionDetails?.data);
  }, [vehicleFunctionDetails]);

  useEffect(() => {
    console.log("vehicleClassDetails......");

    console.log(vehicleClassDetails?.vehicleClassDetails?.data);
    setVcData(vehicleClassDetails?.vehicleClassDetails?.data);
  }, [vehicleClassDetails]);

  useEffect(() => {
    console.log("powerTrainDetails");
    console.log(powerTrainClassDetails?.powerTrainClassDetails?.data);
    setPcData(powerTrainClassDetails?.powerTrainClassDetails?.data);
  }, [powerTrainClassDetails]);

  useEffect(() => {
    console.log("architectureTypes");
    console.log(
      architectureTypesDetails?.adminSettings?.data?.architecture_types
    );
    setArchitectureTypes(
      architectureTypesDetails?.adminSettings?.data?.architecture_types
    );
    setZoneData(architectureTypesDetails?.adminSettings?.data?.zone_details);
  }, [architectureTypesDetails]);

  useEffect(() => {
    if (projectStatusList && projectStatusList.length > 1) {
      const projectStatusData = projectStatusList
        .slice(0, 2)
        .map((el, idx) => ({
        value: idx,
        label: formatString(el),
        }));

    setProjectData(projectStatusData);
    } else {
      setProjectData([]);
    }
  }, [projectStatusDetails]);

  useEffect(() => {
    if (vcData && vcData.length) {
      console.log("---vcData----");
      console.log(vcData);
      const imageUrl = async () => {
        const fetchUrlArr = await Promise.all(
          vcData.map(async (item) => {
            console.log("---Image URL----");
            console.log(item.image);
            try {
              const response = await GetSingleImages({ image: item.image });
              return { ...item, imageUrl: response };
            } catch (error) {
              console.log(error);
              return { ...item, imageUrl: "" };
            }
          })
        );
        console.log("----Fetch Url--");
        console.log(fetchUrlArr);
        setImage(fetchUrlArr);
      };
      imageUrl();
    }
  }, [vcData]);

  const timeZones = Array.from(
    new Set(timeZoneDetails?.map((item) => item?.time_zone))
  );

  const ctry = timeZoneDetails.filter((item) => item?.time_zone === region);

  console.log("time zones", timeZones);
  console.log("ctry", ctry);

  const timeZoneOptions = timeZones
    ?.map((tz) => ({ value: tz, label: tz }))
    .sort((a, b) => {
      return a.value.localeCompare(b.value);
    });
  console.log("timeZoneOptions", timeZoneOptions);

  const countryOptions = ctry?.map((country) => ({
    value: country.country,
    label: country.country,
  }));

  const handleCreate = () => {
    const reqObj = {
      name: name,
      trade_mark: commercialName,
      vehicle_platform: vehiclePlatform,
      status: projectStatusDetails[projectStatusList[selectPS]],
      ...(vehicleStatus?.length > 0 ? { vehicle_status: vehicleStatus } : null),
      architecture_name: selectedItemsAt,
      mft_details: {
        name: manufacturerName,
        address: manufacturerAddress,
      },
      ...(selectedItemsZone?.length > 0 ? { zones: selectedItemsZone } : {}),

      vehicle_class: parseInt(selectedClass),
      // ...(selectedItemsPc?.length > 0
      //   ? { pwrtrn_class: selectedItemsPc.map((str) => parseInt(str)) }
      //   : {}),
      pwrtrn_class:
        selectedItemsPc && selectedItemsPc.length
          ? selectedItemsPc?.map((str) => parseInt(str))
          : undefined,
      vehicle_funcs: selectedItemsVf?.map((str) => parseInt(str)),
      current_time: moment().format(),
      country: country,
      region: region,
    };

    console.log("reqObj", reqObj);
    dispatch(
      HandleApiActions({
        ...projectConfigAPI.add_project,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(HandleApiActions({ ...projectConfigAPI.get_project }));
    });
    close(false);
  };

  console.log("selectedItemsPc", selectedItemsPc);
  console.log("selectedItemsZone", selectedItemsZone);

  const handleClassChange = (event) => {
    setSelectedClass(event.target.value);
    console.log("event", event.target.value);
  };

  function formatString(str) {
    let result = str.charAt(0).toUpperCase() + str.slice(1);

    result = result
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return result;
  }

  useEffect(() => {
    console.log("---Image---");
    console.log(image);
  }, [image]);

  return (
    <div>
      <Box sx={{ maxHeight: "400px", overflowY: "auto" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
          <Typography sx={{ fontStyle: "italic", mt: "10px" }}>
            Required fields are marked with asterisk *
          </Typography>
          {/* <IconButton>
            <DownloadIcon />
          </IconButton> */}
        </Box>

        <Box sx={{ display: "flex" }}>
          <Box sx={{ marginTop: "10px" }}>
            <SelectTextField
              size={"small"}
              label={"Time Zone*"}
              optionsList={timeZoneOptions}
              //optionsList={regions}
              selectedValue={region}
              setSelectedValue={setRegion}
              variant={"standard"}
              sltFldStyle={{
                width: "180px",
              }}
            />
          </Box>

          <Box
            sx={{
              marginTop: "10px",
              marginLeft: "10px",
              flexGrow: "2",
            }}
          >
            <SelectTextField
              size={"small"}
              label={"Country*"}
              optionsList={countryOptions}
              //optionsList={countries}
              selectedValue={country}
              setSelectedValue={setCountry}
              variant={"standard"}
              sltFldStyle={{
                width: "200px",
              }}
            />
          </Box>
          <Box
            sx={{
              marginTop: "10px",
              marginLeft: "10px",
              marginRight: "10px",
            }}
          >
            <SelectTextField
              required
              size={"small"}
              label={"Project Status"}
              optionsList={projectData}
              selectedValue={selectPS}
              setSelectedValue={setSelectPS}
              variant={"standard"}
              sltFldStyle={{
                width: "200px",
              }}
            />
          </Box>
        </Box>

        <Divider
          sx={{
            marginTop: "25px",
            marginRight: "10px",
            border: 1,
          }}
          flexItem
        />
        <Box mt="10px" display="flex" justifyContent="space-between">
          <CustomTextField2
            size="small"
            placeholder="Name of the Project"
            label="Name of the Project"
            required
            value={name}
            setValue={setName}
            txtFldStyle={{ width: "220px" }}
            validationRules={{ minLength: 3 }}
            setError={setNameError}
            error={nameError !== ""}
          />
          <CustomTextField2
            size="small"
            label="Vehicle Status"
            placeholder="Vehicle Status"
            value={vehicleStatus}
            setValue={setVehicleStatus}
            txtFldStyle={{
              width: "200px",

              mr: "10px",
            }}
            validationRules={{ minLength: 3 }}
            setError={setVehicleStatusError}
            error={vehicleStatusError !== ""}
          />
        </Box>
        <Box display="flex" justifyContent="space-between">
          <CustomTextField2
            required
            size="small"
            label="Trademark"
            placeholder="Trademark"
            value={commercialName}
            setValue={setCommercialName}
            txtFldStyle={{
              width: "220px",
              mt: "10px",
            }}
            validationRules={{ minLength: 3 }}
            setError={setCommercialNameError}
            error={commercialNameError !== ""}
          />

          <CustomTextField2
            required
            size="small"
            label="Vehicle Platform"
            placeholder="Vehicle Platform"
            value={vehiclePlatform}
            setValue={setVehiclePlatform}
            txtFldStyle={{
              width: "200px",
              mt: "10px",
              mr: "10px",
            }}
            validationRules={{ minLength: 3 }}
            setError={setVehiclePlatformError}
            error={vehiclePlatformError !== ""}
          />
        </Box>
        <Divider
          sx={{
            marginTop: "25px",
            marginRight: "10px",
            border: 1,
          }}
          flexItem
        />
        <Box display="flex" justifyContent="space-between">
          <Box sx={{ mt: "25px" }}>
            <Typography fontWeight={"bold"} ml="2px">
              Vehicle Class*
            </Typography>
            <div>
              <Box
                sx={{
                  display: "flex",
                  mt: "10px",
                  width: "600px",
                }}
              >
                <FormControl fullWidth sx={{ width: "400px" }}>
                  <InputLabel>Select Vehicle Class</InputLabel>
                  <Select
                    variant="standard"
                    value={selectedClass}
                    onChange={handleClassChange}
                    sx={{ mb: 2 }}
                    label="Select Vehicle Class"
                  >
                    {image &&
                      image?.map((data, idx) => (
                      <MenuItem key={data?.id} value={data?.id}>
                          <Box display="flex" key={data?.id} value={data?.id}>
                          <Box>
                            <img
                                src={data.imageUrl}
                              alt=""
                              style={{
                                width: "46px",
                                height: "46px",
                                marginRight: "8px",
                              }}
                            />
                          </Box>

                          <Box display="flex" flexDirection="column">
                            <Typography fontWeight="bold">
                              {" "}
                              {data?.name}
                            </Typography>
                            <Typography>
                              {data?.class}-{data?.sub_class}
                            </Typography>
                          </Box>
                        </Box>
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>
          </Box>
        </Box>

        <Box sx={{ marginTop: "15px" }}>
          <Box sx={{ mt: "25px" }}>
            <Typography fontWeight={"bold"} ml="2px">
              Vehicle Function*
            </Typography>
            <MultiSelectTextField2
              mutiple
              size={"small"}
              label={"Select Vehicle Function"}
              optionsList={vfData}
              selectedValues={selectedItemsVf}
              setSelectedValues={setSelectedItemsVf}
              sltFldStyle={{
                width: "50%",
                textAlign: "start",
                mt: "10px",
                backgroundColor: "white",
              }}
            />
          </Box>
          <Box sx={{ mt: "25px" }}>
            <Typography fontWeight={"bold"} ml="2px">
              PowerTrain Class
            </Typography>
            <MultiSelectTextField2
              mutiple
              variant="standard"
              size={"small"}
              label={"Select Powertrain Class [Optional]"}
              optionsList={pcData}
              selectedValues={selectedItemsPc}
              setSelectedValues={setSelectedItemsPc}
              sltFldStyle={{
                width: "50%",
                textAlign: "start",
                ml: "2px",
                mt: "10px",
                backgroundColor: "white",
              }}
            />
          </Box>
        </Box>

        <Divider
          sx={{
            marginTop: "25px",
            marginRight: "10px",
            border: 1,
          }}
          flexItem
        />

        <Box sx={{ marginTop: "10px" }}>
          <Typography fontWeight={"bold"}>
            Manufacturer Name & Address
          </Typography>
          <CustomTextField2
            required
            size="small"
            label="Manufacturer Name"
            placeholder="Manufacturer Name"
            value={manufacturerName}
            setValue={setManufacturerName}
            txtFldStyle={{
              width: "270px",
              marginTop: "10px",
            }}
            validationRules={{ minLength: 3 }}
            setError={setManufacturerNameError}
            error={manufacturerNameError !== ""}
          />

          <CustomTextField2
            required
            size="small"
            label="Manufacturer Address"
            placeholder="Manufacturer Address"
            value={manufacturerAddress}
            setValue={setManufacturerAddress}
            txtFldStyle={{
              width: "95%",
              mt: "15px",
            }}
            validationRules={{ minLength: 3 }}
            setError={setManufacturerAddressError}
            error={manufacturerAddressError !== ""}
          />
        </Box>

        <Divider
          sx={{
            marginTop: "25px",
            marginRight: "10px",
            border: 1,
          }}
          flexItem
        />

        <Box mt="25px">
          <Typography fontWeight={"bold"} ml="2px">
            Choose the type of Architecture*
          </Typography>
          <Box
            mt="5px"
            width="50%"
            sx={{ display: "flex" }}
            boxShadow="3"
            borderRadius="10px"
            display="flex"
            flexWrap="wrap"
          >
            {architectureTypes?.map((data, idx) => {
              return (
                <FormControlLabel
                  sx={{ ml: "10px" }}
                  key={data?.name}
                  control={
                    <Checkbox checked={selectedItemsAt === data?.name} />
                  }
                  label={data?.name}
                  onChange={() => setSelectedItemsAt(data?.name)}
                />
              );
            })}
          </Box>
        </Box>
        {selectedItemsAt &&
        architectureTypes.find((item) => item.name === selectedItemsAt)
          ?.is_zones_available ? (
          <Box mt="20px" mb="10px">
            <Typography fontWeight={"bold"}>
              Select the Zones & Positions*
            </Typography>

            <Box mt="10px">
              <MultiSelectTextField3
                disabled={!selectedItemsZone}
                size="medium"
                label="Select Zones"
                optionsList={zoneData}
                selectedValues={selectedItemsZone}
                setSelectedValues={setSelectedItemsZone}
                sltFldStyle={{ minWidth: 200 }}
              />
            </Box>
          </Box>
        ) : (
          <Box mb="10px"></Box>
        )}
      </Box>
      <Box mt="10px" display="flex" justifyContent="flex-end">
        <Button
          onClick={handleCreate}
          disabled={
            name.length > 2 &&
            region.length > 2 &&
            country.length > 2 &&
            commercialName.length > 2 &&
            vehiclePlatform.length > 2 &&
            selectedItemsVf?.length > 0 &&
            manufacturerName.length > 2 &&
            selectedItemsAt?.length > 0 &&
            manufacturerAddress.length > 2
              ? false
              : true
          }
          size="medium"
          variant="contained"
        >
          Create
        </Button>
      </Box>
    </div>
  );
}

export default FirstScreen;
