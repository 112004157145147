import { Typography } from "@mui/material";
import React from "react";

function LegalRequirements() {
  return (
    <div>
      <Typography sx={{ marginTop: "20px" }}>
        Legal Requirement related content here
      </Typography>
    </div>
  );
}

export default LegalRequirements;
