/**
 * @author Abdul Rahim M - abdul@au79consulting.com
 * @date 11-06-2024
 * @description This is the Project Configurator Page for the Application
 */
//Importing the required components/functions from the libraries

import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Paper from "@mui/material/Paper";
import Tooltip from "@mui/material/Tooltip";
import CustomButton from "../../components/buttons/CustomButton";
import CreateProject from "./CreateProject";
import {
  GetMultipleImages,
  GetSingleImages,
  HandleApiActions,
} from "../../redux/actions/actionHandler";
import projectConfigAPI from "../../redux/actions/projectConfigAPI";
import { useDispatch, useSelector } from "react-redux";
import { convertUtcToTimeZone } from "../../utils/convertUtcToTimeZone";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import FrontBox1 from "./FrontBox1";
import ViewDialog1 from "./ViewDialog1";
import { Grow } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";

//Main Project Configurator Page Function

function ProjectConfig() {
  const dispatch = useDispatch();
  const projectDetails = useSelector(
    (state) => state.projectConfigReducer?.projectConfig
  );
  const profileDetails = useSelector((state) => state.profileReducer);

  const [boxes, setBoxes] = useState([]);

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);

  const [openCreateProject, setOpenCreateProject] = useState(false);
  const [flippedBoxes, setFlippedBoxes] = useState({});
  const [openDialog, setOpenDialog] = useState(false);
  const [dialogData, setDialogData] = useState(null);
  const [showNoProjectsFound, setShowNoProjectsFound] = useState(false);
  const [images, setImages] = useState({ id: 0, url: "" });
  const [selectedProjectId, setSelectedProjectId] = useState("");

  useEffect(() => {
    if (boxes.length === 0) {
      const timer = setTimeout(() => {
        setShowNoProjectsFound(true);
      }, 500);

      return () => clearTimeout(timer);
    } else {
      setShowNoProjectsFound(false);
    }
  }, []);

  const handleCreateProjectClick = () => {
    setOpenCreateProject(true);
  };

  const handleClickBox = (index) => {
    const clickedData = boxes.find((item) => item?.project_id === index);
    setDialogData(clickedData);
    setSelectedProjectId(index);

    setOpenDialog(true);
  };
  useEffect(() => {
    if (selectedProjectId) {
      const clickedData = boxes.find(
        (item) => item?.project_id === selectedProjectId
      );
      setDialogData(clickedData);
    }
  }, [selectedProjectId, boxes]);
  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  useEffect(() => {
    dispatch(HandleApiActions({ ...projectConfigAPI.get_project }));
  }, []);
  useEffect(() => {
    console.log("projectDetails", projectDetails);
  }, [projectDetails]);
  useEffect(() => {
    if (!Array.isArray(projectDetails)) {
      setBoxes([]);
      return;
    }

    const imageDetails = [];
    let filteredProjectList = projectDetails.map((data) => {
      imageDetails.push({
        id: data.project_id,
        image: data.vehicle_class_details.length
          ? data.vehicle_class_details[0].image
          : "",
      });
      console.log(imageDetails);
      return {
        ...data,
        id: data?.project_id,
        name: data?.name,
        trade_mark: data?.trade_mark,
        mft_details: {
          name: data?.mft_details?.name,
          address: data?.mft_details?.address,
        },
        architecture_name: data?.architecture_name,
        zones: data?.zones,
        vehicle_class_details: data?.vehicle_class_details,
        vehicle_function_details: data?.vehicle_function_details,
        created_at: data?.created_at
          ? convertUtcToTimeZone(
              data.created_at,
              profileDetails?.profile?.profile?.region
            )
          : null,
        updated_at: data?.updated_at
          ? convertUtcToTimeZone(
              data.updated_at,
              profileDetails?.profile?.profile?.region
            )
          : null,
        country: data?.country,
        region: data?.region,
        image_url: "",
      };
    });
    if (imageDetails.length) {
      // const imagesURLs = async () => {
      //   const resp = await GetMultipleImages(imageDetails);
      //   console.log("imagesURLs RESP: ");
      //   console.log(resp);
      // };
      // imagesURLs();
      // console.log("imagesURLs: ");
      // console.log(imagesURLs);
    }
    setBoxes(filteredProjectList);
  }, [projectDetails, profileDetails]);

  console.log("boxes.length", boxes.length);
  console.log("dialogData", dialogData);

  return (
    <>
      {projectDetails.length > 0 ? (
        <Box sx={{ mb: "10px" }}>
          <Box sx={styles.tblFun}>
            <CustomButton
              size={"small"}
              variant={"contained"}
              btnName={"Add"}
              handleOnClick={handleCreateProjectClick}
              btnStyle={{
                ml: 2,
              }}
              startIcon={<AddIcon />}
            />
          </Box>

          <Box
            sx={{
              display: "inline-flex",

              flexWrap: "wrap",
              padding: "10px",
            }}
          >
            {boxes?.map((box, index) => {
              return (
                <Box>
                  <Box
                    display="inline-flex"
                    key={index}
                    sx={{
                      cursor: "pointer",
                      ml: "20px",
                      mb: "20px",
                    }}
                  >
                    {/* <FrontBox
                      profileDetails={profileDetails}
                      boxes={box}
                      handleClickBox={handleClickBox}
                    /> */}
                    <FrontBox1
                      profileDetails={profileDetails}
                      boxes={box}
                      handleClickBox={handleClickBox}
                    />
                  </Box>
                  {openDialog && (
                    <Dialog
                      open={openDialog}
                      onClose={handleCloseDialog}
                      fullWidth
                      fullScreen
                      maxWidth="xl"
                      PaperProps={{ backgroundColor: "#adacac" }}
                      TransitionComponent={Slide}
                      transitionDuration={100} // Adjust the duration as needed
                      TransitionProps={{
                        direction: "up", // Change the direction of the slide
                      }}
                    >
                      <DialogTitle
                        sx={{ m: 0, p: 2, boxShadow: "3" }}
                        id="customized-dialog-title"
                      >
                        <Typography fontWeight="bold" variant="h6">
                          Project ID: {dialogData?.id}
                        </Typography>
                      </DialogTitle>
                      <IconButton
                        aria-label="close"
                        onClick={() => handleCloseDialog(box?.project_id)}
                        sx={{
                          position: "absolute",
                          right: 8,
                          top: 8,
                          color: (theme) => theme.palette.grey[500],
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                      <DialogContent
                        sx={{ backgroundColor: "#f2f5fc ", padding: 2 }}
                      >
                        <ViewDialog1
                          boxes={dialogData}
                          profileDetails={profileDetails}
                        />

                        {/* <ViewDialog
                        boxes={dialogData}
                        profileDetails={profileDetails}
                      /> */}
                      </DialogContent>
                    </Dialog>
                  )}
                </Box>
              );
            })}
          </Box>
        </Box>
      ) : (
        showNoProjectsFound && (
          <Box
            sx={{
              mt: 12,
            }}
          >
            <Typography
              variant="h6"
              // color="primary"
              sx={{
                mb: 1,
              }}
            >
              No Projects Found
            </Typography>
            <Button
              size="small"
              color="primary"
              variant="contained"
              onClick={handleCreateProjectClick}
            >
              Create Project
            </Button>
          </Box>
        )
      )}

      {openCreateProject && (
        <CreateProject open={openCreateProject} close={setOpenCreateProject} />
      )}
    </>
  );
}

export default ProjectConfig;

const styles = {
  container: {
    width: "100%",
    overflow: "hidden",
  },
  tblFun: {
    mx: 2,
    mb: 2,
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
};
