/**
 * @author Narender - narender@au79consulting.com
 * @date 13-06-2024
 * @description Defines action creators for authentication-related actions.
 */

import axios from "axios";
import appMeta from "../../assets/app-meta/appMeta";
import useAxios from "../../hooks/useAxios";
import actionType from "../actionTypes";
import { toast } from "react-toastify";
import customConsole from "../../config/customConsole";

const baseURL = process.env.REACT_APP_BASE_URL;

/**
 * @author Narender - narender@au79consulting.com
 * @date 13-06-2024
 * @description Action creator for login action
 * @param
 * @returns
 */
export const LoginAction = ({ email, password, storage }) => {
  return async (dispatch) => {
    return await axios
      .post(`${baseURL}/UCM/user/login`, { email, password })
      .then((res) => {
        if (!res?.data?.error) {
          if (storage === true) {
            localStorage.setItem("token", res?.data?.data?.token);
          } else {
            sessionStorage.setItem("token", res?.data?.data?.token);
          }

          dispatch({ type: actionType.LOGIN, payload: res?.data });

          return { error: false, msg: res?.data?.msg };
        } else {
          return { error: true, msg: res?.data?.msg };
        }
      })
      .catch((err) => {
        customConsole("-- Login API --")
        customConsole(err);

        return { error: true, msg: err?.response?.data?.msg };
      });
  };
};

/**
 * @author Narender - narender@au79consulting.com
 * @date 13-06-2024
 * @description Action creator for forgot password action
 * @param
 * @returns
 */
export const ForgotPasswordAction = ({ email, handleAuthViewChange }) => {
  return async (dispatch) => {
    return await axios
      .post(`${baseURL}/UCM/user/reset/password/otp`, { email })
      .then((res) => {
        if (!res?.data?.error) {
          sessionStorage.setItem("checksum", res?.data?.data?.checksum);

          handleAuthViewChange(appMeta.authView.RESET_PASSWORD);

          return { error: false, msg: res?.data?.msg };
        } else {
          return { error: true, msg: res?.data?.msg };
        }
      })
      .catch((err) => {
        customConsole("-- Forgot Password API --")
        customConsole(err);

        return { error: true, msg: err?.response?.data?.msg };
      });
  };
};

/**
 * @author Narender - narender@au79consulting.com
 * @date 13-06-2024
 * @description Action creator to reset password
 * @param
 * @returns
 */
export const ResetPasswordAction = ({
  otp,
  password,
  handleAuthViewChange,
}) => {
  const checksum = sessionStorage.getItem("checksum");

  return async (dispatch) => {
    return await axios
      .put(`${baseURL}/UCM/user/reset/password/update`, {
        otp: Number(otp),
        checksum,
        password,
      })
      .then((res) => {
        if (!res?.data?.error) {
          sessionStorage.removeItem("checksum");

          handleAuthViewChange(appMeta.authView.LOGIN);

          return { error: false, msg: res?.data?.msg };
        } else {
          return { error: true, msg: res?.data?.msg };
        }
      })
      .catch((err) => {
        customConsole("-- Reset Password API --")
        customConsole(err);

        return { error: true, msg: err?.response?.data?.msg };
      });
  };
};
