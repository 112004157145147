import actionType from "../actionTypes";
/**
 * @author Tahir Shaik - tahir@au79consulting.com/tahir@bookwater.com
 * @date 12-07-2024
 * @description  Swagger API documentation
 */
const initialState = {
  error: false,
  msg: "",
  swagger_response: "",
};
const swaggerReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionType.GET_SWAGGER_DATA:
      return {
        ...state,
        error: false,
        msg: "Got the data!",
        swagger_response: action.payload,
      };
    default:
      return state;
  }
};

export default swaggerReducer;
