/**
 * @author Tahir Shaik - tahir@bookwater.com - +911-9177327808
 * @date 11-06-2024
 * @description This is the Custom use Axios hook
 *          For making Api call
 *          It will automatically refresh the token if it expires
 */
//Importing the components from the modules/libraries
import axios from "axios";
import { jwtDecode } from "jwt-decode";
import dayjs from "dayjs";
import { startLoading, stopLoading } from "../redux/actions/spinner";
import customConsole from "../config/customConsole";
import store from "../redux/store";
//Global Variables
const baseURL = process.env.REACT_APP_BASE_URL;
const REFRESH_TOKEN_URL = `${baseURL}/UCM/user/keepalive`;
let show_spinner = true;
//Main Custom Use Axios Hook functional component
const useAxios = () => {
  let authTokens = localStorage.getItem("token")
    ? localStorage.getItem("token")
    : null;
  let storageType = true; //true -> Local Storage
  if (authTokens === null) {
    storageType = false;
    authTokens = sessionStorage.getItem("token");
  }
  //Create the Axios Instance
  //Note: Every API (GET/PUT/POST) must have Auth Token So Make it as Default.
  const axiosInstance = axios.create({
    baseURL,
    headers: { Authorization: `Bearer ${authTokens}` },
  });
  //It will Execute Before Main Axios for Token verification & Refresh
  axiosInstance.interceptors.request.use(
    async (req) => {
      show_spinner = true;
      try {
        // if the key exist then this API doesn't wanto to show spinner
        if (req.params?.ignore_app_loading_spinner === true) {
          show_spinner = false;
          delete req.params.ignore_app_loading_spinner;
        }
      } catch (error) {
        console.error("Invalid URL:", error);
      }
      if (show_spinner) {
        store.dispatch(startLoading());
      }
      const user = jwtDecode(authTokens);
      const isExpired = dayjs.unix(user.exp).diff(dayjs()) < 1;
      if (!isExpired) return req;
      //If Token Expiered, refresh the Token
      const tkn = authTokens;
      const response = await axios.post(REFRESH_TOKEN_URL, {
        headers: { Authorization: `Bearer ${tkn}` },
        token: tkn,
      });
      if (response.status === 200) {
        customConsole("Success Response in Refresh Token");
        if (storageType) {
          localStorage.setItem("token", response.data?.data?.token);
        } else {
          sessionStorage.setItem("token", response.data?.data?.token);
        }

        authTokens = response.data?.data?.token;
      } else {
        customConsole("Error Response in Refresh Token");
        customConsole("End Response: " + JSON.stringify(response));
      }
      req.headers.Authorization = `Bearer ${authTokens}`;
      return req;
    },
    (error) => {
      if (show_spinner) {
        store.dispatch(stopLoading());
      }
      return Promise.reject(error);
    }
  );
  axiosInstance.interceptors.response.use(
    (response) => {
      if (show_spinner) {
        store.dispatch(stopLoading());
      }
      return response;
    },
    (error) => {
      console.log(error);
      if (show_spinner) {
        store.dispatch(stopLoading());
      }
      return Promise.reject(error);
    }
  );

  return axiosInstance;
};
export default useAxios;
