import React, { useEffect, useState } from "react";
import CustomUpdateDialog from "../../components/dialog-boxes/CustomUpdateDialog";
import { Box, Stack } from "@mui/material";
import SelectTextField from "../../components/text-fields/SelectTextField";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import projectConfigAPI from "../../redux/actions/projectConfigAPI";
import settingsApis from "../../redux/actions/settings";

function UpdateStatus({ openStatus, boxes, setOpenStatus }) {
  const projectStatus = {
    100: "ACTIVE_PRE_SOP",
    200: "ACTIVE_POST_SOP",
    300: "DE_ACTIVATED",
    500: "COMPLETED",
  };
  const dispatch = useDispatch();
  const [selectPS, setSelectPS] = useState("");
  const [projectData, setProjectData] = useState([]);
  const projectStatusDetails = useSelector(
    (state) => state.settingsReducer?.metaData?.project_status
  );
  const projectStatusList = Object.keys(projectStatusDetails);

  useEffect(() => {
    dispatch(HandleApiActions({ ...settingsApis.get_app_meta_data }));
  }, []);
  useEffect(() => {
    let projectStatusData = projectStatusList?.map((el, idx) => {
      return {
        value: idx,
        label: formatString(el),
      };
    });

    setProjectData(projectStatusData);
  }, [projectStatusDetails]);

  useEffect(() => {
    setSelectPS(projectStatus[boxes?.status]);
  }, [boxes]);

  function formatString(str) {
    let result = str.charAt(0).toUpperCase() + str.slice(1);

    result = result
      .replace(/_/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
      .join(" ");

    return result;
  }

  const handleClickEditStatus = (index) => {
    const reqObj = {
      project_id: parseInt(index?.project_id),
      status: parseInt(projectStatusDetails[projectStatusList[selectPS]]),
      current_time: moment().format(),
    };

    dispatch(
      HandleApiActions({
        ...projectConfigAPI.update_project_status,
        params: reqObj,
        show_toast: true,
      })
    ).then(() => {
      dispatch(HandleApiActions({ ...projectConfigAPI.get_project }));
    });

    setOpenStatus(false);
  };
  return (
    <div>
      <CustomUpdateDialog
        open={openStatus}
        onClose={() => setOpenStatus(false)}
        dialogTitle={"Update Status"}
        dialogContent={
          <Stack spacing={2}>
            <Box sx={{ marginTop: "10px" }}>
              <SelectTextField
                required
                size={"small"}
                label={"Project Status"}
                optionsList={projectData}
                selectedValue={selectPS}
                setSelectedValue={setSelectPS}
                variant={"standard"}
                sltFldStyle={{
                  width: "200px",
                }}
              />
            </Box>
          </Stack>
        }
        onConfirm={() => handleClickEditStatus(boxes)}
      />
    </div>
  );
}

export default UpdateStatus;
