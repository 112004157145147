import React, { useEffect, useState } from "react";
import CustomUpdateDialog from "../../components/dialog-boxes/CustomUpdateDialog";
import { Box, Stack, Typography } from "@mui/material";
import MultiSelectTextField4 from "../../components/text-fields/MultiSelectTextField4";
import { useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import adminSettingsAPI from "../../redux/actions/adminSetttingAPI";

function EditVehicleFunction({
  openVfDialog,
  setOpenVfDialog,
  editVF,
  setEditVF,
  handleClickEdit,
}) {
  const dispatch = useDispatch();
  const [vfData, setVfData] = useState([]);
  const vehicleFunctionDetails = useSelector(
    (state) => state.vehicleFunctionReducer
  );
  useEffect(() => {
    dispatch(HandleApiActions({ ...adminSettingsAPI.get_vehicle_function }));
  }, []);
  useEffect(() => {
    console.log("vehicleFunctionDetails......");

    console.log(vehicleFunctionDetails?.vehicleFunctionDetails?.data);
    setVfData(vehicleFunctionDetails?.vehicleFunctionDetails?.data);
  }, [vehicleFunctionDetails]);
  return (
    <div>
      <CustomUpdateDialog
        open={openVfDialog}
        onClose={() => {
          setOpenVfDialog(false);
        }}
        dialogTitle={"Add Vehicle Function"}
        dialogContent={
          <Stack spacing={2}>
            <Box mt="10px">
              <Box sx={{ mt: "25px" }}>
                <Typography fontWeight={"bold"} ml="2px">
                  Select the Vehicle Function
                </Typography>
                <MultiSelectTextField4
                  mutiple
                  size={"small"}
                  label={"Select Vehicle Function"}
                  optionsList={vfData}
                  selectedValues={editVF}
                  setSelectedValues={setEditVF}
                  sltFldStyle={{
                    width: "400px",
                    textAlign: "start",
                    mt: "10px",
                    backgroundColor: "white",
                  }}
                />
              </Box>
            </Box>
          </Stack>
        }
        onConfirm={handleClickEdit}
      />
    </div>
  );
}

export default EditVehicleFunction;
