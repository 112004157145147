/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-06-2024
 * @description Component for adding roles and access permissions.
 */

import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Paper,
  Step,
  StepLabel,
  Stepper,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import userManagementApis from "../../redux/actions/userManagent";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import CloseIcon from "@mui/icons-material/Close";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { CustomTextFieldN, SelectTextField } from "../../components";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-06-2024
 * @description Component for adding roles and access permissions.
 * @param { setIsAddRole }
 * @return The rendered add roles and access component.
 */
function AddRolesAndAccess({ setIsAddRole }) {
  const [activeStep, setActiveStep] = React.useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const [roleName, setRoleName] = useState("");
  const [roleNameError, setRoleNameError] = useState("");
  const [department, setDepartment] = useState("");
  const [departmentError, setDepartmentError] = useState("");
  const [subDepartment, setSubDepartment] = useState("");
  const [subDepartmentError, setSubDepartmentError] = useState("");
  const [description, setDescription] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const { featuresList } = useSelector(
    (state) => state.userManagementReducer,
    shallowEqual
  );
  const [selectedMenus, setSelectedMenus] = useState([]);
  const { menu_options } = useSelector(
    (state) => state.settingsReducer.metaData,
    shallowEqual
  );
  const [menuOptionsList, setMenuOptionsList] = useState([]);
  console.log("menu_options", menu_options);
  const [selectedMenuOption, setSelectedMenuOption] =
    useState("USER MANAGEMENT");
  const [selectedMenuOptionFeaturesList, setSelectedMenuOptionFeaturesList] =
    useState([]);
  const [selectedFeatures, setSelectedFeatures] = useState([]);
  const dispatch = useDispatch();

  useEffect(() => {
    const filteredMenuOptionsList = menu_options?.filter(
      (el) => el !== "DEFAULT"
    );
    setMenuOptionsList(filteredMenuOptionsList);

    // const itemsToCheck = ["PROFILE", "SETTINGS", "DASHBOARD"];
    const itemsToCheck = ["PROFILE"];
    // const itemsToCheck = [];
    setSelectedMenus((prevSelectedMenus) => {
      const newSelectedMenus = [...prevSelectedMenus];
      itemsToCheck.forEach((item) => {
        if (menu_options?.includes(item) && !newSelectedMenus.includes(item)) {
          newSelectedMenus.push(item);
        }
      });
      return newSelectedMenus;
    });
  }, [menu_options]);

  useEffect(() => {
    const filteredFeaturesList = featuresList?.filter(
      (el) => el.page_name == selectedMenuOption
    );
    setSelectedMenuOptionFeaturesList(filteredFeaturesList);

    const filteredFeaturesIdList = featuresList?.filter(
      // (el) => el.feature_name === "Default" || el.feature_name === "Profile"
      (el) => el.feature_name === "Default"
    );

    const ids = filteredFeaturesIdList.flatMap(
      (el) => el.details?.map((detail) => detail.id) || []
    );

    // Add only new ids to selectedFeatures
    setSelectedFeatures((prevFeatures) => {
      const newIds = ids.filter((id) => !prevFeatures.includes(id));
      return [...prevFeatures, ...newIds];
    });
  }, [selectedMenuOption]);

  const handleGetRolesAndAccessList = () => {
    dispatch(
      HandleApiActions({
        ...userManagementApis.get_roles_list,
        params: {
          page_number: 1,
          page_size: 100,
          sort_column: "created_at",
          sort_order: "ASC",
        },
        show_toast: false,
      })
    );
  };

  const handleRoleAndAccessFinish = () => {
    let currentTime = new Date().toISOString();

    dispatch(
      HandleApiActions({
        ...userManagementApis.add_role,
        params: {
          role_name: roleName,
          department,
          ...(subDepartment && { sub_department: subDepartment }),
          description,
          current_time: currentTime,
          access_to_pages: selectedMenus,
          access_to_features: selectedFeatures,
        },
        show_toast: true,
      })
    ).then(() => {
      handleGetRolesAndAccessList();
    });

    // setTimeout(handleGetRolesAndAccessList, 500);
    setIsAddRole(false);
  };

  const isFormValid = () => {
    return (
      roleName.trim() !== "" &&
      !roleNameError &&
      department.trim() !== "" &&
      !departmentError &&
      (subDepartment.trim().length === 0 || !subDepartmentError) &&
      description.trim() !== "" &&
      !descriptionError
    );
  };

  return (
    <Box style={{ marginBottom: "60px" }}>
      {/* <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          marginTop: "-20px",
          marginBottom: "10px",
        }}
      >
        <Button
          variant="text"
          startIcon={<ArrowBackIcon />}
          sx={{ "&:hover": { backgroundColor: "transparent" } }}
          onClick={() => setIsAddRole(false)}
        >
          Back
        </Button>
      </Box> */}

      <Box sx={{ width: "100%" }}>
        <Stepper activeStep={activeStep}>
          <Step key="CreateRole">
            <StepLabel>Create Role</StepLabel>
          </Step>
          <Step key="MenuAccess">
            <StepLabel>Menu Access</StepLabel>
          </Step>
          <Step key="FeaturesAccess">
            <StepLabel>Features Access</StepLabel>
          </Step>
        </Stepper>

        <Box sx={{ mt: 4 }}>
          {activeStep === 0 && (
            <>
              <Paper elevation={2}>
                <Card
                  style={{
                    border: "1px solid lightgrey",
                    boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
                    minHeight: "305px",
                  }}
                >
                  <Typography my={1} variant="h6" fontWeight="bold">
                    Create Role
                  </Typography>
                  <Divider />
                  <Grid
                    container
                    columnSpacing={2}
                    rowSpacing={1}
                    px={3}
                    pt={2}
                    pb={4}
                  >
                    {/* <Grid container columnSpacing={2} rowSpacing={1}> */}
                    <Grid item xs={12} sm={4}>
                      <CustomTextFieldN
                        size="small"
                        label="Role*"
                        placeholder="Role"
                        variant={"standard"}
                        type={"text"}
                        fldType={"text4"}
                        value={roleName}
                        setValue={setRoleName}
                        valueError={roleNameError}
                        setValueError={setRoleNameError}
                        txtFldStyle={{
                          backgroundColor: "white",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <CustomTextFieldN
                        size="small"
                        label="Department*"
                        placeholder="Department"
                        variant={"standard"}
                        type={"text"}
                        fldType={"text"}
                        value={department}
                        setValue={setDepartment}
                        valueError={departmentError}
                        setValueError={setDepartmentError}
                        txtFldStyle={{
                          backgroundColor: "white",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12} sm={4}>
                      <CustomTextFieldN
                        size="small"
                        label="Sub Departments"
                        placeholder="Sub Departments"
                        variant={"standard"}
                        type={"text"}
                        fldType={"textO"}
                        value={subDepartment}
                        setValue={setSubDepartment}
                        valueError={subDepartmentError}
                        setValueError={setSubDepartmentError}
                        txtFldStyle={{
                          backgroundColor: "white",
                        }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <CustomTextFieldN
                        size="small"
                        label="Description*"
                        placeholder="Description"
                        variant={"standard"}
                        type={"text"}
                        fldType={"text"}
                        value={description}
                        setValue={setDescription}
                        valueError={descriptionError}
                        setValueError={setDescriptionError}
                        isMultiline={true}
                        minRows={1}
                        maxRows={4}
                        txtFldStyle={{
                          backgroundColor: "white",
                          my: 2,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Paper>
            </>
          )}

          {activeStep === 1 && (
            <>
              <Paper elevation={2}>
                <MenusCard
                  menuOptions={menuOptionsList || []}
                  selectedMenus={selectedMenus}
                  setSelectedMenus={setSelectedMenus}
                />
              </Paper>
            </>
          )}

          {activeStep === 2 && (
            <>
              <Typography my={1} variant="h6" fontWeight="bold">
                Provide Feature Access
              </Typography>
              <FeaturesCard
                menuOptions={menu_options || []}
                featuresList={featuresList || []}
                selectedMenuOption={selectedMenuOption}
                setSelectedMenuOption={setSelectedMenuOption}
                selectedMenuOptionFeaturesList={selectedMenuOptionFeaturesList}
                selectedFeatures={selectedFeatures}
                setSelectedFeatures={setSelectedFeatures}
              />
            </>
          )}

          <Box
            sx={{
              // position: "fixed",
              // bottom: 0,
              // right: 0,
              // backgroundColor: "white",
              padding: 2,
              display: "flex",
              justifyContent: "space-between",
              // borderTop: "1px solid #e0e0e0",
            }}
          >
            <Button
              color="primary"
              variant={"contained"}
              startIcon={<ArrowBackIcon />}
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />

            <Box>
              <Button
                sx={{
                  mr: 2,
                }}
                color={"error"}
                variant={"contained"}
                startIcon={<CloseIcon />}
                onClick={() => setIsAddRole(false)}
              >
                Cancel
              </Button>
              {activeStep === 2 ? (
                <Button
                  color={"primary"}
                  variant={"contained"}
                  startIcon={<CheckCircleOutlineIcon />}
                  disabled={activeStep === 2 && selectedFeatures?.length <= 0}
                  onClick={handleRoleAndAccessFinish}
                >
                  Finish
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant={"contained"}
                  endIcon={<ArrowForwardIcon />}
                  disabled={
                    (activeStep === 0 && !isFormValid()) ||
                    (activeStep === 1 && selectedMenus?.length <= 0)
                  }
                  onClick={handleNext}
                >
                  Next
                </Button>
              )}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}

export default AddRolesAndAccess;

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-06-2024
 * @description
 * @param {  }
 * @return
 */
const MenusCard = ({ menuOptions, selectedMenus, setSelectedMenus }) => {
  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;

    if (name === "PROFILE") {
      // Prevent unchecking "PROFILE"
      if (checked) {
        setSelectedMenus((prevItems) => [...prevItems, name]);
      }
    } else {
      // Handle other menu items
      if (checked) {
        setSelectedMenus((prevItems) => [...prevItems, name]);
      } else {
        setSelectedMenus((prevItems) =>
          prevItems.filter((item) => item !== name)
        );
      }
    }
  };

  return (
    <Card
      style={{
        // marginBottom: "50px",
        border: "1px solid lightgrey",
        boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
      }}
    >
      <Typography my={1} variant="h6" fontWeight="bold">
        Access Menu Options
      </Typography>
      <Divider />
      <CardContent>
        <Grid container spacing={2}>
          {menuOptions?.map((menu, index) => (
            <Grid item xs={12} sm={6} md={6} key={index}>
              <Box display="flex" alignItems="center">
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={selectedMenus.includes(menu)}
                      onChange={handleCheckboxChange}
                      name={menu}
                      disabled={
                        menu === "PROFILE" && selectedMenus.includes(menu)
                      }
                    />
                  }
                  label={menu}
                />
              </Box>
            </Grid>
          ))}
        </Grid>
      </CardContent>
    </Card>
  );
};

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 28-06-2024
 * @description
 * @param {}
 * @return
 */
const FeaturesCard = ({
  menuOptions,
  featuresList,
  selectedMenuOption,
  setSelectedMenuOption,
  selectedMenuOptionFeaturesList,
  selectedFeatures,
  setSelectedFeatures,
}) => {
  console.log("selectedFeatures", selectedFeatures);

  const uniqueMenuList = [
    ...new Set(featuresList?.map((menu) => menu.page_name)),
  ];
  const menuOptionsList = uniqueMenuList?.map((pageName) => ({
    value: pageName,
    label: pageName,
  }));

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    const id = parseInt(name, 10);

    // Find the current feature based on its ID
    const currentFeature = selectedMenuOptionFeaturesList.find((feature) =>
      feature.details.some((detail) => detail.id === id)
    );

    if (currentFeature?.feature_name === "Default") {
      // Allow only selection for "Default" feature
      if (checked) {
        setSelectedFeatures((prevItems) => [...prevItems, id]);
      }
    } else {
      // Handle selection and unselection for other features
      if (checked) {
        setSelectedFeatures((prevItems) => [...prevItems, id]);
      } else {
        setSelectedFeatures((prevItems) =>
          prevItems?.filter((item) => item !== id)
        );
      }
    }
  };

  return (
    <Box>
      <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
        <SelectTextField
          size={"small"}
          label={"Select Menu"}
          variant={"standard"}
          optionsList={menuOptionsList}
          selectedValue={selectedMenuOption}
          setSelectedValue={setSelectedMenuOption}
          sltFldStyle={{
            mb: 2,
            width: "220px",
            backgroundColor: "white",
          }}
        />
      </Box>

      <Grid container spacing={3}>
        {selectedMenuOptionFeaturesList?.map((feature, index) => (
          <Grid item xs={12} sm={6} key={index}>
            <Paper elevation={2}>
              <Card
                key={index}
                style={{
                  border: "1px solid lightgrey",
                  boxShadow: "0px 0px 2px rgba(0, 0, 0, 0.2)",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography my={1} textAlign={"center"} variant="h6">
                    {feature.feature_name}
                  </Typography>
                </Box>
                <Divider />
                <CardContent style={{ height: "200px", overflowY: "auto" }}>
                  <Grid container spacing={2}>
                    {feature.details?.map((detail, index1) => (
                      <Grid item xs={12} key={index1}>
                        <Box display="flex" alignItems="center">
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={selectedFeatures.includes(detail.id)}
                                onChange={handleCheckboxChange}
                                name={detail.id.toString()}
                                disabled={
                                  feature.feature_name === "Default" &&
                                  selectedFeatures.includes(detail.id)
                                }
                              />
                            }
                            label={detail.description}
                          />
                        </Box>
                      </Grid>
                    ))}
                  </Grid>
                </CardContent>
              </Card>
            </Paper>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
