/**
 * @author Tahir Shaik - tahir@bookwater.com - +91-9177327808
 * @date 11-06-2024
 * @description This a custom Spinner/Loader Dialog box -> It will open whenever an API call made and close automatically
 */
import { Box, Button, Dialog, DialogActions, Typography } from "@mui/material";
import React from "react";

function CustomLoaderDialog(props) {
  return (
    <Dialog open={props.open}>
      <Box
        sx={{
          marginTop: "10px",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          // padding: "2px",
          borderRadius: "25px",
        }}
      >
        {/* <Typography
          sx={{
            fontSize: "14px",
            fontWeight: "bold",
            color: "blue",
            marginBottom: "10px",
          }}
        >
          Please wait!
        </Typography> */}
        <img
          style={{
            height: "150px",
            width: "200px",
            padding: "-20px",

            borderRadius: "10px",
          }}
          src={require("../../assets/gif-files/logogif.gif")}
          // src={require("../../assets/gif-files/deloitte_loading.gif")}
          alt="loading..."
        />
      </Box>

      {/* <DialogActions>
        <Button
          sx={{
            borderRadius: "15px",
            fontSize: "10px",
            color: "white",
            fontWeight: "bold",
            backgroundImage: "linear-gradient(#021636,#0c4aad)",
            "&:hover": {
              backgroundImage: "linear-gradient(#6b8df2,#021636)",
            },
          }}
          onClick={props.handleClose}
          autoFocus
        >
          Close
        </Button>
      </DialogActions> */}
    </Dialog>
  );
}

export default CustomLoaderDialog;
