/**
 * @author Tahir Shaik - tahir@bookwater.com - +911-9177327808
 * @date 11-06-2024
 * @description This is the custom console -> Where if the environment is Production the logs won't be printed in the console
 * @param {any} data
 * @returns {string}
 */
export default function customConsole(data) {
  if (process.env.REACT_APP_ENV === "PROD") return;
  console.log(data);
}
