/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 19-07-2024
 * @description
 */
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  ButtonBase,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { SelectTextFieldN } from "../../components";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { HandleApiActions } from "../../redux/actions/actionHandler";
import labCar from "../../redux/actions/labCar";
import LinkIcon from "@mui/icons-material/Link";
import LinkOffIcon from "@mui/icons-material/LinkOff";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 19-07-2024
 * @description
 * @param {}
 * @return {}
 */
function VLcLinkSWModules({
  selectedProjectId,
  selectedProjectIsZonal,
  selectedProjectZonesList,
  selectedProjectName,
  selectedProjectArchitecture,
  selectedProjectTradeMark,
  isLinkSoftwareModulesScreen,
  setIsLinkSoftwareModulesScreen,
}) {
  /* */
  const dispatch = useDispatch();

  /*  */
  const { eeComponentsMainList } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );
  const { eeComponentsLinkedList } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );
  const { eeComponentsMainLinkedList } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );
  const { swModulesMainList } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );
  const { swModulesLinkedList } = useSelector(
    (state) => state.labCarReducer,
    shallowEqual
  );

  /* Zone Main */
  const [zoneMainOptionsList, setZoneMainOptionsList] = useState([]);
  const [selectedZoneMain, setSelectedZoneMain] = useState(null);

  /* Zone Linked */
  const [zoneLinkedOptionsList, setZoneLinkedOptionsList] = useState([]);
  const [selectedZoneLinked, setSelectedZoneLinked] = useState(null);

  /* EE Components Main */
  const [eeMainOptionsList, setEEMainOptionsList] = useState([]);
  const [selectedEEMainId, setSelectedEEMainId] = useState(null);

  /* EE Components Linked */
  const [eeLinkedOptionsList, setEELinkedOptionsList] = useState([]);
  const [selectedEELinkedId, setSelectedEELinkedId] = useState(null);

  /* EE Components Main Linked */
  const [eeMainLinkedOptionsList, setEEMainLinkedOptionsList] = useState([]);

  /* EE - SW Module Main */
  const [eeMainSWModulesList, setEEMainSWModulesList] = useState([]);

  /* EE - SW Module Linked */
  const [eeLinkedSWModulesList, setEELinkedSWModulesList] = useState([]);

  /* EE - SW Module Main Selected */
  const [selectedMainSWModuleDetails, setSelectedMainSWModuleDetails] =
    useState(null);

  /* Dispatch Functions */
  const getEEMainComponentsList = () => {
    if (selectedProjectId && selectedZoneMain) {
      dispatch(
        HandleApiActions({
          ...labCar.get_ee_components_main,
          params: {
            project_id: selectedProjectId,
            zone_sequence: selectedZoneMain,
          },
          show_toast: false,
        })
      );
    }
  };

  const getEELinkedComponentsList = () => {
    if (selectedProjectId && selectedZoneLinked) {
      dispatch(
        HandleApiActions({
          ...labCar.get_ee_components_linked,
          params: {
            project_id: selectedProjectId,
            zone_sequence: selectedZoneLinked,
          },
          show_toast: false,
        })
      );
    }
  };

  const getEEMainLinkedComponentsList = () => {
    if (selectedProjectId) {
      dispatch(
        HandleApiActions({
          ...labCar.get_ee_components_main_linked,
          params: {
            project_id: selectedProjectId,
          },
          show_toast: false,
        })
      );
    }
  };

  /* useEffects */
  useEffect(() => {
    /* Make to default state when the isLinkSoftwareModulesScreen is changed */
    /* Zone Main */
    setZoneMainOptionsList([]);
    setSelectedZoneMain(null);
    /* Zone Linked */
    setZoneLinkedOptionsList([]);
    setSelectedZoneLinked(null);
    /* EE Main */
    setEEMainOptionsList([]);
    setSelectedEEMainId(null);
    /* EE Linked */
    setEELinkedOptionsList([]);
    setSelectedEELinkedId(null);
    /* EE Main Linked */
    setEEMainLinkedOptionsList([]);
    /* EE Main SW */
    setEEMainSWModulesList([]);
    /* EE Linked SW */
    setEELinkedSWModulesList([]);
    /* Selected SW Module */
    setSelectedMainSWModuleDetails(null);
  }, [isLinkSoftwareModulesScreen]);

  useEffect(() => {
    if (selectedProjectIsZonal) {
      /* Zone Main */
      let filteredZoneMainOptionsList = selectedProjectZonesList
        ?.map((el) => {
          return {
            value: el?.sequence,
            label: el?.zone_name,
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));
      setZoneMainOptionsList(filteredZoneMainOptionsList);

      /* Zone Linked */
      let filteredZoneLinkedOptionsList = selectedProjectZonesList
        ?.map((el) => {
          return {
            value: el?.sequence,
            label: el?.zone_name,
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));
      setZoneLinkedOptionsList(filteredZoneLinkedOptionsList);
    }
  }, [selectedProjectZonesList]);

  useEffect(() => {
    getEEMainLinkedComponentsList();
  }, [selectedProjectId]);

  useEffect(() => {
    /* EE Main */
    setSelectedEEMainId(null);
    /* EE Main SW */
    setEEMainSWModulesList([]);

    getEEMainComponentsList();
  }, [selectedZoneMain]);

  useEffect(() => {
    /* EE Linked */
    setSelectedEELinkedId(null);
    /* EE Linked SW */
    setEELinkedSWModulesList([]);

    getEELinkedComponentsList();
  }, [selectedZoneLinked]);

  useEffect(() => {
    if (eeComponentsMainList) {
      let filteredEEMainOptionsList = eeComponentsMainList
        ?.map((el) => {
        return {
          value: el?.ee_id,
          label: el?.description,
        };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));
      setEEMainOptionsList(filteredEEMainOptionsList);
    }
  }, [eeComponentsMainList]);

  useEffect(() => {
    if (eeComponentsLinkedList) {
      let filteredEELinkedOptionsList = eeComponentsLinkedList
        ?.map((el) => {
        return {
          value: el?.ee_id,
          label: el?.description,
        };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));
      setEELinkedOptionsList(filteredEELinkedOptionsList);
    }
  }, [eeComponentsLinkedList]);

  useEffect(() => {
    if (eeComponentsMainLinkedList) {
      let filteredEEMainLinkedOptionsList = eeComponentsMainLinkedList
        ?.map((el) => {
          return {
            value: el?.ee_id,
            label: el?.description,
          };
        })
        ?.sort((a, b) => a.label.localeCompare(b.label));
      setEEMainLinkedOptionsList(filteredEEMainLinkedOptionsList);
    }
  }, [eeComponentsMainLinkedList]);

  const getMainSWModulesList = () => {
    if (selectedProjectId && selectedEEMainId) {
      dispatch(
        HandleApiActions({
          ...labCar.get_main_sw_modules,
          params: {
            project_id: selectedProjectId,
            ee_id: selectedEEMainId,
          },
          show_toast: false,
        })
      );
    }
  };

  useEffect(() => {
    /* Selected SW Module */
    setSelectedMainSWModuleDetails(null);

    getMainSWModulesList();
  }, [selectedProjectId, selectedEEMainId]);

  const getLinkedSWModulesList = () => {
    if (selectedProjectId && selectedEELinkedId) {
      dispatch(
        HandleApiActions({
          ...labCar.get_linked_sw_modules,
          params: {
            project_id: selectedProjectId,
            ee_id: selectedEELinkedId,
          },
          show_toast: false,
        })
      );
    }
  };

  useEffect(() => {
    getLinkedSWModulesList();
  }, [selectedProjectId, selectedEELinkedId]);

  useEffect(() => {
    if (swModulesMainList && selectedEEMainId) {
      setEEMainSWModulesList(swModulesMainList);
    }
  }, [swModulesMainList]);

  useEffect(() => {
    if (
      swModulesLinkedList &&
      selectedEELinkedId &&
      selectedMainSWModuleDetails &&
      selectedMainSWModuleDetails?.swm_id
    ) {
      let filteredSWModulesLinkedList = swModulesLinkedList?.filter(
        (el) => el?.swm_id != selectedMainSWModuleDetails?.swm_id
      );
      setEELinkedSWModulesList(filteredSWModulesLinkedList);
    } else if (swModulesLinkedList && selectedEELinkedId) {
      setEELinkedSWModulesList(swModulesLinkedList);
    }
  }, [swModulesLinkedList, selectedMainSWModuleDetails]);

  useEffect(() => {
    console.log("zoneMainOptionsList:", zoneMainOptionsList);
    console.log("selectedZoneMain:", selectedZoneMain);
    console.log("zoneLinkedOptionsList:", zoneLinkedOptionsList);
    console.log("selectedZoneLinked:", selectedZoneLinked);
    console.log("eeMainOptionsList:", eeMainOptionsList);
    console.log("selectedEEMainId:", selectedEEMainId);
    console.log("eeLinkedOptionsList:", eeLinkedOptionsList);
    console.log("selectedEELinkedId:", selectedEELinkedId);
    console.log("eeMainLinkedOptionsList:", eeMainLinkedOptionsList);
    console.log("eeMainSWModulesList:", eeMainSWModulesList);
    console.log("eeLinkedSWModulesList:", eeLinkedSWModulesList);
    console.log("selectedMainSWModuleDetails:", selectedMainSWModuleDetails);

    console.log("eeComponentsMainList:", eeComponentsMainList);
    console.log("eeComponentsLinkedList:", eeComponentsLinkedList);
    console.log("eeComponentsMainLinkedList:", eeComponentsMainLinkedList);
  }, [
    zoneMainOptionsList,
    selectedZoneMain,
    zoneLinkedOptionsList,
    selectedZoneLinked,
    eeMainOptionsList,
    selectedEEMainId,
    eeLinkedOptionsList,
    selectedEELinkedId,
    eeMainLinkedOptionsList,
    eeMainSWModulesList,
    eeLinkedSWModulesList,
    selectedMainSWModuleDetails,
    eeComponentsMainList,
    eeComponentsLinkedList,
    eeComponentsMainLinkedList,
  ]);

  /* -------------- Handle Sw Module Link/UnLink functions -------------- */
  const handleSWModuleLink = (selectedLinkedSWModuleDetails) => {
    let currentTime = new Date().toISOString();

    if (
      selectedProjectId &&
      selectedMainSWModuleDetails &&
      selectedMainSWModuleDetails?.ee_id &&
      selectedMainSWModuleDetails?.swm_id &&
      selectedLinkedSWModuleDetails?.ee_id &&
      selectedLinkedSWModuleDetails?.swm_id
    ) {
      dispatch(
        HandleApiActions({
          ...labCar.link_sw_module,
          params: {
            project_id: Number(selectedProjectId),
            parent: {
              ee_id: Number(selectedMainSWModuleDetails?.ee_id),
              swm_id: Number(selectedMainSWModuleDetails?.swm_id),
            },
            child: {
              ee_id: Number(selectedLinkedSWModuleDetails?.ee_id),
              swm_id: Number(selectedLinkedSWModuleDetails?.swm_id),
            },
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        getMainSWModulesList();
        getLinkedSWModulesList();
      });

      // setTimeout(getMainSWModulesList, 500);
      // setTimeout(getLinkedSWModulesList, 500);
    }
  };

  const handleSWModuleUnLink = (selectedLinkedSWModuleDetails) => {
    let currentTime = new Date().toISOString();

    if (
      selectedProjectId &&
      selectedMainSWModuleDetails &&
      selectedMainSWModuleDetails?.ee_id &&
      selectedMainSWModuleDetails?.swm_id &&
      selectedLinkedSWModuleDetails?.swm_id
    ) {
      dispatch(
        HandleApiActions({
          ...labCar.unlink_sw_module,
          params: {
            project_id: Number(selectedProjectId),
            ee_id: Number(selectedMainSWModuleDetails?.ee_id),
            swm_id: Number(selectedMainSWModuleDetails?.swm_id),
            removing_swm_id: Number(selectedLinkedSWModuleDetails?.swm_id),
            current_time: currentTime,
          },
          show_toast: true,
        })
      ).then(() => {
        getMainSWModulesList();
        getLinkedSWModulesList();
      });

      // setTimeout(getMainSWModulesList, 500);
      // setTimeout(getLinkedSWModulesList, 500);
    }
  };

  return (
    <Box
      sx={{
        mb: "70px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mx: 1,
        }}
      >
        <Box sx={{ display: "flex", alignItems: "center" }}>
          <ButtonBase onClick={() => setIsLinkSoftwareModulesScreen(false)}>
            <IconButton color="primary">
              <ArrowBackIcon />
            </IconButton>
            <Typography variant="body1" color="primary">
              Back
            </Typography>
          </ButtonBase>
        </Box>

        <Typography
          variant="h6"
          fontWeight={"bold"}
          align="center"
          sx={{ flexGrow: 1 }}
        >
          Link Software modules
        </Typography>

        {/* <Button color="primary" size="small" variant="contained">
          Link Modules
        </Button> */}
      </Box>

      <Paper
        elevation={6}
        sx={{
          display: "flex",
          flexDirection: { xs: "column", md: "row" },
          px: 2,
          py: 1,
          mx: 4,
          mt: 1,
        }}
      >
        <Typography
          // variant="h6"
          variant="subtitle1"
          // fontWeight={"bold"}
          align="center"
          sx={{
            flex: 1,
            maxWidth: { xs: "100%", md: "33%" },
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span style={{ fontWeight: "bold" }}>Project: </span>
          {selectedProjectName}
        </Typography>
        <Typography
          // variant="h6"
          variant="subtitle1"
          // fontWeight={"bold"}
          align="center"
          sx={{
            flex: 1,
            maxWidth: { xs: "100%", md: "33%" },
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span style={{ fontWeight: "bold" }}>Architecture: </span>
          {selectedProjectArchitecture}
        </Typography>
        <Typography
          // variant="h6"
          variant="subtitle1"
          // fontWeight={"bold"}
          align="center"
          sx={{
            flex: 1,
            maxWidth: { xs: "100%", md: "33%" },
            overflow: "hidden",
            textOverflow: "ellipsis",
          }}
        >
          <span style={{ fontWeight: "bold" }}>Trade Mark: </span>
          {selectedProjectTradeMark}
        </Typography>
      </Paper>

      {/* <Box sx={{ mt: 4 }}> */}
      <Box sx={{ mt: 2 }}>
        <Grid container spacing={4}>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ mb: 3 }}>
              {selectedProjectIsZonal && (
                <SelectTextFieldN
                  size={"small"}
                  label={"Select Zone"}
                  variant={"standard"}
                  optionsList={zoneMainOptionsList}
                  selectedValue={selectedZoneMain}
                  handleOnChange={(e) => setSelectedZoneMain(e.target.value)}
                  sltFldStyle={{
                    width: "190px",
                  }}
                />
              )}
              <SelectTextFieldN
                size={"small"}
                label={"Select EE Component"}
                variant={"standard"}
                optionsList={
                  selectedProjectIsZonal
                    ? eeMainOptionsList
                    : eeMainLinkedOptionsList
                }
                selectedValue={selectedEEMainId}
                disabled={selectedProjectIsZonal ? !selectedZoneMain : false}
                handleOnChange={(e) => setSelectedEEMainId(e.target.value)}
                sltFldStyle={{
                  width: "190px",
                  ml: 2,
                }}
              />
            </Box>
            <Card
              sx={{
                mx: 2,
                boxShadow: 12,
                width: "420px",
              }}
            >
              <CardHeader
                sx={{
                  pb: 1,
                  backgroundColor: "lightgrey",
                  borderBottom: "1px solid lightgrey",
                }}
                title={
                  <Typography
                    textAlign={"center"}
                    fontWeight={600}
                    variant="subtitle1"
                  >
                    {"Software Modules"}
                  </Typography>
                }
              />
              <CardContent
                sx={{
                  height: "340px",
                  overflowY: "auto",
                }}
              >
                {eeMainSWModulesList && eeMainSWModulesList?.length > 0 ? (
                  eeMainSWModulesList?.map((item, index) => (
                    <Box
                      key={index}
                      onClick={() => setSelectedMainSWModuleDetails(item)}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mb: 1.5,
                        pl: 1.4,
                        pr: 1,
                        backgroundColor: "background.paper",
                        borderRadius: 1,
                        // boxShadow: 2,
                        boxShadow: 4,
                        border:
                          selectedMainSWModuleDetails &&
                            selectedMainSWModuleDetails?.swm_id == item?.swm_id
                            ? "2px solid blue"
                            : "1px solid lightgrey",
                        cursor: "pointer",
                        "&:hover": {
                          // backgroundColor: "lightgrey",
                        },
                      }}
                    >
                      <Box sx={{ flexGrow: 1, textAlign: "start", my: 1 }}>
                        <Typography variant="body2">
                          <strong>Name:</strong> {item?.name}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Description:</strong> {item?.description}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Version:</strong> {item?.version}
                        </Typography>
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "center", mt: 2 }}
                  >
                    No data available
                  </Typography>
                )}
              </CardContent>
              <CardActions
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  borderTop: "1px solid lightgrey",
                }}
              ></CardActions>
            </Card>
          </Grid>
          <Grid
            item
            xs={12}
            md={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Box sx={{ mb: 3 }}>
              {selectedProjectIsZonal && (
                <SelectTextFieldN
                  size={"small"}
                  label={"Select Zone"}
                  variant={"standard"}
                  optionsList={zoneLinkedOptionsList}
                  selectedValue={selectedZoneLinked}
                  handleOnChange={(e) => setSelectedZoneLinked(e.target.value)}
                  sltFldStyle={{
                    width: "190px",
                  }}
                />
              )}
              <SelectTextFieldN
                size={"small"}
                label={"Select EE Component"}
                variant={"standard"}
                optionsList={
                  selectedProjectIsZonal
                    ? eeLinkedOptionsList
                    : eeMainLinkedOptionsList
                }
                selectedValue={selectedEELinkedId}
                disabled={selectedProjectIsZonal ? !selectedZoneLinked : false}
                handleOnChange={(e) => setSelectedEELinkedId(e.target.value)}
                sltFldStyle={{
                  width: "190px",
                  ml: 2,
                }}
              />
            </Box>
            <Card
              sx={{
                mx: 2,
                boxShadow: 12,
                width: "420px",
              }}
            >
              <CardHeader
                sx={{
                  pb: 1,
                  backgroundColor: "lightgrey",
                  borderBottom: "1px solid lightgrey",
                }}
                title={
                  <Typography
                    textAlign={"center"}
                    fontWeight={600}
                    variant="subtitle1"
                  >
                    {"Linked To"}
                  </Typography>
                }
              />
              <CardContent
                sx={{
                  height: "340px",
                  overflowY: "auto",
                }}
              >
                {eeLinkedSWModulesList && eeLinkedSWModulesList?.length > 0 ? (
                  eeLinkedSWModulesList?.map((item, index) => (
                    <Box
                      key={index}
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                        mb: 1.5,
                        pl: 1.4,
                        pr: 1,
                        backgroundColor: "background.paper",
                        borderRadius: 1,
                        // boxShadow: 2,
                        boxShadow: 4,
                        border: item?.linked_swm_ids?.includes(
                          selectedMainSWModuleDetails?.swm_id
                        )
                          ? "2px solid green"
                          : "1px solid lightgrey",
                        // cursor: "pointer",
                        "&:hover": {
                          // backgroundColor: "lightgrey",
                        },
                      }}
                    >
                      <Box sx={{ flexGrow: 1, textAlign: "start", my: 1 }}>
                        <Typography variant="body2">
                          <strong>Name:</strong> {item?.name}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Description:</strong> {item?.description}
                        </Typography>
                        <Typography variant="body2">
                          <strong>Version:</strong> {item?.version}
                        </Typography>
                      </Box>
                      <Box
                    // sx={{
                    //   display: "flex",
                    //   flexDirection: "column",
                    //   alignItems: "center",
                    //   justifyContent: "center",
                    //   gap: 1,
                    // }}
                    >
                        {selectedMainSWModuleDetails && (
                          <Tooltip
                            title={
                              item?.linked_swm_ids?.includes(
                                selectedMainSWModuleDetails?.swm_id
                              )
                                ? "Unlink"
                                : "Link"
                            }
                      >
                      <IconButton
                        size="small"
                        color="info"
                        onClick={(e) => {
                          e.stopPropagation();
                                if (
                                  item?.linked_swm_ids?.includes(
                                    selectedMainSWModuleDetails?.swm_id
                                  )
                                ) {
                                  handleSWModuleUnLink(item);
                                } else {
                                  handleSWModuleLink(item);
                                }
                              }}
                      >
                              {item?.linked_swm_ids?.includes(
                                selectedMainSWModuleDetails?.swm_id
                              ) ? (
                                <LinkOffIcon sx={{ color: "#FF5757" }} />
                              ) : (
                                <LinkIcon sx={{ color: "green" }} />
                              )}
                      </IconButton>
                          </Tooltip>
                        )}
                      </Box>
                    </Box>
                  ))
                ) : (
                  <Typography
                    variant="body1"
                    sx={{ textAlign: "center", mt: 2 }}
                  >
                    No data available
                  </Typography>
                )}
              </CardContent>
              <CardActions
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  borderTop: "1px solid lightgrey",
                }}
              ></CardActions>
            </Card>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
}

export default VLcLinkSWModules;
