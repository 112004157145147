/**
 * @author Narender - narender@au79consulting.com
 * @date 12-06-2024
 * @description Component for handling the reset password functionality.
 */

import { Avatar, Box, IconButton, Link, Typography } from "@mui/material";
import React, { useState } from "react";
import { AuthButton, AuthTextField } from "../";
import appMeta from "../../assets/app-meta/appMeta";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

/**
 * @author : Narender - narender@au79consulting.com
 * @Date : 12-06-2024
 * @description Component for handling the reset password functionality.
 * @param : handleResetPassword, handleAuthViewChange
 * @return The rendered reset password component.
 */
function ResetPassword({
  handleResetPassword,
  throttledHandleResetPassword,
  handleAuthViewChange,
}) {
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [confirmPasswordError, setConfirmPasswordError] = useState("");

  // Handles click event for "Back to Login" link.
  const handleLoginOnClick = () => {
    handleAuthViewChange(appMeta.authView.LOGIN);
  };

  // Handles click event for "Reset Password" button.
  // Validates OTP, password, and confirms password fields.
  const handleResetPasswordOnClick = () => {
    const otpRegex = /^\d{6}$/;

    // Validate otp in real-time
    if (!otpRegex.test(otp)) {
      setOtpError("OTP must be a 6-digit number");
      return;
    } else {
      setOtpError("");
    }

    const passwdMinLength = 8;
    const lowerCaseRegex = /[a-z]/;
    const upperCaseRegex = /[A-Z]/;
    const numberRegex = /[0-9]/;
    const specialCharRegex = /[!@#$%^&*(),.?":{}|<>]/;

    // Validate password in real-time
    if (
      password.length < passwdMinLength ||
      !lowerCaseRegex.test(password) ||
      !upperCaseRegex.test(password) ||
      !numberRegex.test(password) ||
      !specialCharRegex.test(password)
    ) {
      setPasswordError("Password is weak");
    } else {
      setPasswordError("");
    }

    // Confirm password match validation
    if (password != confirmPassword) {
      setConfirmPasswordError("Passwords do not match.");
      return;
    } else {
      setConfirmPasswordError("");
    }

    // Trigger reset password function with OTP and password
    // handleResetPassword({ otp, password });
    throttledHandleResetPassword({ otp, password });
  };

  return (
    <Box sx={styles.mainContainer}>
      <Avatar variant="rounded" sx={styles.avatar}>
        <img height={"100%"} src="deloitteIconBlackLarge.png" alt="Deloitte" />
      </Avatar>

      <Typography style={{ fontWeight: 600 }} component="h1" variant="body1">
        Reset Password
      </Typography>

      <Box sx={styles.fldsContainer}>
        <AuthTextField
          label={"Code"}
          placeHolder={"Code"}
          // type={"number"}
          fldType={appMeta.textFieldTypes.OTP}
          variant={"standard"}
          value={otp}
          setValue={setOtp}
          valueError={otpError}
          setValueError={setOtpError}
          isAutoFocused={true}
          isRequired={true}
          txtFldStyle={{
            mt: 3,
          }}
        />
        <AuthTextField
          label={"New Password"}
          placeHolder={"New Password"}
          type={"password"}
          fldType={appMeta.textFieldTypes.PASSWORD}
          variant={"standard"}
          value={password}
          inputProps={{
            autocomplete: "new-password",
          }}
          setValue={setPassword}
          valueError={passwordError}
          setValueError={setPasswordError}
          isAutoFocused={false}
          isRequired={true}
          txtFldStyle={{
            mt: 3,
          }}
        />
        <AuthTextField
          label={"Confirm Password"}
          placeHolder={"Confirm Password"}
          type={"password"}
          fldType={appMeta.textFieldTypes.CONFIRM_PASSWORD}
          variant={"standard"}
          value={confirmPassword}
          passwordValue={password}
          setValue={setConfirmPassword}
          valueError={confirmPasswordError}
          setValueError={setConfirmPasswordError}
          isAutoFocused={false}
          isRequired={true}
          onKeyDown={(event) => { if (event.key === 'Enter') handleResetPasswordOnClick(); }}
          txtFldStyle={{
            mt: 3,
            mb: 1,
          }}
        />
        {/* resend otp link */}
        <AuthButton
          btnName="Reset Password"
          handleOnClick={handleResetPasswordOnClick}
        />
        {/* <AuthButton
          btnName="Back to login"
          handleOnClick={handleLoginOnClick}
        /> */}
        <Box sx={{ mt: 2 }}>
          <IconButton
            sx={{
              color: "#1976d2",
              // padding: '8px',
              "& svg": {
                fontSize: "20px",
              },
            }}
            aria-label="back to login"
          >
            <ArrowBackIcon />
          </IconButton>
          <Link
            style={styles.linkStyle}
            href="#"
            variant="body2"
            onClick={(e) => {
              e.preventDefault();
              handleLoginOnClick();
            }}
          >
            Back to Login
          </Link>
        </Box>
      </Box>
    </Box>
  );
}

export default ResetPassword;

// Styling for the ResetPassword component
const styles = {
  mainContainer: {
    width: "100%",
    paddingX: 5,
    paddingY: 4,
    borderRadius: 1,
    backgroundColor: "white",
    border: { sm: "1px solid lightgrey" },
    boxShadow: { sm: "0px 2px 4px rgba(0, 0, 0, 0.1)" },
    boxSizing: "border-box",

    // display: 'flex',
    // flexDirection: 'column',
    // alignItems: 'center',
    // justifyContent: 'center',
    // height: '85vh',
    // textAlign: 'center',
    // maxWidth: '400px',
    // padding: 2,
    // margin: '0 auto',
    // boxSizing: 'border-box',
  },
  fldsContainer: {
    width: "100%",
  },
  avatar: {
    mb: 3,
    bgcolor: "white",
    width: "100%",
    height: 24,
    // border: "1px solid red",
  },
};
